import { Component } from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { CategoryservicefilterComponent } from '../categoryservicefilter/categoryservicefilter.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { DeviceService } from '../Services/device.service';

@Component({
  selector: 'app-findajob-jobber',
  templateUrl: './findajob-jobber.component.html',
  styleUrls: ['./findajob-jobber.component.scss'],
  //standalone:true, (removed from standalone because CategoryserviceFilterComponent is not standalone)
 // imports:[ DialogModule,CategoryservicefilterComponent]

})
export class FindajobJobberComponent {

  isMobile: boolean;
isTablet: boolean;
isWeb: boolean;

 
  constructor(public deviceService: DeviceService,private route: ActivatedRoute , private router : Router,private platform: Platform){}
  showMe:boolean=false
  toggleTag(){
    this.showMe=!this.showMe
  }

  goBack() {
    this.router.navigate(['/acejobber/jobber/customerList']);
  }
  
  loginjobber:boolean=false
  ngOnInit(){
 //ionic platform
 this.deviceService.waitForPlatformReady().then(() => {
  console.log('Device type:', this.deviceService.getPlatformCode());
});
   
  }
  findajobnavigate(){
    if (this.route.snapshot.url[0].path === 'findajobjobber') {
      localStorage.removeItem('jobIndex');
    } 
    this.loginjobber=true
  }

  rewardPoints() {
    this.router.navigate(['/acejobber/jobber/referral-rewards'])
  }
}
