import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, lastValueFrom, map, of } from 'rxjs';
import { AppConstants } from '../appConstants';
import { ResponseServiceService } from './response-service.service';
import * as moment from 'moment';
import { ReturnStatement } from '@angular/compiler';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {
////////////////////////////////////////////////////////////////////////////////////////
paymentOrderRequest(amount:any,info:any) {
  return this.http.post<any>(AppConstants.api_based_url + `/billingMethod/create_order?amount=${amount}&info=${info}`, amount,info).pipe(map(data => {
    return data;
  }))
}


  /////////////////////////////////////////////////////////////////////////////////
  constructor(private http: HttpClient, private responseService: ResponseServiceService) { }


  //=========================
  private hireUpdatedData = new Subject<any>();
  castHireUpdatedData = this.hireUpdatedData.asObservable();

  updateHireUpdatedData(hireUpdatedData) {
    this.hireUpdatedData.next(hireUpdatedData);
  }
  //=========================


  getHireById(obj) {
    return this.http.put<any>(AppConstants.api_based_url + '/hired/getById', obj).pipe(map(data => {
      return data;
    }))
  }




  getNotificationsByReceiverId(obj): Observable<any> {
    return this.http.put<any>(AppConstants.api_based_url + '/sentNotifications/findByReceiverId', obj).pipe(map(data => {
      return data;
    }))
  }

  getCategory(): Observable<any> {
    if (this.responseService.isExistCatgeoryList()) {
      return of(this.responseService.getCategoryList())
    } else {
      return this.http.get<any>(AppConstants.api_based_url + '/category/getAll').pipe(map(data => {
        this.responseService.setCategoryList(data)
        return data;
      }))
    }
  }
  getServiceByCatId(categoryId): Observable<any> {
    return this.http.get<any>(AppConstants.api_based_url + '/service/getByCategoryId?categoryId=' + categoryId).pipe(map(data => {
      return data;
    }))
  }

  getServiceRatesByCatIdSpId(categoryId, spId): Observable<any> {
    if (this.responseService.isExistServicesRates(spId)) {
      return of(this.responseService.getServicesRates(spId))
    } else {
      return this.http.get<any>(AppConstants.api_based_url + '/rate/getByCategoryIdSpId?categoryId=' + categoryId + '&spId=' + spId).pipe(map(data => {
        this.responseService.setServicesRates(spId, data)
        return data;
      }))
    }
  }

  getJobberByServiceId(jobId, jobberId): Observable<any> {
    // if (this.responseService.isExistMatchingJobberList(jobId)) {
    //   console.log(this.responseService.getMatchingJobberList(jobId))
    //   return of(this.responseService.getMatchingJobberList(jobId))
    // } else {
      return this.http.get<any>(AppConstants.api_based_url + '/serviceProviderRegistration/getByServicesId?serviceId=3&jobId=' + jobId + '&jobberId=' + jobberId).pipe(map(data => {
        // return data;
      }))
    // }
  }

  savePostJob(postJob, headers, addObj) {
    alert(JSON.stringify(postJob))
    return this.http.post<any>(AppConstants.api_based_url + '/postJob/save', postJob, { headers: headers }).pipe(map(data => {
      addObj.id = data.id
      addObj.jobId = data.id
      if (this.responseService.isExistJobsPostedData()) {
        this.responseService.addRowJobsPostedData(addObj)
      }
      return data;
    }))
  }

  jobPosterRegistration(formData) {
    return this.http.post<any>(AppConstants.api_based_url + '/jobPosterRegistration/save', formData).pipe(map(data => {
      return data;
    }))
  }

  updateCustomerDetails(formData) {
    return this.http.put<any>(AppConstants.api_based_url + '/jobPosterRegistration/updateCustomerDetails', formData).pipe(map(data => {
      return data;
    }))
  }

  getLocationsByLoginId(id): Observable<any> {
    if (this.responseService.isExistLocationsList()) {
      return of(this.responseService.getLocationList())
    } else {
      return this.http.get<any>(AppConstants.api_based_url + '/location/getByLoginId?loginId=' + id).pipe(map(data => {
        this.responseService.setLocationsList(data)
        return data;
      }))
    }
  }

  saveLocation(location) {
    return this.http.post<any>(AppConstants.api_based_url + '/location/save', location).pipe(map(data => {
      console.log(location.id + "location.id")
      if (location.id == null) {
        if (this.responseService.isExistLocationsList()) {
          this.responseService.addRowLocationList(data.body)
        }
      } else {
        this.responseService.editRowLocation(data.body)
      }

      return data;
    }))
  }

  deleteByIdLocation(location) {
    return this.http.put<any>(AppConstants.api_based_url + '/location/deleteById', location).pipe(map(data => {
      this.responseService.deleteRowLocation(location)
      return data;
    }))
  }

  getAllJobsByUserId(id: string,jobId : any , pageSize: number, pageNumber: number): Observable<any> {
    const mapKey = `${pageSize}-${pageNumber}`;
    if (this.responseService.isExistJobPostedDataList(mapKey , jobId)) {
      return of(this.responseService.getJobPostedDataList(mapKey));
    } else {
      const url = `${AppConstants.api_based_url}/postJob/getByUserId?userId=${id}&jobId=${jobId}&pageSize=${pageSize}&pageNumber=${pageNumber}`;
      return this.http.get<any>(url).pipe(map(data => {
        if (!jobId) {
        this.responseService.setJobPostedDataList(mapKey, data);
        }
        return data;
      }));
     }
  }

  getOpenJobsByCustomerId(obj) {
    return this.http.put<any>(AppConstants.api_based_url + '/postJob/getOpenJobsByCustomerId', obj).pipe(map(data => {
      return data;
    }))
  }

  getServicesId(jobId) {
    return this.http.put<any>(AppConstants.api_based_url + `/postJob/getServiceId/${jobId}`, jobId).pipe(map(data => {
      return data;
    }))
  }

  getOpenJobs(obj) {
    if (this.responseService.isExistOpenJobs()) {
      return this.responseService.getOpenJobs()
    } else {
    }
  }

  getActiveJobs(obj) {
    if (this.responseService.isExistActiveJobs()) {
      return this.responseService.getActiveJobs()
    } else {
    }
  }

  getUpcomingJobs(obj) {
    if (this.responseService.isExistUpcomingJobs()) {
      return this.responseService.getUpcomingJobs()
    } else {
    }
  }

  getRejectedJobs(obj) {
    if (this.responseService.isExistRejectedJobs()) {
      return this.responseService.getRejectedJobs()
    } else {
    }
  }

  getCompletedJobs(obj) {
    if (this.responseService.isExistCompletedJobs()) {
      return this.responseService.getCompletedJobs()
    } else {
    }
  }

  getMyJobsByCustomerId(obj: any, pageSize: number, pageNumber: number): Observable<any> {
    var date = moment(new Date).format('YYYY-MM-DD');
    const mapKey = `${pageSize}-${pageNumber}_${obj.hireStatusId}`;
    if (this.responseService.isExistMyJobsDataList(mapKey)) {
      return of(this.responseService.getMyJobsDataList(mapKey));
    } else {
      const url = `${AppConstants.api_based_url}/postJob/getMyJobsByCustomerId?pageSize=${pageSize}&pageNumber=${pageNumber}`;
      return this.http.put<any>(url, obj).pipe(map(data => {
        // var openJobs = data.filter(obj => obj.hire.hirestatusId == 1);
        // this.responseService.setOpenJobs(openJobs);
        //  var activeJobs = data.filter(obj => obj.hire && obj.hire.hirestatusId == 2 && obj.hire.startDate && obj.hire.startDate.substring(0, 10) <= date);
        // this.responseService.setActiveJobs(activeJobs);
        //  var upcomingJobs = data.filter(obj => obj.hire && obj.hire.hirestatusId == 2 && obj.hire.startDate && obj.hire.startDate.substring(0, 10) > date);
        // this.responseService.setUpcomingJobs(upcomingJobs);
        // var rejectedJobs = data.filter(obj => obj.hire.hirestatusId == 3);
        // this.responseService.setRejectedJobs(rejectedJobs);
        // var completedJobs = data.filter(obj => obj.hire.hirestatusId == 5);
        // this.responseService.setCompletedJobs(completedJobs);
        if (!obj.jobId) {
         this.responseService.setMyJobsDataList(mapKey, data);
        }
         return data;
      }));
     }
  }

  getAllPostedJobsByJobId(categoryId: any, locationCity: any, jobId : any , pageSize: number, pageNumber: number) {
    // const mapKey = `${pageSize}-${pageNumber}`;
    // if (this.responseService.isExistCustomerData(mapKey)) {
    //    return of(this.responseService.getCustomerData(mapKey));
    // } else {
    return this.http.get<any>(`${AppConstants.api_based_url}/postJob/getAllByPostJobId?categoryId=${categoryId}&locationCity=${locationCity}&jobId=${jobId}&pageSize=${pageSize}&pageNumber=${pageNumber}`).pipe(map(data => {
      // this.responseService.setCustomerData(mapKey , data);
      return data;
    }));
    //  }
  }

  saveHired(hired) {
    return this.http.post<any>(AppConstants.api_based_url + '/hired/wholeSave', hired).pipe(map(data => {
      return data;
    }))
  }

  updateJobStatus(postJob) {
    return this.http.put<any>(AppConstants.api_based_url + '/postJob/updateJobStatus', postJob).pipe(map(data => {
      if (this.responseService.isExistJobsPostedData) {
        this.responseService.updateJobsPostedDataStatus(postJob.id, 'Open', 'Open' )
      }
      return data;
    }))
  }

  deleteTaskById(task) {
    return this.http.put<any>(AppConstants.api_based_url + '/task/deleteById', task).pipe(map(data => {
      return data;
    }))
  }

  getPayables(): Observable<any> {
    if (this.responseService.isInvoicePayables()) {
      return of(this.responseService.getInvoicePayables())
    } else {
      return null
    }
  }

  getHistory(): Observable<any> {
    if (this.responseService.isExistPaymentHistory()) {
      return of(this.responseService.getPaymentHistory())
    } else {
      return null
    }
  }

  getPayments(obj, type , pageSize : number , pageNumber : number): Observable<any> {
     const key = `${pageNumber}_${pageSize}_${obj.status}`;
    const params = new HttpParams().set('pageSize', pageSize).set('pageNumber', pageNumber);
    if (this.responseService.isExistPaymentsData(key)) {
       return of(this.responseService.getPaymentsData(key))
    }
    else {
      return this.http.put<any>(AppConstants.api_based_url + '/invoice/getByCustomerIdPayments', obj , { params }).pipe(map(data => {
       if (!obj.jobId) {
        this.responseService.setPaymentsData(key , data)
       }
        return data ;
      }))
    } 
  }

  saveCard(card) {
    return this.http.post<any>(AppConstants.api_based_url + '/card/save', card).pipe(map(data => {
      return data;
    }))
  }

  saveBillingMethod(obj) {
    return this.http.post<any>(AppConstants.api_based_url + '/billingMethod/save', obj).pipe(map(data => {
      return data;
    }))
  }

  getBillingMethodByUserId(obj) {
    return this.http.put<any>(AppConstants.api_based_url + '/billingMethod/getByUserId', obj).pipe(map(data => {
      return data;
    }))
  }

  getAllCard() {
    return this.http.get<any>(AppConstants.api_based_url + '/card/getAll').pipe(map(data => {
      return data;
    }))
  }

  getServiceByRequestSent(jobId) {
    return this.http.get<any>(AppConstants.api_based_url + '/serviceProviderRegistration/getBySentRequest?jobId=' + jobId).pipe(map(data => {
      return data;
    }))
  }

  getHireByJobId(obj) {
    if (this.responseService.isExistJobHireDataList(obj.jobId)) {
      return of(this.responseService.getJobHireDataList(obj.jobId))
    } else {
      return this.http.put<any>(AppConstants.api_based_url + '/hired/getByJobId',obj).pipe(map(data => {
        this.responseService.setJobHireDataList(obj.jobId, data);
        return data;
      }))
     }
  }

  saveReview(obj) {
    return this.http.post<any>(AppConstants.review_url + '/review/save', obj).pipe(map(data => {
      return data;
    }))
  }



  payAndFinish(obj): Observable<any> {
    return this.http.put<any>(AppConstants.api_based_url + '/invoice/payAndFinish', obj).pipe(map(data => {
      // this.responseService.updateTaskAndInvoiceStatus(obj, data)
      return data;
    }))
  }



  dashboardCountData(obj) {
    return this.http.put<any>(AppConstants.api_based_url + '/postJob/dashboardCountData', obj).pipe(map(data => {
      return data;
    }))
  }

  saveSentRequest(obj) {
    return this.http.post<any>(AppConstants.api_based_url + '/sentRequest/save', obj).pipe(map(data => {
      return data;
    }))
  }

  sendReferralMailTocustomer(obj): Observable<any> {
    return this.http.post<any>(AppConstants.api_based_url + '/mail/sendmail', obj).pipe(map(data => {
      return data;
    }))
  }

 async saveReferralAndRewards (obj): Promise<any> {
  return await  lastValueFrom(this.http.post<any>(AppConstants.reward_url+ '/referralAndRewards/save',obj).pipe(map(data => {
      return data;
    })))
  }

  getAllRewardsByUserId(id): Observable<any> {
    if (this.responseService.isExistRewards()) {
      return of(this.responseService.getRewards())
    } else {
      return this.http.get<any>(AppConstants.review_url + '/referralAndRewards/getByUserId?userId=' + id).pipe(map(data => {
        this.responseService.setRewards(data)
        return data;
      }))
    }
  }

  getTaxByCategoryId(id) {
    console.log(id)
    return this.http.get<any>(AppConstants.api_based_url + '/tax/getByCategoryId?categoryId=' + id).pipe(map(data => {
      console.log(data)
      return data;
    }))
  }

  getAllDiscounts() {
    return this.http.get<any>(AppConstants.api_based_url + '/discount/getAllDiscounts').pipe(map(data => {
      console.log(data)
      return data;
    }))
  }

  getDiscountByCategoryName(name) {
    return this.http.get<any>(AppConstants.api_based_url + '/discount/getByCategoryName?categoryName=' + name).pipe(map(data => {
      console.log(data)
      return data;
    }))
  }

  saveAdvanceAmount(obj) {
    return this.http.post<any>(AppConstants.api_based_url + '/AdvanceAmount/saveadvanceAmount', obj).pipe(map(data => {
      return data;
    }))
  }

  getAdvanceAmountByCustomerId(customerId): Observable<any> {
    return this.http.get<any>(AppConstants.api_based_url + '/AdvanceAmount/getAdvanceAmount/' + customerId).pipe(map(data => {
      return data;
    }))
  }

  updateReferralAndRewards(obj) {
    return this.http.put<any>(AppConstants.review_url + '/referralAndRewards/updateReferralRewardsService/', obj).pipe(map(data => {
      return data;
    }))
  }

  updateAdvanceAmount(obj) {
    return this.http.put<any>(AppConstants.api_based_url + '/AdvanceAmount/updateadvanceAmount/', obj).pipe(map(data => {
      return data;
    }))
  }

  saveUsableAdvanceAmount(obj) {
    return this.http.post<any>(AppConstants.api_based_url + '/usableAdvanceAmount/save', obj).pipe(map(data => {
      this.responseService.setUseableAdvanceAmount(data)
      return data;
    }))

  }

  updateDepositedAmount(obj){
    return this.http.put<any>(AppConstants.api_based_url + '/usableAdvanceAmount/update', obj).pipe(map(data => {
      this.responseService.setUseableAdvanceAmount(data)
      return data;
    }))
  }

  isExistDepositedAmount(id){
    return this.http.get<any>(AppConstants.api_based_url + '/usableAdvanceAmount/isExist' , id).pipe(map(data=>{
      return data ;
    }))
  }
  getRejectedAdvanceAmount(customerId): Observable<any> {
    if (this.responseService.isExistUseableAdvanceAmount()) {
      return of(this.responseService.getUseableAdvanceAmount())
    } else {
      return this.http.get<any>(AppConstants.api_based_url + `/usableAdvanceAmount/get/${customerId}`).pipe(map(data => {
        this.responseService.setUseableAdvanceAmount(data)
        return data;
      }))
    }
  }

  updateRejectedJobAdvanceAmount(obj) {
    return this.http.put<any>(AppConstants.api_based_url + '/usableAdvanceAmount/update', obj).pipe(map(data => {
      this.responseService.setUseableAdvanceAmount(data)
      return data;
    }))
  }

  //written by Gowthami 28/10/2023
  isCustomerProfileExists(id): Observable<any> {
    if (this.responseService.getIsUserExist()) {
      return of(this.responseService.getIsUserExist())
    }
    return this.http.get(AppConstants.api_based_url + "/jobPosterRegistration/isCustomerProfileExsist?loginId=" + id).pipe(map(data => {
      this.responseService.setIsUserExist(data)
      return data;
    }))
  }

  getCustomerDetailsByloginId(loginId): Observable<any> {
    if (this.responseService.ifExistUserDetails()) {
      return of(this.responseService.getUserDetails())
    } else {
      return this.http.get<any>(AppConstants.api_based_url + '/jobPosterRegistration/getCustomerbyUserId?loginId=' + loginId).pipe(map(data => {
        this.responseService.setUserDetails(data)
        return data;
      }))
    }
  }

  userAccountGetById(id): Observable<any> {
    if (this.responseService.isExistUserAccountDetails()) {
      return this.responseService.getUserAccountDetails()
    } else {
      return this.http.get<any>(AppConstants.api_based_url + '/userAccount/getById?loginId=' + id).pipe(map(data => {
        this.responseService.setUserAccountDetails(data)
        return data;
      }))
    }
  }


  cancelUpdate(hireid) {
    return this.http.put(AppConstants.api_based_url+`/postJob/update/cancel-hire/${hireid}`,hireid).subscribe(jobber=>{
      return jobber;
    })
   }  
   // Swamy Nalla 22-03-2024
   getJobDetails(jobId) {
    return this.http.put<any>(AppConstants.api_based_url+`/postJob/getHireDataByJobId/${jobId}`,jobId).pipe(map(data => {
      return data;
    }))
   }

   getJobDetailsByJobId(jobId){
    return this.http.get<any>(AppConstants.api_based_url+`/postJob/findById/${jobId}`,jobId).pipe(map(data => {
      return data;
    }))
   }

   getAllDatesBySPUserAccountId(obj) {  
    return this.http.put<any>(AppConstants.api_based_url + '/hired/getAllBySP', obj).pipe(map(data => {
      this.responseService.setHiredSPDetails(obj.serviceproviderId,data);
      return data;
    }))
   }

   getLatestNotificationData(notification){
    return this.http.post<any>(AppConstants.api_based_url+'/notification/check',notification).pipe(map(data=>{
      return data ;
    }))
   }

  cancelJob(jobId) {
   return this.http.put<any>(AppConstants.api_based_url+`/postJob/update/cancel-job/${jobId}`,jobId).pipe(map(jobber=>{
      return jobber;
    }))
  }

  hireUpdateHireStatus(obj, status) {
    return this.http.put<any>(AppConstants.api_based_url + '/hired/updateHireStatus', obj, {params: new HttpParams().set('status', status)}).pipe(map(data => {
        return data;
      })
    ).subscribe();
  }

  getLockedDays(obj) {  
    return this.http.put<any>(AppConstants.api_based_url + '/hired/lockedDays', obj).pipe(map(data => {
      this.responseService.setHiredSPDetails(obj.serviceproviderId,data);
      return data;
    }))
   }
}
