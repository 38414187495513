import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, of, shareReplay } from 'rxjs';
import { ImageFileServiceService } from 'src/app/Services/image-file-service.service';
import { AppConstants } from 'src/app/appConstants';
import { ResponseServiceService } from 'src/app/jobposter-module/response-service.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceFileService {

  constructor(private http: HttpClient, private responseService: ResponseServiceService , private imageFile: ImageFileServiceService) { }


  updateNotificationsRead(obj): Observable<any> {
    console.log("read")
    return this.http.put<any>(AppConstants.api_based_url + '/sentNotifications/updateRead', obj).pipe(map(data => {
      data;
    }))
  }


  getNotificationsByReceiverId(obj): Observable<any> {
    return this.http.put<any>(AppConstants.api_based_url + '/sentNotifications/findByReceiverId', obj).pipe(map(data => {
      return data;
    }))
  }

  getNotificationCountByReceiverId(obj): Observable<any> {
    return this.http.put<any>(AppConstants.api_based_url + '/sentNotifications/getCountByReceiverId', obj).pipe(map(data => {
      return data;
    }))
  }

  // getUser():Observable<any> {
  //   return this.http.get<any>( AppConstants.api_based_url + '/user/getAll').pipe(map(data => {
  //     return data;
  //   }))
  // }
  getUserType(): Observable<any> {
    return this.http.get<any>(AppConstants.api_based_url + '/userType/getAll').pipe(map(data => {
      return data;
    }))
  }
  getCategory(): Observable<any> {
    return this.http.get<any>(AppConstants.api_based_url + '/category/getAll').pipe(map(data => {
      console.group(data)
      return data;
    }))
  }
  saveUser(user): Observable<any> {
    return this.http.post<any>(AppConstants.api_based_url + '/user/save', user).pipe(map(data => {
      return data;
    }))
  }

  // saveUserAccount(user):Observable<any> {
  //   return this.http.post<any>( AppConstants.api_based_url + "/userAccount/save?referralCode=?",user).pipe(map(data => {
  //    console.log(data.id)
  //     return data;
  //   }))
  // }
  saveUserAccount(user, referralCode = null): Observable<any> {
    return this.http.post<any>(`${AppConstants.api_based_url}/userAccount/save?referralCode=${referralCode}`, user);
  }



  // serviceProviderRegistration(user):Observable<any> {
  //   return this.http.post<any>( AppConstants.api_based_url + '/userAccount/save',user).pipe(map(data => {
  //         return data;
  //   }))
  // }

  verifyAccount(user): Observable<any> {
    return this.http.post<any>(AppConstants.api_based_url + '/userAccount/updateIsActivate', user).pipe(map(data => {
      return data;
    }))
  }

  getServiceByCatId(categoryId): Observable<any> {
    return this.http.get<any>(AppConstants.api_based_url + '/service/getByCategoryId?categoryId=' + categoryId).pipe(map(data => {
      return data;
    }))
  }

  sendEmailToUpdatePassword(user): Observable<any> {
    return this.http.put<any>(AppConstants.api_based_url + '/userAccount/sendEmailToUpdatePassword', user).pipe(map(data => {
      return data;
    }))
  }



  updatePasswordById(account): Observable<any> {
    return this.http.put(AppConstants.api_based_url + '/userAccount/resetPassword', account).pipe(map(data => {
      return data;
    }))
  }

  getServiceProviderRegByUSerAcctId(id): Observable<any> {
    if(this.responseService.isExistJobberProfile()){
       return of(this.responseService.getJobberExistingProfile());
    }
    else{
    return this.http.get(AppConstants.api_based_url + "/serviceProviderRegistration/getByUserAccountId?loginId=" + id).pipe(map(data => {
       this.responseService.setJobberExistingProfile(data);
      return data;
    }))
  }
  }

  isJobberProfileExsist(id): Observable<any> {
    return this.http.get(AppConstants.api_based_url + "/serviceProviderRegistration/isProfileExsist?loginId=" + id).pipe(map(data => {
      return data;
    }))
  }

  saveDocument(document): Observable<any> {
    return this.http.post(AppConstants.api_based_url + "/document/save", document).pipe(map(data => {
      return data;
    }))
  }

  //-----------------Not using at any where------------------//
  getAllDocument(): Observable<any> {
    return this.http.get(AppConstants.api_based_url + "/document/getAll").pipe(map(data => {
      return data;
    }))
  }

  getAllDocumentsByJobberId(useraccountId): Observable<any> {
    if (this.responseService.isExistAllDocumentsByJobberId()) {
      return of (this.responseService.getAllDocumentsByJobberId());
    }
    return this.http.get(AppConstants.api_based_url + '/document/getAllDocById?useraccountId=' + useraccountId).pipe(map(data => {
      this.responseService.setAllDocumentsByJobberId(data);
      return data;
    }))
  }
  //--------------------------------------------------------//
  //   getDocumentsByUserId(useraccountId){
  //     return this.http.get( AppConstants.api_based_url +"/document/getBySpUserAccountId",useraccountId).pipe(map(data => {
  //       return data;
  //  }))
  // }
  getByServiceProviderAwaiting(obj): Observable<any> {
    if (this.responseService.isExistServiceProviderAwaitingData()) {
      return of(this.responseService.getServiceProviderAwaitingData())
    } else {
      return this.http.put(AppConstants.api_based_url + "/hired/getByServiceProviderAwaiting", obj).pipe(map(data => {
        this.responseService.setServiceProviderAwaitingData(data);
        return data;
      }))
    }
  }

  getByServiceProviderActive(obj): Observable<any> {
    if (this.responseService.isExistServiceProviderHireData()) {
      return of(this.responseService.getServiceProviderHireData())
    } else {
      return this.http.put(AppConstants.api_based_url + "/hired/getByServiceProviderActive", obj).pipe(map(data => {
        this.responseService.setServiceProviderHireData(data);
        return data;
      }))
    }
  }

  getByServiceProviderUpcoming(obj): Observable<any> {
    if (this.responseService.isExistServiceProviderUpComingData()) {
      return of(this.responseService.getServiceProviderUpComingData())
    } else {
      return this.http.put(AppConstants.api_based_url + "/hired/getByServiceProviderUpcoming", obj).pipe(map(data => {
        this.responseService.setServiceProviderUpComingData(data);
        return data;
      }))
    }
  }


  getByServiceProviderPassed(obj): Observable<any> {
    if (this.responseService.isExistServiceProviderPassedData()) {
      return of(this.responseService.getServiceProviderPassedData())
    } else {
      return this.http.put(AppConstants.api_based_url + "/hired/getByServiceProviderPassed", obj).pipe(map(data => {
        this.responseService.setServiceProviderPassedData(data);
        return data;
      }))
    }
  }

  hireUpdateAccept(obj): Observable<any> {
    return this.http.put(AppConstants.api_based_url + "/hired/updateAccept", obj).pipe(map(data => {
      return data;
    }))
  }

  hireUpdateHireStatus(obj, status): Observable<any> {
    return this.http.put(AppConstants.api_based_url + "/hired/updateHireStatus?status=" + status, obj).pipe(map(data => {
      return data;
    }))
  }


  saveInvoice(obj): Observable<any> {
    return this.http.post(AppConstants.api_based_url + "/invoice/save", obj).pipe(map(data => {
      return data;
    }))
  }

  startWork(data) {
    this.http.put(AppConstants.api_based_url + `/postJob/update/work-status/${data.hire.hireId}/${2}`, data.hire.hireId).subscribe(data => {
    })
  }

  startFromLocation(data) {
    this.http.put(AppConstants.api_based_url + `/postJob/update/work-status/${data.hire.hireId}/${1}`, data.hire.hireId).subscribe(data => {
    })
  }

  hireCancleUpdateJob(obj): Observable<any> {
    return this.http.put(AppConstants.api_based_url + "/hired/updateCancle", obj).pipe(map(data => {
      return data;
    }))
  }

  hireReschedule(obj): Observable<any> {
    return this.http.put(AppConstants.api_based_url + "/hired/reschedule", obj).pipe(map(data => {
      return data;
    }))
  }

  hireExtended(obj): Observable<any> {
    return this.http.put(AppConstants.api_based_url + "/hired/extended", obj).pipe(map(data => {
      return data;
    }))
  }

  getInvoiceAwaitingPaymentsBySP(obj , pageSize : number , pageNumber : number): Observable<any> {
    const key = `${pageNumber}_${pageSize}`
    if (this.responseService.isExistJobberPayments(key)) {
      return of(this.responseService.getJobbersPayments(key));
    } else {
    const params = new HttpParams().set('pageSize', pageSize).set('pageNumber', pageNumber);
    return this.http.put(AppConstants.api_based_url + "/invoice/getBySPIdAwaitingPayment", obj , {params}).pipe(map(data => {
      if (!obj.jobId) {
      this.responseService.setjobbersPayments(key ,data);
      }
      return data;
    }))
  }
  }

  getInvoicePaymentsHistoryBySP(obj , pageSize : number , pageNumber : number): Observable<any> {
    const key = `${pageNumber}_${pageSize}`;
    if (this.responseService.isExistJobberPaymentHistory(key)) {
      return of (this.responseService.getJobberPaymentHistory(key));
    } else {
      const params = new HttpParams().set('pageSize', pageSize).set('pageNumber', pageNumber);
    return this.http.put(AppConstants.api_based_url + "/invoice/getBySPIdPaymentHistory", obj , {params}).pipe(map(data => {
      if (!obj.jobId) {
      this.responseService.setJobberPaymentHistory(key,data);
      }
      return data;
    }))
  }
  }


  getUserDetails(loginId) {
    // if (this.responseService.ifExistUserSPDetails()) {
    //   return of(this.responseService.getUserSPDetails())
    // } else {
    return this.http.get<any>(AppConstants.api_based_url + '/serviceProviderRegistration/getDetailsByLoginId?loginId=' + loginId).pipe(map(data => {
      // this.responseService.setUserSPDetails(data)
       return data;
    }))
  // }
  }

  saveRCHire(obj) {
    return this.http.post<any>(AppConstants.api_based_url + '/rCHire/save', obj).pipe(map(data => {
      return data;
    }))
  }

  //--------------------//
  //Gowthami 16/02/2024
  saveReason(obj) {
    return this.http.post<any>(AppConstants.api_based_url + '/reason/saveReason', obj).pipe(map(data => {
      return data;
    }))
  }
  //-------------------//

  getAllReasons() {
    if (this.responseService.isExistGetAllReasons()) {
      return of(this.responseService.getGetAllReasons())
    } else {
      return this.http.get<any>(AppConstants.api_based_url + '/reason/getAll').pipe(map(data => {
        this.responseService.setGetAllReasons(data);
        return data;
      }))
    }
  }

  documentSave(formData, headers) {
    return this.http.post<any>(AppConstants.api_based_url + '/document/save', formData, { headers: headers }).pipe(map(data => {
      return data;
    }))
  }

  getDocument(useraccountId) {
    if (this.responseService.isExistGetDocument()) {
      return of(this.responseService.getGetDocument())
    } else {
      return this.http.get<any>(AppConstants.api_based_url + '/document/getByUserAccountId?useraccountId=' + useraccountId).pipe(map(data => {
        this.responseService.setGetDocument(data);
        return data;
      }))
    }
  }

  // saveServiceproviderRegistration(formData,headers){
  //   return this.http.post<any>( AppConstants.api_based_url + '/serviceProviderRegistration/save', formData,{headers: headers}).pipe(map(data => {
  //         return data;
  //   }))
  // }

  saveServiceproviderRegistrationObj(obj) {
    return this.http.post<any>(AppConstants.api_based_url + '/serviceProviderRegistration/saveObj', obj).pipe(map(data => {
      return data;
    }))
  }


  // saveProfileImage(formData, headers) {
  //   return this.http.post<any>(AppConstants.api_based_url + '/serviceProviderRegistration/saveProfileImage', formData, { headers: headers }).pipe(map(data => {
  //     return data;
  //   }))
  // }
  saveProfileImageDetails(obj , headers) {
    return this.http.put<any>(AppConstants.api_based_url + '/serviceProviderRegistration/saveProfileImage', obj, { headers: headers }).pipe(map(data => {
     console.log(data)
      return data;
    }))
  }

  getProfileImage(loginId) {
    if (this.responseService.isExistGetProfileImage()) {
      return of(this.responseService.getGetProfileImage())
    } else {
      return this.http.get<any>(AppConstants.api_based_url + '/serviceProviderRegistration/getProfileImage?loginId=' + loginId).pipe(map(data => {
        this.responseService.setGetProfileImage(data);
        return data;
      }))
    }
  }

  editSaveSPRegistration(formData, headers) {
    return this.http.post<any>(AppConstants.api_based_url + '/serviceProviderRegistration/editSave', formData, { headers: headers }).pipe(map(data => {
      return data;
    }))
  }

  updatePassword(account, headers) {
    return this.http.post<any>(AppConstants.api_based_url + "/userAccount/updatePassword", account, { headers: headers }).pipe(map(data => {
      return data;
    }))
  }

  getReviewCounts(obj) {

    return this.http.put<any>(AppConstants.review_url + "/review/getCounts", obj).pipe(map(data => {
      return data;
    }))
  }

  getReviewAverage(obj) {
    return this.http.put<any>(AppConstants.review_url+ "/review/getAverage", obj).pipe(map(data => {
      this.responseService.setgetReviewAverage(data);
      return data;
    }))
  }
  getReviewsAndAverage(serviceproviderIds) {
    if (this.responseService.isExistgetReviewAverage()) {

      return of(this.responseService.getgetReviewAverage())
    } else {
      return this.http.post<any>(AppConstants.review_url + "/review/reviews", serviceproviderIds).pipe(map(data => {
 
        shareReplay(1)
        this.responseService.setgetReviewAverage(data);
        return data;
      }))
    }
  }

  getReviewBySpId(obj) {
    return this.http.put<any>(AppConstants.review_url + "/review/getBySpId", obj).pipe(map(data => {
      return data;
    }))
  }


  deleteRate(id) {
    return this.http.get<any>(AppConstants.api_based_url + "/rate/deleteById?id=" + id).pipe(map(data => {
      return data;
    }))
  }
  
  updateTax(obj) {
    return this.http.put<any>(AppConstants.api_based_url + '/tax/updateTax', obj).pipe(map(data => {
      return data;
    }))
  }

  getAllTax(): Observable<any> {
    return this.http.get<any>(AppConstants.api_based_url + '/tax/getAll').pipe(map(data => {
      return data;
    }))
  }

  saveTax(obj) {
    return this.http.post<any>(AppConstants.api_based_url + '/tax/saveTax', obj).pipe(map(data => {
      return data;
    }))
  }

  //  saveAdminRewards(obj){
  //   return this.http.post<any>(AppConstants.api_based_url+'/adminRewards/save',obj).pipe(map(data=>{
  //     return data;
  //   }))
  //  }

  //  updateAdminRewards(obj){
  //   return this.http.put<any>(AppConstants.api_based_url+'/adminRewards/update',obj).pipe(map(data=>{
  //     return data;
  //   }))
  //  }

  //  getAllAdminRewards():Observable<any>{
  //   return this.http.get<any>(AppConstants.api_based_url+'/adminRewards/getAll').pipe(map(data=>{
  //     return data;
  //   }))
  //  }

  saveUsableAdvanceAmount(obj) {
    return this.http.post<any>(AppConstants.api_based_url + '/usableAdvanceAmount/save', obj).pipe(map(data => {
      return data;
    }))
  }

  //  getRejectedAdvanceAmount(customerId):Observable<any>{
  //   console.log(customerId)
  //   return this.http.get<any>(AppConstants.api_based_url+`/usableAdvanceAmount/get/${customerId}`).pipe(map(data=>{
  //     return data;
  //   }))
  //  }

  //  updateRejectedJobAdvanceAmount(obj){
  //   return this.http.put<any>(AppConstants.api_based_url+'/usableAdvanceAmount/update',obj).pipe(map(data=>{
  //     return data;
  //   }))
  //  }

  getPaymentThoughByJobId(hireId): Observable<any> {
    console.log(hireId)
    return this.http.get<any>(AppConstants.api_based_url + `/hired/get/${hireId}`).pipe(map(data => {
      return data;
    }))
  }

  //  countOfAdvanceAmount(customerId):Observable<any>{
  //   console.log(customerId)
  //   return this.http.get<any>(AppConstants.api_based_url+`/usableAdvanceAmount/get/${customerId}`).pipe(map(data=>{
  //     return data;
  //   }))
  //  }

  userAccountGetById(id): Observable<any> {
    return this.http.get<any>(AppConstants.api_based_url + '/userAccount/getById?loginId=' + id).pipe(map(data => {
      return data;
    }))
  }

  //  getDatesByServiceProviderId(serviceproviderId){
  //   return this.http.get( AppConstants.api_based_url +"/hired/getBySPId?serviceproviderId="+serviceproviderId).pipe(map(data => {
  //    console.log(data)
  //     return data;
  //  }))
  // }
  //   getDatesByServiceProviderId(serviceproviderId){
  //     return this.http.get(`${AppConstants.api_based_url}/hired/getBySPId/${serviceproviderId}`).pipe(
  //         map(data => {
  //             console.log(data);
  //             return data;
  //         })
  //     );
  // }

  //Gowthami 04-03-2024
      getAllDatesBySPUserAccountId(obj): Observable<any> {  
        if (this.responseService.ifExistHiredSPDetails(obj.serviceproviderId)) {
          return of(this.responseService.getHiredSPDetails(obj.serviceproviderId))
        } else {
      
        return this.http.put<any>(AppConstants.api_based_url + '/hired/getAllBySP', obj).pipe(map(data => {
          this.responseService.setHiredSPDetails(obj.serviceproviderId,data);
          return data;
        }))
      }
      }
           //Bhabani 15/03/2024

           saveUserNotificationSettings(obj): Observable<any> {
            return this.http.post<any>(AppConstants.api_based_url + '/user-notification-settings/save', obj ).pipe(map(data => {
              return data;
            }))
          }
          getUserNotificationSettings(id):Observable<any> {
            return this.http.get<any>( AppConstants.api_based_url + '/user-notification-settings/get/'+id).pipe(map(data => {
                  return data;
               }))
             }

             //Bhabani 02-05-2024
             createTransferOrder(amount,info,customer,email,name): Observable<any> {
              return this.http.post<any>(AppConstants.api_based_url + `/billingMethod/create_transfer_order?amount=${amount}&info=${info}&customer=${customer}&email=${email}&name=${name}`,amount,info ).pipe(map(data => {
                return data;
              }))
            }

            //Bhabani 03-05-2024
            getWalletAmount(userId): Observable<any> {
              return this.http.get<any>(AppConstants.api_based_url + '/billingMethod/getSpAmount/'+userId).pipe(map(data => {
                return data;
              }))
            }

            sendRefundAmmount(obj): Observable<any> {
              return this.http.post<any>(AppConstants.api_based_url + '/billingMethod/refund', obj ).pipe(map(data => {
                return data;
              }))
            }

            verifyBankAccount(obj): Observable<any> {
              return this.http.post<any>(AppConstants.api_based_url + '/billingMethod/validate', obj ).pipe(map(data => {
                return data;
              }))
            }


            getBankDetails(userId): Observable<any> {
              return this.http.get<any>(AppConstants.api_based_url + '/billingMethod/getBankDetails/'+userId).pipe(map(data => {
                return data;
              }))
            }

             //04-05-2024
             getAllJobberActiveJobs(obj , pageSize : number , pageNumber : number){
              const params = new HttpParams().set('pageSize', pageSize).set('pageNumber', pageNumber);
              const key = `${pageNumber}_${pageSize}`;
              if(this.responseService.isExistJobberActiveJobs(key)){
                return of(this.responseService.getJobberActiveJobs(key));
              }else{
               return this.http.put<any>(AppConstants.api_based_url + '/hired/getByServiceProviderActive' , obj , {params} ).pipe(map(data => {
                if (!obj.id) {
                this.responseService.setJobberActiveJobs(key , data)
                }
                return data;
             }))
            }
             }
             getAllJobberUpcomingJobs(obj, pageSize : number , pageNumber : number){
              const params = new HttpParams().set('pageSize', pageSize).set('pageNumber', pageNumber);
              const key = `${pageNumber}_${pageSize}`;
              if(this.responseService.isExistJobberUpcomingJobs(key)){
                return of(this.responseService.getJobberUpcomingJobs(key));
              }else{
              return this.http.put<any>(AppConstants.api_based_url + '/hired/getByServiceProviderUpcoming' , obj , {params}).pipe(map(data => {
                if (!obj.id) {
                this.responseService.setJobberUpcomingJobs(key , data)
                }
                return data;
            }))
          }
            }
            getAllJobberPassed(obj, pageSize : number , pageNumber : number){
              const params = new HttpParams().set('pageSize', pageSize).set('pageNumber', pageNumber);
              const key = `${pageNumber}_${pageSize}`;
              if(this.responseService.isExistJobberCompletedJobs(key)){
                return of(this.responseService.getJobberCompletedJobs(key));
              }else{
              return this.http.put<any>(AppConstants.api_based_url + '/hired/getByServiceProviderPassed' , obj , {params}).pipe(map(data => {
                 if (!obj.id) {
                this.responseService.setJobberCompletedJobs(key , data)
                 }
                return data;
            }))
          }
            }
            getAllJobberOpenJobs(obj, pageSize : number , pageNumber : number){
              const key = `${pageNumber}_${pageSize}`;
              if(this.responseService.isExistJobberOpenJobs(key)){
                return of(this.responseService.getJobberOpenJobs(key));
              }else{
              const params = new HttpParams().set('pageSize', pageSize).set('pageNumber', pageNumber);
              return this.http.put<any>(AppConstants.api_based_url + '/hired/getByServiceProviderAwaiting' , obj , {params}).pipe(map(data => {
                if (!obj.id) {
                this.responseService.setJobberOpenJobs(key , data)
                }
                return data;
            }))
          }
            }
            getAllJobberCancelled(obj, pageSize : number , pageNumber : number){
              const params = new HttpParams().set('pageSize', pageSize).set('pageNumber', pageNumber);
              const key = `${pageNumber}_${pageSize}`;
              if(this.responseService.isExistJobberCancelledJobs(key)){
                return of(this.responseService.getJobberCancellledJobs(key));
              }else{
              return this.http.put<any>(AppConstants.api_based_url + '/hired/getByServiceProviderCancelled' , obj , {params}).pipe(map(data => {
                if (!obj.id) {
                this.responseService.setJobberCancelledJobs(key , data)
                }
                return data;
            }))
          }
            }

  

          deleteBankAccount(obj) {
            return this.http.post<any>(AppConstants.api_based_url + '/billingMethod/deleteBank', obj ).pipe(map(data => {
              return data;
            }))
          }

          sendToSource(obj) {
            return this.http.post<any>(AppConstants.api_based_url + '/billingMethod/funds_transfer', obj ).pipe(map(data => {
              return data;
            }))
          }

          getTransactionData(userId: any) {
            return this.http.get<any>(AppConstants.api_based_url + '/billingMethod/getTransactionData/'+userId).pipe(map(data => {
              return data;
            }))
          }

          getStatus(transactions) {
            return this.http.post<any>(AppConstants.api_based_url + '/billingMethod/getStatus', transactions ).pipe(map(data => {
              return data;
            }))
          }

          getJobDetails(jobId) {
            return this.http.put<any>(AppConstants.api_based_url+`/postJob/getHireDataByJobId/${jobId}`,jobId).pipe(map(data => {
              return data;
            }))
           }

    getLockedDays(obj) {  
    return this.http.put<any>(AppConstants.api_based_url + '/hired/lockedDays', obj).pipe(map(data => {
      this.responseService.setHiredSPDetails(obj.serviceproviderId,data);
      return data;
    }))
    }

}
