import { Injectable } from '@angular/core';
import { AppConstants } from '../appConstants';
import { Observable, catchError, map, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResponseServiceService } from '../jobposter-module/response-service.service';

@Injectable({
  providedIn: 'root'
})
export class ImageFileServiceService {

  constructor(private http: HttpClient , private responce :ResponseServiceService) { }

  saveImage(profileImageFile, headers): Observable<any> {
    return this.http.post<any>(AppConstants.api_based_url + "/image-details/save-image?imageFile", profileImageFile, { headers: headers }).pipe(map(data => {
     console.log(data)
      return data;
    }))
  }

  // getImage(imageId: string){
  //   if(this.responce.isExistImageByImageId){
  //     this.responce.getImageByImageId() ;
  //   }
  //   else{
  //   return this.http.get<any>(AppConstants.api_based_url + "/image-details/get-image/" +imageId).pipe(map(data => {
  //        this.responce.setImageByImageId(data) ;
  //     return data;
  //      })
  //   );
  //     }
  //  }
  
  getImage(imageId: string){
     if (this.responce.isExistImageByImageId(imageId)) {
        return of(this.responce.getImageByImageId(imageId));
    } 
    else{
       return this.http.get<any>(AppConstants.api_based_url + "/image-details/get-image/" + imageId).pipe(map(data => {
        this.responce.setImageByImageId(imageId ,data);
         return data;
       }));
      }
   }
   

   getMultipleImages(imageId: string){
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    if(this.responce.isExistMultipleImagesByImageIds(JSON.stringify(imageId))) {
      return of(this.responce.getMultipleImagesByImageId(JSON.stringify(imageId))) ;
    }
    else{
    return this.http.post<any>(AppConstants.api_based_url + "/image-details/get-image" ,imageId, { headers: headers }).pipe(map(data => {
      this.responce.setMultipleImagesByImageId(JSON.stringify(imageId) ,data) ;
      return data;
        }));
       }
   }
  //  imageUUID: any
  //  setImageID(data){
  //   this.imageUUID = data.body.serviceProviderProfileCls.imageUUID;
  //  }
  //  getImageId(){
  //    return this.imageUUID ;
  //  }

    reviewNotifications(obj , params){
      return this.http.post<any>(AppConstants.api_based_url + "/review/sendmail",obj,{ params: params }).pipe(map(data => {
       return data ;
        }));
    }

    getServerSessionData(){
      return this.http.get<any>(AppConstants.api_based_url+"/review/fetchSessionData").pipe(map(data=>{
        return data ;
      }))
    }
   
    updateReviews(obj , params){
      return this.http.put<any>(AppConstants.api_based_url+"/postJob/updateReviews" , obj , { params: params }).pipe(map(data=>{
        return data;
      }))
    }

    deleteDocuments(id) {
     return this.http.delete<any>(AppConstants.api_based_url + "/document/deleteDocument?id="+id).pipe(map(data => {
      return data;
     }))
    }

    updateDocuments( formData, headers ) {
      return this.http.put<any>(AppConstants.api_based_url + "/document/updateDocuments",  formData, { headers: headers }).pipe(map(data => {
        return data;
      }))
    }
  }
   
