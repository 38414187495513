import { Component } from '@angular/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';

@Component({
  selector: 'app-popular-categories',
  templateUrl: './popular-categories.component.html',
  styleUrls: ['./popular-categories.component.scss']
  
})
export class PopularCategoriesComponent {
imageUrl: "https://ehelperteam.com/wp-content/uploads/2019/09/Broken-images.png";


warning() {
  alert("its not implemented.....") 
}
}
