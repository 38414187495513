

<ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button (click)="goBack()">
          <ion-icon src="assets/figma/left.svg"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title>Terms and Conditions</ion-title>
    </ion-toolbar>
  </ion-header>

<div class="container">
    <div class="row">
        <div class="col-12">

<h1 style="text-align: center; padding-top: 18px;font-weight: 500;font-weight: bold;">TERMS AND CONDITIONS</h1>
<h2 style="font-size: 35px; font-weight: 600; padding-top: 35px;">Introduction</h2>
<p >
    Welcome to AceJobber! These Terms and Conditions ("Terms") apply to your
    use of the AceJobber services, which are accessible via our website at
    <a href="https://www.acejobber.com">https://www.acejobber.com</a> and
    through the AceJobber mobile application (together referred to as the
    "Platform"). Alongside these Terms, your use of the Platform is also
    governed by our Privacy Policy, available at
    <a href="https://www.acejobber.com/privacy-policy"
      >https://www.acejobber.com/privacy-policy</a
    >, and any additional guidelines, terms, policies, and disclaimers we may
    provide from time to time (collectively, "Supplemental Terms"). The
    Privacy Policy and any Supplemental Terms are considered part of these
    Terms and should be read in conjunction with them. Should there be any
    inconsistency between these Terms and the Supplemental Terms regarding the
    Services, the Supplemental Terms will take precedence.
  </p>
<h2 class="heading-1" >A. Governing Terms</h2>
<p class="pera-1">These Terms outline the rules for using the services offered on or through the AceJobber Platform ("Services"). This
    includes our Privacy Policy and any additional guidelines, terms, policies, and disclaimers
    we may issue periodically ("Supplemental Terms").
    The Privacy Policy and Supplemental Terms are integral to these Terms, providing additional detail and guidance. In
    cases where these Terms might
    conflict with the Supplemental Terms regarding specific Services, the Supplemental Terms will have precedence,
    ensuring the most relevant and specific
    guidelines apply.</p>
<h2 class="heading-1">B. Legal Agreement</h2>
<p class="pera-1">This section forms a legally binding contract between [Your Company Name] ("AJ", "we", "us", or "our")
    and you — either as an individual user of our Services or as a legal entity arranging for Professional Services
    ("Pro Services", as defined later) on behalf
    of end-users ("you" or "Customer"). By engaging with our Services, you affirm that you possess the legal capacity
    and authority to enter into and adhere
    to these Terms. Should you be representing another individual or entity, you hereby declare that you have the
    requisite authorization to bind such party to these Terms.</p>
<h2 class="heading-1">C. Acceptance and Compliance</h2>
<p class="pera-1">In utilizing our Services, you acknowledge that you have thoroughly reviewed, comprehend, and consent to be bound by
    these Terms, which may be revised
    periodically. These Terms unequivocally replace any prior agreements between you and us. If you find yourself in
    disagreement with these Terms or unable
    to adhere to the stipulated requirements, we kindly ask that you refrain from using our Services.</p>
<h3 class="heading-1">1. SERVICES</h3>

    <section class="pera-1" >
        <b class="bolde"> (a) </b><p class="para">The Services include the provision of the Platform that enables you to arrange and schedule different
            professional services with independent third-party service providers of those services or skilled
            individuals (“Service Professionals”). As a part of the Services, AJ facilitates the transfer of payments to
            Service Professionals for the services they render
            to you and collects payments on behalf of such Service Professionals.</p></section>
    <section class="pera-1" >
        <b class="bolde">(b) </b><p class="para">The services rendered by Service Professionals are referred to as “Pro Services”. The term “Services” does
        not include the Pro Services.
        AJ does not provide the Pro Services and is not responsible their provision. Service Professionals are solely
        liable and responsible for the Pro Services
        that they offer or otherwise provide through the Platform. AJ and its affiliates do not employ Service
        Professionals, nor are Service Professionals agents,
        contractors, or partners of AJ or its affiliates. Service Professionals do not have the ability to bind or
        represent AJ.</p></section>
    <section class="pera-1" > <b class="bolde">(c) </b> <p class="para">The Platform is for your personal and non-commercial use only, unless otherwise agreed upon on in accordance
        with the terms of a separate agreement.
        Please note that the Platform is intended for use only within India. You agree that in the event you avail the
        Services or Pro Services from a
        legal jurisdiction other than the territory of India, you will be deemed to have accepted the AJ terms and
        conditions applicable to that jurisdiction.</p></section>
    <section class="pera-1" ><b class="bolde">(d) </b><p class="para">The Services are made available under various brands owned by or otherwise licensed to AJ and its
        affiliates.</p></section>
    <section class="pera-1" ><b class="bolde">(e) </b> <p class="para">A key part of the Services is AJ’s ability to send you text messages, electronic mails,
        or WhatsApp messages, including in connection with your bookings, your utilisation
        of the Services, or as a part of its promotional and marketing strategies. While you
        may opt out of receiving these text messages by contacting AJ
        at privacy@acejobber.com or through the in-Platform settings, you agree and
        acknowledge that this may impact AJ’s ability to provide the Services (or a part of the
        Services) to you.</p></section>
        <section class="pera-1" ><b class="bolde">(f) </b> <p class="para">
            In certain instances, you may be required to furnish identification proof to avail the
            Services or the Pro Services, and hereby agree to do so. A failure to comply with this
            request may result in your inability to use the Services or Pro Services.</p></section>

        <section class="pera-1" style="display: flex;padding-top: 8px;flex-direction: column;" >
            <div style="display: flex;margin-bottom: 30px;">
                <b class="bolde">(g)</b><p style="text-align: justify;font-size: 19px;color: black;text-decoration: underline;">AJ Credits:</p>
            </div>
           
                          <div style="padding-left: 50px;">
                            <ul class="braces" style="text-align: justify;padding-left: 20px;" >
                                <li style="padding-left: 18px;">AJ may, in its sole discretion, offer promotional codes that may be redeemed
                                    for credits, other features or benefits related to the Services, and/or Pro Services,
                                    subject to any additional terms that may apply on a promotional code <b>(“AJ
                                    Credits”)</b>.</li>
                                 <li style="padding-left: 18px;">   You agree that (i) you shall use AJ Credits in a lawful manner, and only for the
                                        purposes specified by such AJ Credits, (ii) you shall not duplicate, sell, or transfer
                                        the AJ Credits in any manner (including by posting such codes on a public
                                        forum) unless you have AJ’s express prior consent to do so, (iii) AJ Credits may
                                        be disabled by AJ at any time for any reason without any liability to you, (iv) AJ
                                        Credits are not valid for cash, and (v) AJ Credits may expire prior to your use.</li>
                                 <li style="padding-left: 18px;">   AJ may, at its sole discretion, provide only certain users with AJ Credits that may
                                        result in different amounts charged for the same or similar services obtained by
                                        other users.</li>
                                 <li style="padding-left: 18px;">   AJ reserves the right to withhold or deduct credits or other features or benefits
                                        obtained through the use of AJ Credits, by you or any other user, if AJ reasonably
                                        determines or believes that the use or redemption of the AJ Credits was in error,
                                        fraudulent, illegal, or in violation of the applicable AJ Credit terms or these
                                        Terms.</li>
                               </ul>
                          </div>
        </section>

<h3 class="heading-1">2. ACCOUNT</h3>
<div class="d-flex flex-column ">
    
    <section class="pera-1" >
        <b class="bolde">(a)</b> <p class="para">In order to access and utilize the Services, you must register for an account ("User Account") on the
        Platform. This account may be for personal or professional use, but may not be transferred to any other person
        or entity without our express written consent. If you create an account for another person or entity, you hereby
        represent and warrant that you have the necessary authority to do so.</p></section>
        <section class="pera-1" >
            <b class="bolde"> (b)</b> <p class="para">The User Account must contain accurate, up-to-date, and complete information, including your full name,
        email address, and phone number. Any changes to this information must be promptly updated within your User
        Account. If we reasonably believe that the information provided in your User Account is false, inaccurate, or
        misleading, we reserve the right to suspend or terminate your access to the Services.</p></section>
    
        <section class="pera-1" >
            <b class="bolde">(c) </b> <p class="para">You are solely responsible for the security of your User Account and the use of your account details. You
        are responsible for all activities that occur under your User Account, including the Pro Services you book and
        payments made through your User Account. We will not be liable for any unauthorized use of your User Account or
        for any other damages or losses that may arise from such unauthorized use. We recommend that you maintain secure
        access to your User Account by utilizing a unique username and password, enabling two-factor authentication
        where possible, and not sharing your login credentials with others.</p></section>
        <section class="pera-1" >
            <b class="bolde"> (d) </b><p class="para">You are liable and accountable for all activities that take place through your Account,
                including activities performed by persons other than you. We shall not be liable for
              any unauthorised access to your Account.</p></section>
    <section class="pera-1" >
    <b class="bolde"> (e) </b><p class="para">You agree to receive communications from us regarding (i) requests for payments, (ii)
        information about us and the Services, (iii) promotional offers and services from us
        and our third-party partners, and (iv) any other matter in relation to the Services..</p></section>
</div>

<h3 class="heading-1">3. USER CONTENT</h3>
            
<section class="pera-1" >
    <b class="bolde"> (a) </b><p class="para">Our Platform may contain interactive features or services that allow users who have
        created an account with us to post, upload, publish, display, transmit, or submit
        comments, reviews, suggestions, feedback, ideas, or other content on or through the
        Platform <b>(“User Content”).</b></p></section>

                    
<section class="pera-1" >
    <b class="bolde"> (b) </b><p class="para">As part of the effective provision of the Services and quality control purposes, we may
        request reviews from you about Service Professionals and you agree and acknowledge
        that Service Professionals may provide reviews about you to us. You must not
        knowingly provide false, inaccurate, or misleading information in respect of the
        reviews. Reviews will be used by us for quality control purposes and to determine
        whether Customers and Service Professionals are appropriate users of the Platform. If
        we determine at our sole discretion that you are not an appropriate user, we reserve
        the right to cancel your registration and remove you from our Platform.</p></section>

                    
<section class="pera-1" >
    <b class="bolde"> (c) </b><p class="para">
        You grant us a non-exclusive, worldwide, perpetual, irrevocable, transferable,
        sublicensable, and royalty-free licence to (i) use, publish, display, store, host, transfer,
        process, communicate, distribute, make available, modify, adapt, translate, and create
        derivative works of, the User Content, for the functioning of, and in connection with,
        the Services and (ii) use User Content for the limited purposes of advertising and
        promoting the Services, or furnishing evidence before a court or authority of
        competent jurisdiction under applicable laws.</p></section>

                    
        <section class="pera-1" >
            <b class="bolde"> (d) </b><p class="para">
                In connection with these Terms and the licences granted under this clause, you hereby
                waive any claims arising out of any moral rights or other similar rights relating to the
                User Content.</p></section>

                <section class="pera-1" >
                    <b class="bolde"> (e) </b><p class="para">
                        You agree and acknowledge that AJ may, without notice to you, remove, or otherwise
                        restrict access to User Content that, in its sole discretion, violates these Terms.</p></section>



<h3 class="heading-1">4. CONSENT TO USE DATA</h3>

    <section class="pera-1" >
        <b class="bolde"> (a) </b>  <p class="para">Services are generally provided at no cost to you. However, we may impose fees or charges for specific
        features or functionality, such as additional premium services or support options. In such cases, we will inform
        you of the applicable fees and seek your consent before they are charged to your User Account. We reserve the
        right to modify these fees or charges at any time without notice.</p></section>

        <section class="pera-1" >
            <b class="bolde"> (b)</b>  <p class="para">We facilitate payments to Service Professionals through the Platform. In some cases, you may need to add
        your credit card or bank account details to your User Account to enable such payments. By adding your payment
        details to your User Account, you agree that you are responsible for the payment and will be liable for any
        transactions made through your User Account.</p></section>

        <section class="pera-1" >
            <b class="bolde"> (c) </b> <p class="para">certain situations, Service Professionals may choose to bill you directly for the Pro Services they
        provide. In such cases, the payment obligations will be between you and the Service Professionals. AJ does not
        handle any payments or transactions between you and the Service Professionals. AJ does not have the ability to
        modify, reverse, or control payments made directly to Service Professionals.</p></section>

<h3 class="heading-1">5. BOOKINGS</h3>

<section class="pera-1" >
    <b class="bolde">(a) </b> <p class="para">
     <span style="text-decoration: underline; font-size: 19px;"><u>Orders :</u> </span>certain situations, Service Professionals may choose to bill you directly for the Pro Services they
provide. In such cases, the payment obligations will be between you and the Service Professionals. AJ does not
handle any payments or transactions between you and the Service Professionals. AJ does not have the ability to
modify, reverse, or control payments made directly to Service Professionals.</p></section>


<section class="pera-1" >
    <b class="bolde">(b) </b> <p class="para">
     <span style="text-decoration: underline; font-size: 19px;"><u>Confirmation : </u> </span>
     Once you place a request we will provide confirmation of the booking
     via SMS, email or a push notification. Once your booking has been confirmed, you will
     be required to make the payment in accordance with these Terms or as indicated on
     the Platform. Once a Service Professional has been identified for the requested Pro
     Services, you shall receive confirmation in App or via SMS, email or a push notification.</p></section>


<section class="pera-1" >
    <b class="bolde">(c) </b> <p class="para">
     <span style="text-decoration: underline; font-size: 19px;"><u>Cancellations :</u> </span>
     Bookings that are cancelled before confirmation on the Platform will
not be charged. AJ’s cancellation policy sets out applicable cancellation fees.</p></section>


<section class="pera-1" >
    <b class="bolde">(d) </b> <p class="para">
     <span style="text-decoration: underline; font-size: 19px;"><u>Substitution :</u> </span>
     In case of the unavailability of, or cancellation by a selected Service
Professional, we will offer you a substitute of the Service Professional from among our
registered Service Professionals.</p></section>





<h3 class="heading-1">6. PRICING, FEES, AND PAYMENT TERMS</h3>

<section class="pera-1" >
    <b class="bolde">(a) </b> <p class="para">
     <span style="text-decoration: underline; font-size: 19px;"><u>Substitution :</u> </span>
     AJ reserves the right to charge you for the different Services you may avail and/or for
     any other facilities you may opt for, from time to time, on or via the Platform.</p></section>

  
<section class="pera-1" style="display: flex;padding-top: 8px;display: flex;padding-top: 8px;flex-direction: column">
    
    <div style="display: flex;margin-bottom: 30px;">
        <b class="bolde">(b) </b> 
     <span style="text-decoration: underline; font-size: 19px;"><u>Charges and Fees in respect of Pro Services :</u> </span></div>
     <ul class="" style="text-align: justify;padding-left: 75px;padding-top: 10px; list-style: lower-roman;" >
        <li style="padding-top: 7px;">In respect of Pro Services that you seek to avail through the Platform, you shall
            be required to pay Service Professionals the amount indicated at the time of
            booking as well as amounts towards (a) any additional Pro Services you may
            avail, (b) out of pocket expenses incurred by the Service Professional, and (c)
            expenses arising out of the purchase of goods required or utilised for the
            performance of the Pro Service (“Charges”). In addition to the Charges payable
            to Service Professionals, AJ reserves the right to charge you a convenience fee
            for facilitating the booking and transferring payments to the Service
            Professional (this fee is referred to as “Fees”). You acknowledge that the final bill
            you receive may include additional charges, including without limitation, a safety
            fee, warranty fee, insurance fee, or Service Professional welfare fee.</li>

            <li style="padding-top: 7px;">AJ shall notify you of the applicable Charges, Fees, and payment methods at the
                time of booking. Generally, you may make payments for Pro Services through
                credit cards, debit cards, net banking, wallets, UPI or cash upon completion of
                the Pro Service. We have the right to modify and otherwise restrict the modes
                of payment available to you. You acknowledge that certain payment methods
                such as cash upon completion may not always be available to you as a paymentmethod
                . For the avoidance of doubt,  in the event you pay through the method  
                of ‘cash upon completion’, you acknowledge that you will be required to pay
                both Charges and Fees to the Service Professional.</li>
                <li style="padding-top: 7px;">The Charges and Fees may be payable at the time of making a booking, or upon
                    the completion of the Pro Service, as specified by AJ.</li>
                <li style="padding-top: 7px;">For the avoidance of doubt, please note that the Charges are payable to Service
                    Professionals, and UC acts as a limited collection agent on behalf of such Service
                    Professionals to collect and transfer amounts due to them.</li>
                <li style="padding-top: 7px;"> <u>Taxes:</u>All Charges and Fees are inclusive of applicable taxes.</li>
                <li style="padding-top: 7px;">AJ reserves the right to reasonably amend the Charges and Fees at any time at
                    its sole discretion. A change in Fees shall not impact any bookings that have
                    been confirmed before the publication of the revised Fees on the Platform.</li>
                <li style="padding-top: 7px;">Charges and Fees that you pay are final and non-refundable, unless otherwise
                    determined by AJ or required by the applicable laws. Under certain laws, you
                    may be entitled to a refund or other remedies for a failure in the provision of
                    the Services.</li>
                <li style="padding-top: 7px;">You acknowledge and agree that Charges and Fees applicable in certain
                    geographical areas may increase substantially during times of high demand. UC
                    will use reasonable efforts to inform you of the Charges and Fees that may apply.
                    However, by using the Pro Services or Services, you will be responsible for the
                    Charges and Fees incurred under your Account regardless of your awareness of
                    such Charges or Fees.</li>
     </ul>
     </section>


     <section class="pera-1" >
        <b class="bolde">(c) </b> <p class="para">
         <span style="text-decoration: underline; font-size: 19px;"><u>Payment Processors: </u> </span>
         We may use a third-party payment processor <b>(“Payment
         Processor”) </b> to bill you through your selected mode of payment. The processing of
         payments will be subject to the terms and policies of such Payment Processor in
         addition to these Terms. We shall not be liable for any error of the Payment Processor.
         In the event of any unsuccessful payment, the money debited shall be credited in
         accordance with the terms of the Payment Processor.</p></section>


     <section class="pera-1" >
        <b class="bolde">(d) </b> <p class="para">
         <span style="text-decoration: underline; font-size: 19px;"><u>Cancellation: </u> </span>
         You may elect to cancel your request for services from a Service
         Professional at any time prior to such Service Professional’s arrival, in which case you
         may be charged a cancellation fee in accordance with AJ’s cancellation policy. AJ
         reserves the right to charge you, or otherwise deduct applicable taxes in respect of
         such cancellation fee.</p></section>


     <section class="pera-1" >
        <b class="bolde">(e) </b> <p class="para">
         <span style="text-decoration: underline; font-size: 19px;"><u>Subscriptions: </u> </span>
         AJ may from time to time offer subscription packages (howsoever
         named) for monetary consideration. The packages shall provide Customers with
         additional benefits, which may include the ability to avail discounted Pro Services. You
         agree that subscription packages (howsoever named) shall be subject to additionalterms and conditions. 
         You acknowledge that such subscription packages will be
         subject to additional terms and conditions that will be deemed to be an integral part
         of these Terms.</p></section>



     <section class="pera-1" >
        <b class="bolde">(f) </b> <p class="para">
         <span style="text-decoration: underline; font-size: 19px;"></span>
         AJ does not designate any portion of your payment as a tip or gratuity to the Service
         Professional. Any representation by AJ to the effect that tipping is “voluntary”, “not
         required”, and/or “included” in the payments you make for Pro Services is not
         intended to suggest that AJ provides any additional payments to Service Professionals.
         You understand and agree that while you are free to provide additional payment as a
         gratuity to any Service Professional who provides you with Pro Services, you are under
         no obligation to do so. Gratuities are voluntary.</p></section>


<h3 class="heading-1">7. CUSTOMER CONDUCT</h3>
      



<section class="pera-1" >
    <b class="bolde">(a) </b> <p class="para">
     <span style="text-decoration: underline; font-size: 19px;"></span>
     AJ does not designate any portion of your payment as a tip or gratuity to the Service
     Professional. Any representation by AJ to the effect that tipping is “voluntary”, “not
     required”, and/or “included” in the payments you make for Pro Services is not
     intended to suggest that AJ provides any additional payments to Service Professionals.
     You understand and agree that while you are free to provide additional payment as a
     gratuity to any Service Professional who provides you with Pro Services, you are under
     no obligation to do so. Gratuities are voluntary.</p></section>



     <section class="pera-1" >
        <b class="bolde">(b) </b> <p class="para">
         <span style="text-decoration: underline; font-size: 19px;"></span>
         We request that you treat all Service Professionals with courtesy and respect, and that
you provide them with a safe, clean, and appropriate location to perform the Pro
Services. Service Professionals shall be entitled to refuse to perform Pro Services if you
have not provided a safe, clean, and appropriate location for them, or you behave
towards them in a manner which is discourteous, disrespectful, abusive, or otherwise
inappropriate. We reserve the right to withhold access to the Services and otherwise
limit your access to Pro Services at our absolute discretion if you behave towards any
Service Professional in a manner which is discourteous, disrespectful, or abusive, or
which we otherwise deem to be inappropriate or unlawful.</p></section>



     <section class="pera-1" >
        <b class="bolde">(c) </b> <p class="para">
         <span style=" font-size: 19px;">You agree that you will be liable for discriminating against Service Professionals or
            for any failure, intentional or otherwise, to provide the Service Professionals a safe,
            clean, and appropriate location for them to perform the Pro Services. Additionally, you
            will also disclose any and all information that may have a bearing on the ability of the
            Service Professional to perform the Pro Services or impact the Services Professional’s
            health, safety, or well-being, to AJ and the Service Professional.</span>
        </p></section>



     <section class="pera-1" >
        <b class="bolde">(d) </b> <p class="para">
         <span style="text-decoration: underline; font-size: 19px;"></span>
         You agree that in the event a Service Professional behaves in a manner that is
         discourteous, disrespectful, abusive, inappropriate, or in violation of the law, you shall
         be required to report such incident to grievanceofficer@acejobber.com at the earliest
         but in any event within 48 (forty eight) hours of such incident.</p></section>






<h3 class="heading-1">8. THIRD PARTY SERVICES</h3>



<section class="pera-1" >
    <b class="bolde">(a) </b> <p class="para">
     <span style="text-decoration: underline; font-size: 19px;"></span>
     The Platform may include services, content, documents, and information owned by,
licensed to, or otherwise made available by, a third party (“Third Party Services”) and
contain links to Third Party Services. You understand and acknowledge that Third Party
Services are the sole responsibility of the third party that created or provided it and
that use of such Third-Party Services is solely at your own risk.</p></section>


     <section class="pera-1" >
        <b class="bolde">(b) </b> <p class="para">
         <span style="text-decoration: underline; font-size: 19px;"></span>
         We make no representations and exclude all warranties and liabilities arising out of
         or pertaining to such Third-Party Services, including their accuracy or completeness.
         Should you avail a Third-Party Service, you shall be governed and bound by the terms
         and conditions and privacy policy of the third parties providing the Third-Party
         Services. Further, all intellectual property rights in and to Third Party Services are the
         property of the respective third parties.</p></section>






<h3 class="heading-1">9. YOUR RESPONSIBILITIES</h3>



<section class="pera-1" >
    <b class="bolde">(a) </b> <p class="para">
     <span style="text-decoration: underline; font-size: 19px;"></span>
     You represent and warrant that all information that you provide in relation to the
     Services and Pro Services is complete, true, and correct on the date of agreeing to
     these Terms and shall continue to be complete, true, and correct while you avail the
     Services and/or the Pro Services. Should any information that you provide change
     during the existence of these Terms, you undertake to immediately bring such change
     to our notice. We do not accept any responsibility or liability for any loss or damage
     that you may suffer or incur if any information, documentation, material, or data,
     provided to avail the Services is incorrect, incomplete, inaccurate, or misleading or if
     you fail to disclose any material fact.</p></section>



     <section class="pera-1" >
        <b class="bolde">(b) </b> <p class="para">
         <span style="text-decoration: underline; font-size: 19px;"></span>
         You shall extend all cooperation to us in our defence of any proceedings that may be
initiated against us due to a breach of your obligations or covenants under these
Terms.</p></section>

<div style="display: flex;margin-bottom: 30px;">
    <b style="padding: 0 20px; font-size: 19;font-weight: 500;">(c) </b> 
 <span style="text-decoration: underline; font-size: 19px;">In respect of the User Content, you represent and warrant that:</span></div>
 <ul class="" style="text-align: justify;padding-left: 75px;padding-top: 10px; list-style: lower-roman; font-size: 19px;" >

    <li style="padding-top: 7px;">you own all intellectual property rights (or have obtained all necessary
        permissions) to provide User Content and to grant the licences under these
        Terms;</li>
        <li style="padding-top: 7px;">you are solely responsible for all activities that occur on or through your account
            on the Platform and all User Content;</li>
        <li>the User Content does not and shall not violate any of your obligations or
            responsibilities under other agreements;</li>
        <li style="padding-top: 7px;">the User Content does not and shall not violate, infringe, or misappropriate any
            intellectual property right or other proprietary right including the right of
            publicity or privacy of any person or entity;</li>
        <li style="padding-top: 7px;">the User Content does not and shall not contain any viruses, corrupted data, or
            other harmful, disruptive, or destructive files or content;</li>
        <li style="padding-top: 7px;">the User Content does not and shall not violate any third-party rights; and</li>
        <li style="padding-top: 7px;">the User Content (A) does not belong to any other person to which you do not
            have any right, (B) does not threaten the unity, integrity, defence, security or
            sovereignty of India, friendly relations with foreign states, public order, cause
            incitement to the commission of any cognisable offence, prevents investigation
            of any offence, or is insulting another nation, (C) is not defamatory, grossly
            harmful, blasphemous, paedophilic, invasive of another’s privacy, discriminatory
            based on gender, ethnically objectionable, disparaging, relating to, or
            encouraging money laundering or gambling, libellous, hateful, racist, violent,
            obscene, pornographic, unlawful, harmful to children, or (D) otherwise offensive,
            objectionable, or restricts, or inhibits, any other person from using or enjoying
            the Services.</li>
</ul>



<section class="pera-1" >
    <b class="bolde">(d) </b> <p class="para">
     <span style="text-decoration: underline; font-size: 19px;"></span>
     You shall not use the Services in any manner except as expressly permitted in these
Terms. Without limiting the generality of the preceding sentence, you shall not:</p></section>

  <ul style="text-align: justify;padding-left: 75px;padding-top: 10px; list-style: lower-roman; font-size: 19px;">
    <li style="padding-top: 7px;">infringe any proprietary rights, including but not limited to copyrights, patents,
        trademarks, or trade secrets of any party;</li>
    <li style="padding-top: 7px;">except as may be provided hereunder, copy, display, distribute, modify, publish,
        reproduce, store, transmit, post, translate, create any derivative works from or
        license the Services;</li>
    <li style="padding-top: 7px;">use the Services to transmit any data, or send or upload any material that
        contains viruses, Trojan horses, worms, timebombs, keystroke loggers, spyware,
        adware, or any other harmful programmes, or similar computer code, designed
        to adversely affect the operation of any computer software or hardware;</li>
     <li style="padding-top: 7px;">use any robot, spider, other automated device, or manual process to monitor
        or copy the Services or any portion thereof;</li>
     <li style="padding-top: 7px;">engage in the systematic retrieval of content from the Services to create or
        compile, directly or indirectly, a collection, compilation, database, or directory;</li>
     <li style="padding-top: 7px;">use the Services in (A) any unlawful manner, (B) for fraudulent or malicious
        activities or (C) in any manner inconsistent with these Terms;</li>
      <li style="padding-top: 7px;">decompile, reverse engineer, or disassemble the Services;</li>
      <li style="padding-top: 7px;">link to, mirror, or frame, any portion of all or any of the Services; or</li>
      <li style="padding-top: 7px;">violate applicable laws in any manner.</li>
  </ul>

  <section class="pera-1" >
    <b class="bolde">(e) </b> <p class="para">
     <span style="text-decoration: underline; font-size: 19px;"></span>
     You warrant that you shall not engage in any activity that interferes with or disrupts
the Services.</p></section>

<section class="pera-1" >
    <b class="bolde">(f) </b> <p class="para">
     <span style="text-decoration: underline; font-size: 19px;"></span>
     You shall not attempt to gain unauthorised access to any portion or feature of the
Services, any other systems or networks connected to the Services, to any of our
servers, or through the Platform by hacking, password mining, or any other illegitimate
means.</p></section>


<section class="pera-1" >
    <b class="bolde">(g) </b> <p class="para">
     <span style="text-decoration: underline; font-size: 19px;"></span>
     You shall not directly or indirectly, in any capacity, solicit, attempt to influence,
     engage, approach, or accept or encourage the solicitations or approach of, a Service
     Professional from whom you have availed Pro Services, to either terminate or
     otherwise cease their registration on or engagement with the Platform, or avail
     services the same as or similar to the Pro Services independently, without booking the
     Pro Services through your Account. You agree that this limitation is reasonable and
     fair and is necessary for the protection of the privacy and security of Service
     Professionals and that this will not preclude you from obtaining services the same as
     or similar to the Pro Services through the Platform or other means. You further agree
     that any potential harm to Service Professionals from the non-enforcement of this
     clause far outweighs any potential harm to you.</p></section>





<h3 class="heading-1">10. OUR INTELLECTUAL PROPERTY</h3>

<section class="pera-1" >
    <b class="bolde">(a) </b> <p class="para">
     <span style="text-decoration: underline; font-size: 19px;"></span>
     All rights, titles, and interest in, and to the Services, including all intellectual property
rights arising out of the Services, are owned by or otherwise licensed to us. Subject to
compliance with these Terms, we grant you a non-exclusive, non-transferable, non-
sub licensable, revocable, and limited licence to use the Services in accordance with
these Terms and our written instructions issued from time to time. Any rights not
expressly granted herein are reserved by AJ or AJ’s licensors.</p></section>

<section class="pera-1" >
    <b class="bolde">(b) </b> <p class="para">
     <span style="text-decoration: underline; font-size: 19px;"></span>
     We may request you to submit suggestions and other feedback, including bug
reports, relating to the Services from time to time (“Feedback”). We may freely use,
copy, disclose, publish, display, distribute, and exploit the Feedback we receive from
you without any payment of royalty, acknowledgement, prior consent, or any other
form of restriction arising out of your intellectual property rights.</p></section>



<section class="pera-1" >
    <b class="bolde">(c) </b> <p class="para">
     <span style="text-decoration: underline; font-size: 19px;"></span>
     Except as expressly stated in these Terms, nothing in these Terms should be construed
     as conferring any right in, or licence to, our or any third party’s intellectual property
     rights.</p></section>


     <h2 class="heading-1">11. TERM AND TERMINATION</h2>


<section class="pera-1" >
    <b class="bolde">(a) </b> <p class="para">
     <span style="text-decoration: underline; font-size: 19px;"></span>
     These Terms shall remain in effect unless terminated in accordance with the terms
     hereunder.</p></section>


<section class="pera-1" >
    <b class="bolde">(b) </b> <p class="para">
     <span style="text-decoration: underline; font-size: 19px;"></span>
     We may restrict, deactivate, or terminate your access to, or use of, the Services, or any
portion thereof, (i) immediately and at any point at our sole discretion, (A) if you
violate or breach any of the obligations, responsibilities, or covenants under theseTerms,
 (B) when you cease to become a user of our Services, (C) you do not, or are
likely not to qualify under applicable law, or the standards and policies of AJ or its
affiliates, to access and use the Services, or (D) violate or breach the Community
Guidelines, (ii) upon 30 (Thirty) days’ prior written notice to you, or (iii) immediately
for any legitimate business, legal, or regulatory reason.</p></section>


<section class="pera-1" >
    <b class="bolde">(c) </b> <p class="para">
     <span style="text-decoration: underline; font-size: 19px;"></span>
     You may terminate these Terms, at any time, for any reason by sending a notice to
     AJ at privacy@acejobber.com</p></section>


     <section class="pera-1" >
        <b class="bolde">(d) </b> <p class="para">
         <span style="text-decoration: underline; font-size: 19px;"></span>
         Upon termination of these Terms:</p></section>
           
               <ul style="text-align: justify;padding-left: 75px;padding-top: 10px; list-style: lower-roman; font-size: 19px;">
                <li style="padding-left: 5px;">the Account will expire;</li>
                <li style="padding-left: 5px;">the Services will “time-out”; and</li>
                <li style="padding-left: 5px;">these Terms shall terminate, except for those clauses that are expressly, or by
                    implication, intended to survive termination or expiry.</li>
               </ul>



    <h2 class="heading-1">12. DISCLAIMERS AND WARRANTIES</h2>

           
<section class="pera-1" >
    <b class="bolde">(a) </b> <p class="para">
     <span style="text-decoration: underline; font-size: 19px;"></span>
     The Services are provided on an “as is” basis without warranty of any kind, express,
     implied, statutory or otherwise, including without limitation the implied warranties of
     title, non-infringement, merchantability, or fitness for a particular purpose. Without
     limiting the foregoing, we make no warranty that the Services will meet your
     requirements or expectations.</p></section>



<section class="pera-1" >
    <b class="bolde">(b) </b> <p class="para">
     <span style="text-decoration: underline; font-size: 19px;"></span>
     No advice or information, whether oral or written, obtained by you from us shall
create any warranty that is not expressly stated in the Terms.</p></section>




<section class="pera-1" >
    <b class="bolde">(c) </b> <p class="para">
     <span style="text-decoration: underline; font-size: 19px;"></span>
     While AJ strives to provide accurate information about Pro Services and Charges,
pricing errors may occur from time to time.</p></section>


<section class="pera-1" >
    <b class="bolde">(d) </b> <p class="para">
     <span style="text-decoration: underline; font-size: 19px;"></span>
     You agree and acknowledge that we are merely a Platform that connects you with
     Service Professionals, and we shall not be liable in any manner for any obligations that
     have not been explicitly stated in these Terms. We are not liable or responsible for
     fulfilment of any bookings, for the performance of the Pro Services by any Service
     Professional, or for any acts or omissions of the Service Professionals during their
     provision of the Pro Services including any damage they may cause to property. By
     booking Pro Services through the Platform, you are entering into a contract with the
     relevant Service Provider for the provision of those services, and we accept no
     responsibility or liability, nor do we make any warranty, representation, or guarantee
     in respect of the Service Professional’s performance under that contract.</p></section>


<section class="pera-1" >
    <b class="bolde">(e) </b> <p class="para">
     <span style="text-decoration: underline; font-size: 19px;"></span>
     You agree and acknowledge that soliciting or receiving services from any Service
     Professional independently is solely at your own risk, and in such an event, you waive
     any rights that you may have under these Terms.</p></section>


<section class="pera-1" >
    <b class="bolde">(f) </b> <p class="para">
     <span style="text-decoration: underline; font-size: 19px;"></span>
     We do not guarantee or warrant and we make no representation whatsoever
     regarding the reliability, quality, or suitability of the Service Professionals.</p></section>


<section class="pera-1" >
    <b class="bolde">(g) </b> <p class="para">
     <span style="text-decoration: underline; font-size: 19px;"></span>
     You hereby accept full responsibility for any consequences that may arise from your
     use of the Services and Pro Services, and expressly agree and acknowledge that we
     shall have absolutely no liability in this regard.</p></section>


<section class="pera-1" >
    <b class="bolde">(h) </b> <p class="para">
     <span style="text-decoration: underline; font-size: 19px;"></span>
     AJ will maintain a complaints management framework and will manage this
framework on behalf of Service Professionals in a reasonable manner and in
accordance with the non-excludable requirements of relevant applicable laws.</p></section>


<!-- 
<section class="pera-1" >
    <b style="padding: 0 20px;">(i) </b> <p class="para">
     <span style="text-decoration: underline; font-size: 19px;"></span>
     To the fullest extent permissible by law, we, our affiliates, and our related parties, each
     disclaim all liability for any loss or damage arising out of, or due to:.</p></section> -->

     <div style="display: flex;margin-bottom: 30px;">
        <b style="padding: 0 20px; font-size: 19;">(i) </b> 
     <span style=" font-size: 19px;">To the fullest extent permissible by law, we, our affiliates, and our related parties, each
        disclaim all liability for any loss or damage arising out of, or due to:</span></div>

       <ul style="text-align: justify;padding-left: 75px;padding-top: 10px; list-style: lower-roman; font-size: 19px;">
        <li style="padding-top: 10px;">your use of, inability to use, or availability or unavailability of the Services or the
            Pro Services;</li>
        <li style="padding-top: 10px;">the occurrence or existence of any defect, interruption, or delays, in the
            operation or transmission of information to, from, or through the Services,
            communications failure, theft, destruction, or unauthorised access to our
            records, programmes, services, servers, or other infrastructure relating to the
            Services;</li>
        <li style="padding-top: 10px;">the failure of the Services to remain operational for any period of time; and</li>
        <li style="padding-top: 10px;">the loss of any User Content and any other data in connection with your use of
            the Services.</li>
       </ul>

   
<section class="pera-1" >
    <b class="bolde">(j) </b> <p class="para">
     <span style="text-decoration: underline; font-size: 19px;"></span>
     In no event shall AJ, its officers, directors, and employees, or its contractors, agents,
licensors, partners, or suppliers, be liable to you for any direct, special, indirect,
incidental, consequential, punitive, reliance, or exemplary damages (including without
limitation, lost business opportunities, lost revenues, or loss of anticipated profits or
any other pecuniary or non-pecuniary loss or damage of any nature whatsoever,
including but not limited to any abuse or breach of data), even if AJ or an authorised
representative had been advised of the possibility of such damages, arising out of, or
relating to (A) these Terms, (B) the Services or the Pro Services, (C) your use or inability
to use the Services or the Pro Services, or (D) any other interactions with another user
of the Services.</p></section>



<section class="pera-1" >
    <b class="bolde">(k) </b> <p class="para">
     <span style="text-decoration: underline; font-size: 19px;"></span>
     To the maximum extent permitted by law, our liability shall be limited to the amount
     of commission we receive in respect of a particular booking made on the Platform. In
     no event shall our total liability to you in connection with these Terms exceed INR
     10,000 (Rupees Ten Thousand).</p></section>


<section class="pera-1" >
    <b class="bolde">(l) </b> <p class="para">
     <span style="text-decoration: underline; font-size: 19px;"></span>
     Nothing in these Terms will exclude or limit any warranty implied by law that it would
     be unlawful to exclude or limit.</p></section>



     <h2 class="heading-1">13. INDEMNITY</h2>

     <section class="pera-1" style="padding-top: 15px; margin-left: 10px;">You shall indemnify, defend at our option, and hold us, our parent companies, subsidiaries,
        affiliates, and our officers, employees, directors, agents, and representatives, harmless from
        and against any claim, demand, lawsuits, judicial proceeding, losses, liabilities, damages, and
        costs (including, without limitation, all damages, liabilities, settlements, and attorneys’ fees),
        due to or arising out of your access to the Services or Pro Services, use of the Services or
        Pro Services, violation of these Terms, or any violation of these Terms by any third party who
        may use your Account.</section>

  <h2 class="heading-1" style="padding-top: 10px;">14. JURISDICTION, GOVERNING LAWS, AND DISPUTE RESOLUTION</h2>

    

  <section class="pera-1" >
    <b class="bolde">(a) </b> <p class="para">
     <span style="text-decoration: underline; font-size: 19px;"></span>
     These Terms shall be governed by and construed and enforced in accordance with
the laws of India. Subject to other provisions in this clause, courts in New Delhi shall
have exclusive jurisdiction over all issues arising out of these Terms or the use of the
Services.</p></section>


<section class="pera-1" >
    <b class="bolde">(b) </b> <p class="para">
     <span style="text-decoration: underline; font-size: 19px;"></span>
     Any controversies, conflicts, disputes, or differences, arising out of these Terms shall
     be resolved by arbitration in New Delhi in accordance with the Arbitration and
     Conciliation Act, 1996 for the time being in force, which is deemed to be incorporated
     by reference in this clause. The tribunal shall consist of 1 (One) arbitrator appointed
     by AJ. The language of the arbitration shall be English. The parties to the arbitration
     shall keep the arbitration confidential, and not disclose to any person, other than on
     a need-to-know basis, or to legal advisors, unless required to do so by law. The
     decision of the arbitrator shall be final and binding on all the parties thereto. Each
     party to the arbitration shall bear its own costs with respect to any dispute.</p></section>

<h2 class="heading-1">15. GRIEVANCE REDRESSAL</h2>


<section class="pera-1" >
    <b class="bolde">(a) </b> <p class="para">
     <span style="text-decoration: underline; font-size: 19px;"></span>
     You may contact our designated Grievance Redressal Officer with any complaints or
queries relating to the Services or these Terms through registered post or through
email, details of which are provided below:<br>
Name:
<br>
Designation:
<br>
Email Address: nikhilshanker@acejobber.com</p></section>


<section class="pera-1" >
    <b class="bolde">(a) </b> <p class="para">
     <span style="text-decoration: underline; font-size: 19px;"></span>
     We shall ensure that your complaint is resolved within timelines prescribed by
applicable laws.</p></section>


<h2 class="heading-1">16. MISCELLANEOUS PROVISIONS</h2>

<!-- 
<section class="pera-1" >
    <b style="padding: 0 20px;">(a) </b><u>Changes to Terms: </u> <p class="para">
     <span style="text-decoration: underline; font-size: 19px;"></span>
     The Terms are subject to revisions at any time, as determined by
     us, and all changes are effective immediately upon being posted on the Platform. It isyour responsibility to review these Terms periodically for any updates or changes. You
     will be deemed to have accepted the changes made to these Terms if you continue to
     use the Platform once it has been posted.</p></section> -->




     <section class="pera-1" >
        <b class="bolde">(a) </b> <p class="para">
         <span style="text-decoration: underline; font-size: 19px;"><u>Changes to Terms: </u> </span>
         The Terms are subject to revisions at any time, as determined by
us, and all changes are effective immediately upon being posted on the Platform.
 It isyour responsibility to review these Terms periodically for any updates or changes. You
will be deemed to have accepted the changes made to these Terms if you continue to
use the Platform once it has been posted.</p></section>


<section class="pera-1" >
    <b class="bolde">(b) </b> <p class="para">
     <span style="text-decoration: underline; font-size: 19px;"><u>Modification to the Services: </u> </span>
     We reserve the right at any time to add, modify, or
     discontinue, temporarily or permanently, the Services (or any part thereof), with or
     without cause. We shall not be liable for any such addition, modification, suspension,
     or discontinuation of the Services.</p></section>


<section class="pera-1" >
    <b class="bolde">(c) </b> <p class="para">
     <span style="text-decoration: underline; font-size: 19px;"><u>Severability: </u> </span>
     If any provision of these Terms is determined by any court or other
competent authority to be unlawful or unenforceable, the other provisions of these
Terms will continue to be in effect. If any unlawful or unenforceable provision would
be lawful or enforceable if a part of it were deleted, that part will be deemed to be
deleted, and the rest of the provision will continue in effect (unless that would
contradict the clear intention of the clause, in which case the entirety of the relevant
provision will be deemed to be deleted).</p></section>


<section class="pera-1" >
    <b class="bolde">(d) </b> <p class="para">
     <span style="text-decoration: underline; font-size: 19px;"><u>Assignment: </u> </span>
     You shall not license, sell, transfer, or assign your rights, obligations, or
     covenants under these Terms, or your Account in any manner without our prior written
     consent. We may grant or withhold this consent at our sole discretion, subject to any
     conditions we deem appropriate. We may assign our rights to any of our affiliates,
     subsidiaries, or parent companies, any successor in interest of any business associated
     with the Services, or any third party without any prior notice to you.</p></section>


<section class="pera-1" >
    <b class="bolde">(e) </b> <p class="para">
     <span style="text-decoration: underline; font-size: 19px;"><u>Notices: </u> </span>
     All notices, requests, demands, and determinations for us under these Terms
     (other than routine operational communications) shall be sent to
     legal@acejobber.com.</p></section>


<section class="pera-1" >
    <b class="bolde">(f) </b> <p class="para">
     <span style="text-decoration: underline; font-size: 19px;"><u>Third Party Rights: </u> </span>
     The Terms are subject to revisions at any time, as determined by
us, and all changes are effective immediately upon being posted on the Platform.
It isyour responsibility to review these Terms periodically for any updates or changes. You
will be deemed to have accepted the changes made to these Terms if you continue to
use the Platform once it has been posted.</p></section>


<section class="pera-1" style="display: flex;padding-top: 8px; padding-bottom: 15px;">
    <b style="padding: 0 20px; font-weight: 500;">(g) </b> <p class="para">
     <span style="text-decoration: underline; font-size: 19px;"><u>Force Majeure: </u> </span>
     We shall have no liability to you if we are prevented from or delayed
     in performing our obligations, or from carrying on our business, by acts, events,
     omissions, or accidents beyond our reasonable control, including without limitation,
     strikes, failure of a utility service or telecommunications network, act of God, war, riot,
     civil commotion, malicious damage, or compliance with any law or governmental
     order, rule, regulation, or direction.</p></section>



     </div>
   </div> 
</div>    









