import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from '../jobposter-module/service.service';
import { MainServiceService } from '../Services/main-service.service';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
  
  
})

export class NavbarComponent {
  isNavOpen = false;
  isDropdownVisible = false;



  toggleDropdown() {
   
      this.isDropdownVisible = !this.isDropdownVisible;
    
  }
 

  openNav() {
    this.isNavOpen = true;
    document.getElementById("mySidenav").style.width = "100%";
  }
 closeNav() {
  this.isNavOpen = false;
  this.isDropdownVisible = false;
    document.getElementById("mySidenav").style.width = "0";
  }
  switcherClassApplied = false;
  switcherToggleClass() {
      this.switcherClassApplied = !this.switcherClassApplied;
  }

  searchClassApplied = false;
  searchToggleClass() {
      this.searchClassApplied = !this.searchClassApplied;
  }

  classApplied = false;
  toggleClass() {
      this.classApplied = !this.classApplied;
      this.isNavOpen = !this.isNavOpen;
  }

  
showMe:boolean=false
toggleTag(){
  this.showMe=!this.showMe
}
@ViewChild('userForm') userForm : NgForm | undefined;

registerForm!:FormGroup
submitted=false

loginjobber:boolean=false
showModal: boolean = false;

selectedCategory:any
categoryData:any
categoryId:any
categoryName:any

selectedLocation:any
uniqueCities:any

searchObj={
  category:'',
  city:''

  }
  constructor( private _formBuilder: FormBuilder,  private mainServiceService:MainServiceService ,private router : Router, private serviceService:ServiceService){}
  
  categoryForm = this._formBuilder.group({
    category: new FormControl(null , [Validators.required]),
    service: new FormControl(null,  [Validators.required])
  })
  sendToPostJob(){
    this.mainServiceService.sendingSearchingData(this.categoryName)
    this.router.navigate(['acejobber/post-job'])
  }
  
  navigate(value){
    sessionStorage.clear()
    if(value == 'postJob'){
      sessionStorage['loginId'] = null
      sessionStorage['token'] = null
        this.router.navigate(['acejobber/post-job',]);
    }
    else if(value=='findjob'){
    this.loginjobber = !this.loginjobber;
    }
    //Bhabani 24/01/2024
    else if (value == 'otp-auth'){
      this.router.navigate(['/otp-auth',]);
    }
    else if(value == 'admin'){
      this.router.navigate(['/admin',]);
    }
    else {
       this.router.navigate(['/acejobber/'+value+'/login-page',]);
    }
   
  //  written by Gowthami
  this.getCatogory();
    // this.serviceService.getCategory().subscribe((data:any)=>{
    //   this.categoryData=data
    //   this.categoryData.sort();
    //   this.categoryId=this.categoryData.map((item:any)=>item.id)
    //   this.categoryName=this.categoryData.map((item:any)=>item.name)
    //  })
    //  this.mainServiceService.getAllUniqueCities().subscribe((data:any)=>{
    //   this.uniqueCities=data
    //   this.uniqueCities.sort();
    //   })
 

 }
 getCatogory() {
  // this.serviceService.getCategory().subscribe((data:any)=>{
  //   this.categoryData=data
  //   this.categoryData.sort();
  //   this.categoryId=this.categoryData.map((item:any)=>item.id)
  //   this.categoryName=this.categoryData.map((item:any)=>item.name)
  //  })

  this.serviceService.getCategory().subscribe(data => {
    this.categoryData = data
    //this.responseService.setCategoryList(data)
  })
}
 selectedcategory(){}
 selectedlocation(){}


 search(){
  this.searchObj.category=this.selectedCategory
  this.searchObj.city=this.selectedLocation
  this.mainServiceService.sendingSearchingData(this.searchObj)
  this.submitted=true
  if(this.registerForm.invalid){
    return
  }
    this.router.navigate(['acejobber/jobber/category-page']);

 }


ngOnInit(){
  this.registerForm=this._formBuilder.group({
    categoryname:['',Validators.required],
    servicename:['',Validators.required]
  })

  this.getCatogory();
}

setSelectedCategory(category){
  this.mainServiceService.sendingSearchingData(category)
  this.router.navigate(['acejobber/post-job'])
}


}
