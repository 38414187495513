<div class="container">
  <div class="row">
    <div class="col-12">
      <h2 class="text-center">Fees and Charges</h2>
      <h3>For Jobbers</h3>
      <p>
        Acejobber is free to sign up, create a profile, contacting customer and
        discuss the job requirements. If you provide the services to the
        customer, the fee of 10% of the total services cost is charged.
      </p>
      <p>
        You may cancel the job from the app at any time 2 hours prior to the
        starting of the job.
      </p>
      <div class="col-12 d-flex justify-content-center">
        <table class="rwd-table">
          <tr>
            <th>Service Cost</th>
            <th>Fees</th>
          </tr>
          <tr>
            <td>&lt;1000 rs</td>
            <td>5%</td>
          </tr>
          <tr>
            <td>1000 to 5000 rs</td>
            <td>10%</td>
          </tr>
          <tr>
            <td>&gt;5000 rs</td>
            <td>15%</td>
          </tr>
        </table>
      </div>
      <h3>For Customers</h3>
      <p>
        Acejobber is free to sign up, create a profile, post a job, choose the
        jobber, Hire the jobber. If you avail the services and get your job
        done, the fee of 5% of the total services cost is charged.
      </p>
      <h2>Membership Plans</h2>
    </div>
  </div>
</div>
