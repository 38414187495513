<div class="navbar-area" stickyClass="sticky-box-shadow" ngStickyNav>
  <div class="container">
    <nav
      class="navbar navbar-expand-xl navbar-light bg-light"
      [class.active]="classApplied"
    >
      <div class="others-option d-flex align-items-center">
        <div class="option-item">
          <div
            (click)="searchToggleClass()"
            style="font-size: 18px; font-weight: 600"
          >
            <img
              src="assets/figma/newlogo.webp"
              width="40px"
              height="40px"
            />&nbsp;<span style="color: #16264a; border-bottom: none"
              >ACEJOBBER</span
            >
          </div>
        </div>
        <div class="option-item">
          <a routerLink="/" style="font-size: 18px; font-weight: bold"> </a>
        </div>
      </div>
      <!-- reponsive start -->
      <button class="navbar-toggler" type="button" (click)="toggleClass()">
        <div id="mySidenav" class="sidenav">
          <a class="closebtn" (click)="closeNav()">&times;</a>

          <li class="nav-item" style="list-style: none">
            <a class="nav-link" routerLink="/" (click)="closeNav()">
              Home
              <hr
                style="
                  height: 2px;
                  border-width: 0;
                  color: #ffff;
                  background-color: #ffff;
                "
              />
            </a>
          </li>
          <li class="nav-item dropdown" style="cursor: pointer">
            <a
              class="nav-link dropdown-toggle"
              data-toggle="dropdown"
              (click)="toggleDropdown()"
            >
              Categories
            </a>
            <ul class="dropdown" [hidden]="!isDropdownVisible">
              <li
                class="nav-item"
                *ngFor="let item of categoryData"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <a
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  style="font-size: 16px"
                  (click)="closeNav()"
                >
                  {{ item.name }}
                </a>
              </li>
            </ul>
            <hr
              style="
                height: 2px;
                border-width: 0;
                color: #ffff;
                background-color: #ffff;
              "
            />
          </li>

          <li class="nav-item">
            <a class="nav-link" routerLink="/working" (click)="closeNav()">
              How It Works
              <hr
                style="
                  height: 2px;
                  border-width: 0;
                  color: #ffff;
                  background-color: #ffff;
                "
              />
            </a>
          </li>

          <li class="nav-item">
            <a class="nav-link">
              <button
                class="btns btn-outline-primary me-2 btns"
                style="border-radius: 50px"
                type="submit"
                (click)="navigate('postJob')"
                (click)="closeNav()"
              >
                Post A Job
              </button>
              <hr
                style="
                  height: 2px;
                  border-width: 0;
                  color: #ffff;
                  background-color: #ffff;
                "
              />
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link">
              <button
                class="btns btn-outline-info me-2 btns"
                style="border-radius: 50px"
                type="submit"
                routerLink="/landing-2"
                (click)="closeNav()"
              >
                Become a Jobber
              </button>
              <hr
                style="
                  height: 2px;
                  border-width: 0;
                  color: #ffff;
                  background-color: #ffff;
                "
              />
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link">
              <a
                class="btn-outline-primary me-2"
                style="border-radius: 50px"
                type="submit"
                (click)="navigate('login')"
                (click)="closeNav()"
                >Login</a
              >
              <hr
                style="
                  height: 2px;
                  border-width: 0;
                  color: #ffff;
                  background-color: #ffff;
                "
              />
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link">
              <a
                class="btn-outline-primary me-2 nav-link"
                style="border-radius: 50px"
                type="submit"
                (click)="navigate('signup')"
                (click)="closeNav()"
                >Sign Up</a
              >
              <hr
                style="
                  height: 2px;
                  border-width: 0;
                  color: #ffff;
                  background-color: #ffff;
                "
              />
            </a>
          </li>
        </div>
        <span
          style="font-size: 30px; cursor: pointer; border-bottom: none"
          (click)="openNav()"
          >&#9776;</span
        >
      </button>
      <!-- reponsive  end-->
      <div class="collapse navbar-collapse" style="margin-left: 40px">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a
              class="nav-link"
              routerLink="/"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              Home
            </a>
          </li>
          <li class="nav-item" style="cursor: pointer">
            <a
              class="nav-link"
              (mouseenter)="getCatogory()"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              Categories
            </a>
            <ul class="dropdown-menus">
              <li
                class="nav-item"
                *ngFor="let item of categoryData"
                style="display: flex"
              >
                <a
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                >
                  {{ item.name }}</a
                >
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              routerLink="/working"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              How It Works
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link">
              <button
                class="btn"
                style="border-radius: 50px"
                type="submit"
                (click)="navigate('postJob')"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                Post A Job
              </button>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link">
              <button
                class="btn"
                style="border-radius: 50px"
                type="submit"
                routerLink="/landing-2"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                Become a Jobber
              </button>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link">
              <p
                class="btn-outline-primary me-2 me-3"
                style="border-radius: 50px"
                type="submit"
                (click)="navigate('login')"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                Login &nbsp;&nbsp;&nbsp;/
              </p>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link">
              <p
                class="btn-outline-primary me-2 me-4"
                style="border-radius: 50px"
                type="submit"
                (click)="navigate('signup')"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                Sign Up
              </p>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</div>
