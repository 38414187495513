<div class="container">
<div class="row">
    <div class="col-12">

        <h1 style="text-align: center; font-size: 23px; font-weight: bold; padding-top: 20px;">REFUND POLICY</h1>



    </div>
</div>     
</div>
