import { Injectable } from '@angular/core';
import * as SockJS from 'sockjs-client';
import * as Stomp from 'stompjs';
import { AppConstants } from './../appConstants';
import { MainServiceService } from './main-service.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WebsocketStompService {

  stompClient: any;
  loginId: string | null = null;
  email: string | null = null;
  userTypeId: string | null = null;

  private connection = new Subject<any>();
  castConnection = this.connection.asObservable();
  
  updateConnection(data: any) {
    this.connection.next(data);
  }
  
  constructor(private mainServiceService: MainServiceService) {
    console.log("websocket cons");
  }

  sendConnectionOpen(userId: string) {
    if (this.stompClient) {
      this.stompClient.send("/app/connection-open", {}, userId);
    }
  }

  connect() {
    console.log("Initialize WebSocket Connection");
    let sock = new SockJS(AppConstants.websocketHost + '/ws');
    this.stompClient = Stomp.over(sock);
    const _this = this;
    _this.loginId = sessionStorage.getItem('loginId');
    _this.email = sessionStorage.getItem('email');
    _this.userTypeId = sessionStorage.getItem('userTypeId');

    var headers = {
      'userId': this.loginId,
      'email': this.email,
      'userTypeId': this.userTypeId
    };

    _this.stompClient.connect(headers, function (frame: any) {
      console.log("Client Connected...");
      console.log("WebSocket connection opened...");
      _this.sendConnectionOpen(_this.loginId!);
      setInterval(() => {
        if (_this.loginId != null) {
          _this.stompClient.send("/app/heartbeat", {}, _this.loginId!);
        }
      }, 20000);
      if(_this.stompClient.ws.readyState == 1) {
        _this.updateConnection(_this.stompClient)
      }
      _this.stompClient.subscribe('/user/' + _this.loginId + '/private', function (sdkEvent: any) {
        console.log(sdkEvent + "sdkEvent");
        console.log("connect method");
        _this.onMessageReceived(sdkEvent);
      });
      _this.stompClient.subscribe('/user/'+_this.loginId + '/message-status', function (message: any) {
          _this.onMsgAcknowledge(message);
       });
      _this.stompClient.subscribe('/user/'+_this.loginId + '/msgsList', function(messages:any) {
         _this.mainServiceService.updateMsgsList(messages);
      })
      
    },
      _this.stompClient.errorCallBack
    );

    _this.stompClient.debug = function (str: string) {
      //       console.log(str);
    };
  }
 sendFileDownloadedAck(mid:string){
    if(this.stompClient.connected){
       this.stompClient.send("/app/chat/fileDownloadAck",{},mid)
    }
    else{
      alert("Servere is not connected to send download acknowledge")
    }
  }
  error() {
    console.log("Connection lost!");
  }
  getPendingMsgs(userId:any){
    if(this.stompClient.connected){
      this.stompClient.send("/app/chat/getPendingChatMsgs",{},JSON.stringify(userId))
    }
    else{
      //alert("server is not connected yet while getting the pending messages")
      console.log("server is not connected yet while getting users messages")
    }
  }
  errorCallBack(error: any) {
    console.log("errorCallBack -> " + error);
    const _this = this;
    setTimeout(function () {
      _this.connect();
    }, 5000);
  }

  disconnect() {
    var headers = {
      'userId': this.loginId,
      'email': this.email,
      'userTypeId': this.userTypeId
    };
    const _this = this;
    if (_this.stompClient !== null) {
      _this.stompClient.disconnect(function () {
        console.log("Client disconnected... : ");
        _this.loginId = null;
      }, headers);
    }
  }
  //Bhabani 09/03/2024
  handleReadMarker(notificationId: any) {
    console.log("calling read marker with callBackId = " + notificationId);
    this.stompClient.send("/app/read-marker", {}, notificationId);
  }
  
  
  
  onMessageReceived(payload: any) {
    var payloadData = JSON.parse(payload.body);
    console.log("payload data from on message received function" + payloadData);
    if (payloadData.messageType != 'CONNECTION_EVENT') {
      this.sendAcknowledgment(payloadData.callBackId, payloadData.delivered);

      //Bhabani 26/03/2024
      this.mainServiceService.segregateWebsocketData(payloadData);
    } else {
      console.log("On Connection Event Received");
    }
  }

   sendAcknowledgment(callBackId: string, delivered: boolean) {
    if (this.stompClient) {
      var body = {
        callBackId: callBackId,
        delivered: delivered
      }
      this.stompClient.send('/app/acknowledge', {}, JSON.stringify(body));
      console.log("Acknowledgement Sent to Server for callBackId = " + callBackId);
    }
  }

  sendPrivateValue(chatMessage) {
    if (this.stompClient.ws.readyState == 1) {
      this.stompClient.send("/app/chat/send", {}, JSON.stringify(chatMessage));
    }
    else{
      alert('Server is not responding, pls wait or try again later');
      this.onMsgAcknowledge(chatMessage)
    }
  }
   onMsgAcknowledge(message){
    // this.messageReceived.next(message);
    this.mainServiceService.segrigateMsgAck(message);
  }

  sendImageWebsocket( sendMessageObj: any){
   
    if (this.stompClient) {
      this.stompClient.send("/app/chat/image/send",{}, JSON.stringify(sendMessageObj))
    }
  }
  sendFileWebsocket(fileData :any){
    if(this.stompClient){
      this.stompClient.send("/app/chat/file/send",{},JSON.stringify(fileData))
    }
  }
  getUserOnlineStatus(selectedUserId :any, senderId:any){
   let userStatusObj={
      senderId : senderId,
      receiverId : selectedUserId,
      messageType:'Chat'
    }
    if(this.stompClient){
      this.stompClient.send("/app/heartbeatChat", {}, JSON.stringify(userStatusObj));
    }
  }
  sendMsgDlvryAck(message :any){
    if(this.stompClient){
      //this.stompClient.send("/app/chat/sendMsgAck",{},JSON.stringify(message)) // caused by gowthami
      this.stompClient.send("/app/chat/deliveredMsgAck",{},JSON.stringify(message))
    }
  }
  msgSeenAck(unReadMsgList){
    if(this.stompClient){
        this.stompClient.send("/app/chat/seenMsgAck",{},JSON.stringify(unReadMsgList))
    }
  }
  sendTypingAck(userId :any,isTyping:boolean){
    const msgTypingAck={userId,isTyping}
    if(this.stompClient){
      this.stompClient.send("/app/chat/sendTypingAck",{},JSON.stringify(msgTypingAck));
    }
  }
  getUsersChat(senderId :any,receiverId:any,pageNumber:number){
    let obj={  // we can add only one object to send
      senderId:senderId,
      receiverId:receiverId,
      pageNumber:pageNumber
    }
    if(this.stompClient.ws.readyState == 1){
      this.stompClient.send("/app/getUsersChat",{},JSON.stringify(obj));
    }
    else{
      alert('server is not connected yet')
    }
  }

  sendNotificationUpdate(notificationId) {
    if (this.stompClient) {
      this.stompClient.send("/app/read-marker", {}, notificationId)
      console.log("Readed NotificationId : " + notificationId);
    }
  }

  isStompClientConnected(): boolean {
    if (this.stompClient.ws.readyState == 1) {
      return true;
    } else {
      return false;
    }
  }

  handleIsSeenMarker(userId: any) {
    this.stompClient.send("/app/isSeen-marker", {}, userId);
  }

  handleIsDeleted(callbackId: any) {
    this.stompClient.send("/app/isDeleted", {}, callbackId);
  }
}
