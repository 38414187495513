

 <div class="container-fluid main-body" >
  <div class="row">
 <div class=" col-12 col-md-12 col-lg-8 top-head">
<h1>Excellent Marketplace <br><span style="padding-left: 40px;">For Freelancers</span></h1>
<P class="para">Earn good money with <span style="color: #fff;">Acejobber</span></P>
<button class="btns" (click)="jobberLogin()">Join Acejobber</button>
 </div>
 <div class="col-12 col-md-12 col-lg-4 img-3" style="width: 400px;">
<img src="./assets/craft.webp" alt="" class="craft" >
 </div>
  </div>
</div> 
<div class="container-fluid box-2">
    <div class="row">
        <div class=" col-12 col-md-4">
    <img src="assets/LPWebp/potrait.webp" alt="" width="300px" height="300px" class="img-2" >
        </div>
        <div class=" col-12 col-md-8 free-job">
    <h1 style="color: black;">Browse job openings for free</h1>
    <p style="font-size: 16px;color: black;" class="p">Register and start browsing instantly. Get job alerts that 
        <br>match your skills and experience.
    <span></span></p>
    <button class="btns">Sign Up as a Jobber</button>
        </div>
      </div>
</div>
<div class="container-fluid box-3">
    <div class="row">
        <div class="col-12 h2-1"><h2>How it Works</h2></div>
    </div>
    <hr>
    <div class="row how-works">
        <div class="col-12 col-md-2" style="padding-left: 30px;">
<img src="assets/LPWebp/works1.webp" alt="" class="image-1">
        </div>
        <div class=" col-12 col-md-8 para-1">
<h2 class="create">Create your profile </h2>
<p >Create a striking profile that helps you to grab the opportunities you want <br> upload your photo and your id along with your work experience summary.</p>
        </div>
    </div>
    <div class="row how-works" style="margin-top: 20px">
        <div class="col-12 col-md-2" style="padding-left: 30px;">
<img src="assets/LPWebp/works2.webp" alt=""  class="image-1">
        </div>
        <div class=" col-12 col-md-8 para-1">
            <h2 class="create">Set Your Price</h2>
            <p style="margin-top: -10px;">Find opportunities and contact customers you prefer and build your carrer <br> and network.</p>
        </div>
    </div>
    <div class="row offer">
        <div class="col-12 col-md-2" style="padding-left: 30px;">
            <img src="assets/LPWebp/works3.webp" alt=""  class="image-1">

        </div>
        <div class=" col-12 col-md-8 para-1">
<h2 class="main-heading" class="create">Accept the Offer</h2>
<p class="pp">You can negotitate and accept the offer from the customer</p>
        </div>
    </div>
    <div class="row offer">
        <div class="col-12 col-md-2" style="padding-left: 30px;">
            <img src="assets/LPWebp/works4.webp" alt="" class="image-1">

        </div>
        <div class=" col-12 col-md-8 para-1">
<h2 class="create">Complete your work.Get paid</h2>
<p  class="pp">Once you complete the work you can accept the payment by choosing the mode of receving the payment</p>
        </div>
    </div>
</div>
<div class="container-fluid box-4">
      <div class="row">
        <div class=" col-12 col-md-5 pay-works">
<img src="assets/LPWebp/payments.webp" alt="" class="img-4">
        </div>
        <div class=" col-12 col-md-7 pay-works">
<h2>How Payments Work</h2>
<hr>
<h4>
    All in one place 
</h4>
<p class="payments-p">invoice clients and track your earnings for a simple and <br>streamlined process. </p>
<h4>
   Choose how you get paid
</h4>
<p class="payments-p">Use what works best for you-including <span>PhonePe,googlepay </span> <br> wire transfter,and more.</p>
<h4>
    No fees until complete your work
</h4>
<p class="payments-p">You'll pay a 10% freelancer service fee on what you earn </p>

<h5 style="color: rgb(43, 43, 217);text-decoration: underline;">Learn more about service fees</h5>
        </div>
      </div>
</div>
<div class="container-fluid box-5">
    <div class="row">
        <div class=" col-12 col-md-12 col-lg-4" style="padding-left: 20px;">
<h2 class="frequent">Frequently asked  questions </h2>
        </div>
        <div class=" col-12 col-md-12 col-lg-8 quest">
<p class="question" style="margin-bottom: -7px;">Is Acejobber free to join as a Jobber ?</p>
<p style="margin-bottom:0px;"class="answer">Ans :Yes.</p>
<p class="read-more">Read more<span class="material-symbols-outlined">
    keyboard_arrow_down
    </span></p>
    <hr>
    <p class="question" style="margin-bottom: -7px;">Can i work on longterm basis with Acejobber ?</p>
<p style="margin-bottom: 0px;" class="answer">Ans :Yes.</p>
<p  class="read-more">Read more<span class="material-symbols-outlined">
    keyboard_arrow_down
    </span></p>
    <hr>
    <p class="question" style="margin-bottom: -7px;">How the payments are made by Acejobber ?</p>
<p style="margin-bottom: 0px;" class="answer">Ans :You can  receive the payment through upi or direct bank account.</p>
<p  class="read-more">Read more<span class="material-symbols-outlined">
    keyboard_arrow_down
    </span></p>
    <hr>
        </div>
    </div>
</div>
<div class="container-fluid box-6">
    <div class="row fourth-div">
        <div class="col-12 col-md-12 col-lg-8">
<h2 class="h2">Download the Acejobber App</h2>

<div class="card">
  <div class="circle"></div>
  <div class="circle"></div>
    <div class="card-inner">
<p  class="p1" style="padding-top: 20px;"><span class="material-symbols-outlined">
    done
    </span>Create your profile</p>  
<p  class="p1"><span class="material-symbols-outlined">
    done
    </span>Get notifications for the open jobs </p>  
<p  class="p1"><span class="material-symbols-outlined">
    done
    </span>Accepts the job offer</p>  
<p class="p1"><span class="material-symbols-outlined">
    done
    </span>Complete the job </p>  
<p  class="p1"><span class="material-symbols-outlined">
    done
    </span>Receive the payment.</p> 
    <div style="display: flex;align-items: center;justify-content: center;gap: 5px;">
       <div>
        <a  class="playstore-button">
            <span class="icon">
              <svg
                fill="currentcolor"
                viewBox="-52.01 0 560.035 560.035"
                xmlns="http://www.w3.org/2000/svg"
                stroke="#ffffff"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <path
                    d="M380.844 297.529c.787 84.752 74.349 112.955 75.164 113.314-.622 1.988-11.754 40.191-38.756 79.652-23.343 34.117-47.568 68.107-85.731 68.811-37.499.691-49.557-22.236-92.429-22.236-42.859 0-56.256 21.533-91.753 22.928-36.837 1.395-64.889-36.891-88.424-70.883-48.093-69.53-84.846-196.475-35.496-282.165 24.516-42.554 68.328-69.501 115.882-70.192 36.173-.69 70.315 24.336 92.429 24.336 22.1 0 63.59-30.096 107.208-25.676 18.26.76 69.517 7.376 102.429 55.552-2.652 1.644-61.159 35.704-60.523 106.559M310.369 89.418C329.926 65.745 343.089 32.79 339.498 0 311.308 1.133 277.22 18.785 257 42.445c-18.121 20.952-33.991 54.487-29.709 86.628 31.421 2.431 63.52-15.967 83.078-39.655"
                  ></path>
                </g>
              </svg>
            </span>
            <span class="texts"  (click)="warning()">
              <span class="text-1">Download on the</span>
              <span class="text-2">App store</span>
            </span>
          </a>
       </div>
       <div>
        <a  class="playstore-button">
            <span class="icon">
              <svg
                fill="currentcolor"
                viewBox="-52.01 0 560.035 560.035"
                xmlns="http://www.w3.org/2000/svg"
                stroke="#ffffff"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <path
                    d="M380.844 297.529c.787 84.752 74.349 112.955 75.164 113.314-.622 1.988-11.754 40.191-38.756 79.652-23.343 34.117-47.568 68.107-85.731 68.811-37.499.691-49.557-22.236-92.429-22.236-42.859 0-56.256 21.533-91.753 22.928-36.837 1.395-64.889-36.891-88.424-70.883-48.093-69.53-84.846-196.475-35.496-282.165 24.516-42.554 68.328-69.501 115.882-70.192 36.173-.69 70.315 24.336 92.429 24.336 22.1 0 63.59-30.096 107.208-25.676 18.26.76 69.517 7.376 102.429 55.552-2.652 1.644-61.159 35.704-60.523 106.559M310.369 89.418C329.926 65.745 343.089 32.79 339.498 0 311.308 1.133 277.22 18.785 257 42.445c-18.121 20.952-33.991 54.487-29.709 86.628 31.421 2.431 63.52-15.967 83.078-39.655"
                  ></path>
                </g>
              </svg>
            </span>
            <span class="texts"  (click)="warning()">
              <span class="text-1">Download on the </span>
              <span class="text-2">Google Play</span>
            </span>
          </a>
       </div>     
    </div>
</div> 
</div>
        </div> 
        <div class=" col-12  col-md-12 col-lg-4 all">
<div class="download">
 <div class="download-1">
 <button class="Btn"  (click)="warning()">
 <svg class="svgIcon" viewBox="0 0 384 512" height="1em" xmlns="http://www.w3.org/2000/svg"><path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"></path></svg>
 <span class="icon2"></span>
  <span class="tooltip">Download</span>
</button>
</div>
</div>
 </div>
    </div>
</div>



