import { Component, Injectable, ViewChild } from '@angular/core';
import { ServiceFileService } from '../jobber-module/services/service-file.service';
import { Router, RouterModule } from '@angular/router';
import { ServiceService } from '../jobposter-module/service.service';
import { MainServiceService } from '../Services/main-service.service';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DialogModule } from 'primeng/dialog';
import { CommonModule } from '@angular/common';
import { CategoryservicefilterComponent } from '../categoryservicefilter/categoryservicefilter.component';
import { ModalController, IonicModule } from '@ionic/angular';
import { DeviceService } from '../Services/device.service';
@Component({
  selector: 'app-first-page',
  templateUrl: './first-page.component.html',
  styleUrls: ['./first-page.component.scss'],


})
export class FirstPageComponent {

  loginAndDismiss() {
    // Your login logic here
    this.modalController.dismiss();

  }

  signupAndDismiss() {
    // Your signup logic here
    this.modalController.dismiss();
  }

  showMe: boolean = false
  toggleTag() {
    // this.getCatogory();
    this.showMe = !this.showMe
  }
  @ViewChild('userForm') userForm: NgForm | undefined;
  modalOpen = false;
  registerForm!: FormGroup
  submitted = false

  loginjobber: boolean = false
  showModal: boolean = false;
  showMobileTemplate = true;
  selectedCategory: any
  categoryData: any
  categoryId: any
  categoryName: any

  selectedLocation: any
  uniqueCities: any
  murali: any;

  searchObj = {
    category: '',
    city: ''


  }

  constructor(private _formBuilder: FormBuilder, private mainServiceService: MainServiceService, private router: Router, private serviceService: ServiceService, private modalController: ModalController, public deviceService: DeviceService) {

  }

  categoryForm = this._formBuilder.group({
    category: new FormControl(null, [Validators.required]),
    service: new FormControl(null, [Validators.required])
  })

  navigate(value) {
    sessionStorage.clear()
    if (value == 'postJob') {
      sessionStorage['loginId'] = null
      sessionStorage['token'] = null
      this.router.navigate(['acejobber/post-job',]);
    }
    else if (value == 'findjob') {
      this.loginjobber = !this.loginjobber;
    }
    else if (value == 'admin') {
      this.router.navigate(['/admin',]);
    }
    else {
      this.router.navigate(['/acejobber/' + value + '/login-page',]);
    }
  }
  selectedcategory() { }
  selectedlocation() { }


  search() {
    this.searchObj.category = this.selectedCategory
    this.searchObj.city = this.selectedLocation
    this.mainServiceService.sendingSearchingData(this.searchObj)
    this.submitted = true
    if (this.registerForm.invalid) {
      return
    }
    this.router.navigate(['acejobber/jobber/category-page']);

  }

  showLoader = true;
  ngOnInit() {
    //ionic platform
    this.deviceService.waitForPlatformReady().then(() => {
      console.log('Device type:', this.deviceService.getPlatformCode());
    });
    setTimeout(() => {
      this.showLoader = false;
    }, 4000);

    this.registerForm = this._formBuilder.group({
      categoryname: ['', Validators.required],
      servicename: ['', Validators.required]
    })
    // this.getCatogory();
  }
  getStarted() {
    this.showLoader = true;
  }
}
