<!-- mobile -->
<div *ngIf="deviceService.isMobile">
  <div *ngIf="showLoader">
    <div class="loader d-flex align-items-center justify-content-center flex-column" style="height:100%">
      <img src="assets/figma/newlogo.webp" class="main-logo">
      <b>AceJobber</b>
      <img src="assets/figma/fig01.webp" alt="" class="fig01">
      <img src="assets/figma/fig02.webp" alt="" class="fig02">
      <img src="assets/figma/fig03.webp" alt="" class="fig03">
      <img src="assets/figma/fig04.webp" alt="" class="fig04">
      <img src="assets/figma/fig05.webp" alt="" class="fig05">
      <img src="assets/figma/fig06.webp" alt="" class="fig06">


    </div>
  </div>

  <div *ngIf="!showLoader" class="second-div">
    <!-- <img src="assets/figma/char1.webp" alt="Your Image"> -->
    <div class="col-12 ss">
      <input class="carousel__btn" type="radio" name="carousel-control" id="carousel__btn1" hidden />
      <input class="carousel__btn" type="radio" name="carousel-control" id="carousel__btn2" hidden />
      <input class="carousel__btn" type="radio" name="carousel-control" id="carousel__btn3" hidden />
      <div class="carousel">
        <div class="carousel__slides">
          <div class="carousel__slid">
            <img class="carousel__image" src="assets/figma/char1.webp" alt="" />
            <p class="para-ionic">Fast and Professional Home Solutions</p>


          </div>
          <div class="carousel__slid">

            <img src="assets/figma/customer.webp" alt="" class="carousel__image" />
            <p class="para-ionic">Fast and Professional Cleaning Solutions</p>


          </div>
          <div class="carousel__slid">

            <img src="assets/figma/complete.webp" alt="" class="carousel__image" />
            <p class="para-ionic">Fast and Professional Electrical Solutions</p>

          </div>
        </div>
        <div class="carousel__control">
          <label for="carousel__btn1" id="carousel__nav1" class="carousel__nav"></label>

          <label for="carousel__btn2" id="carousel__nav2" class="carousel__nav"></label>

          <label for="carousel__btn3" id="carousel__nav3" class="carousel__nav"></label>
        </div>
      </div>
    </div>


    <button id="open-modal" expand="block" class="buttons-one" routerLink="/example">Get Started</button>


  </div>
</div>
<!-- Tablet -->
<div *ngIf="deviceService.isTablet">Tablet Platform</div>
<!-- Web responsive -->
<div *ngIf="deviceService.isWeb ">
  <section class="main-section mb-3">
    <div class="section-container">
      <div class="flex-container">
        <div class="primary-column">
          <div class="top-content">
            <div class="content-wrapper">
              <div class="flex-row">
                <div class="image-column">
                  <img src="assets/figma/first1.webp" alt="Thumbnail" class="thumbnail" />
                </div>
                <div class="text-column">
                  <div class="section-title">
                    <div class="title-main">Transforming</div>
                    <div class="subtitle">Houses to Homes</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="image-grid">
              <img src="assets/figma/frame2.webp" alt="Grid Image 1" class="grid-image" />
              <button class="large-image" (click)="navigate('findjob')" (click)="toggleTag()">Find a Job</button>
              <button class="large-image" (click)="navigate('postJob')">Post a Job</button>

            </div>
          </div>
        </div>
        <div class="secondary-column">
          <div class="secondary-images">
            <img src="assets/figma/frame3.webp" alt="Secondary Thumbnail 1" class="secondary-thumbnail" />
            <img src="assets/figma/frame4.webp" alt="Secondary Thumbnail 2" class="secondary-large-image" />
          </div>
        </div>
      </div>
    </div>
    <router-outlet></router-outlet>
    <div class="row category-main">
      <div class="col-12  d-flex align-items-center justify-content-center ">
        <div *ngIf="showMe">
          <app-categoryservicefilter></app-categoryservicefilter>
        </div>
      </div>
    </div>
  </section>
  <app-categories></app-categories>
  <app-popular-categories></app-popular-categories>
</div>

