import { Component, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  NgForm,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MainServiceService } from '../Services/main-service.service';
import { Router } from '@angular/router';
import { ServiceService } from '../jobposter-module/service.service';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MatSelectModule } from '@angular/material/select';
import { NgZone } from '@angular/core';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-categoryservicefilter',
  templateUrl: './categoryservicefilter.component.html',
  styleUrls: ['./categoryservicefilter.component.scss'],
  //  standalone:true, (removed from the standalone because this component has to load at landinig page)
  //  imports:[ReactiveFormsModule,AutoCompleteModule]
})
export class CategoryservicefilterComponent {

  isMobile: boolean;
isTablet: boolean;
isWeb: boolean;

  @ViewChild('userForm') userForm: NgForm | undefined;

//   @ViewChild('userForm') userForm : NgForm | undefined;
  
//   registerForm!:FormGroup
//   submitted=false


//   loginjobber:boolean=false
//   showModal: boolean = false;

//   selectedCategory:any
//   categoryData:any
//   categoryId:any
//   categoryName:any

//   selectedLocation:any
//   uniqueCities:any

//   searchObj={
//     category:'',
//     city:''

//   }

  
//   selectedName:any
//   selectedItem: any;
//   suggestions: string[];
//   suggestion: any[];
//   allItems: any[];
//   selectedCategoryName: string;
//   constructor( private _formBuilder: FormBuilder,  private mainServiceService:MainServiceService ,private router : Router, private serviceService:ServiceService){}
  
//   categoryForm = this._formBuilder.group({
//     category: new FormControl(null , [Validators.required]),
//     service: new FormControl(null,  [Validators.required])
//   })

//   ngOnInit(){ 
//     this.registerForm=this._formBuilder.group({
//       categoryname:['',Validators.required],
//       servicename:['',Validators.required]
//     })

//     this.serviceService.getCategory().subscribe((data:any) => {
//       this.categoryData=data
//       this.categoryData.sort();
//      })
//      this.mainServiceService.getAllUniqueCities().subscribe((data:any) =>  {
//       this.uniqueCities=data
//       this.uniqueCities.sort();
//       })
     
//     }
  
//    search(){
//     this.searchObj.category=this.selectedCategory
//     this.searchObj.city=this.selectedLocation
//     console.log(this.selectedCategory)
//     console.log(this.selectedCategoryName)
//     if (!this.categoryData.some(category => category.name === this.selectedName)) {
//       return;
//       }
//     if (!this.uniqueCities.includes(this.selectedLocation)) {
//       return;
//     }
//     this.mainServiceService.sendingSearchingData(this.searchObj)
//     this.submitted=true
//     if(this.registerForm.invalid){
//       return
//     }
//      this.router.navigate(['acejobber/jobber/category-page']);
//     console.log(sessionStorage['loginId'])
//     if(sessionStorage['loginId'] === "null" ||sessionStorage['loginId'] === undefined){
//       this.router.navigate(['acejobber/jobber/category-page']);
//     }
//     else if(sessionStorage['loginId'] != "null" ||sessionStorage['loginId'] != undefined){
//       this.router.navigate(['acejobber/jobber/categorypage'])
//     }
//    }

//   searchs(event) {
//     let query = event.query;
//      if (query.length > 0) {
//       this.suggestions = this.uniqueCities.filter(item => item ? item.toLowerCase().startsWith(query.toLowerCase()) : false);
//      } 

//   }
 
//   searching(event) {
//     let query = event.query;
//     if (query.length > 0) {
//     this.suggestion = this.categoryData.filter(item => item ? item.name.toLowerCase().startsWith(query.toLowerCase()) : false)
//        .map(item => ({name: item.name, id: item.id}));
//     }
//    }

//   onSelect(event) {
//      this.selectedCategory = event.id;
//      this.selectedName=event.name
//     setTimeout(() => {
//       this.search();
//      }, 0);
//    }
   
// }
registerForm!: FormGroup;
  submitted = false;

  loginjobber: boolean = false;
  showModal: boolean = false;

  selectedCategory: any;
  categoryData: any;
  categoryId: any;
  categoryName: any;

  selectedLocation: any;
  uniqueCities: any;

  searchObj = {
    category: '',
    city: '',
  };

  selectedName: any;
  selectedItem: any;
  suggestions: string[];
  suggestion: any[];
  allItems: any[];
  selectedCategoryName: string;
  constructor(
    private _formBuilder: FormBuilder,
    private mainServiceService: MainServiceService,
    private router: Router,
    private serviceService: ServiceService,
    private platform: Platform
  ) {}

  categoryForm = this._formBuilder.group({
    category: new FormControl(null, [Validators.required]),
    service: new FormControl(null, [Validators.required]),
  });

  ngOnInit() {
    this.isMobile = this.platform.is('mobile');
this.isTablet = this.platform.is('tablet');
this.isWeb = !this.platform.is('mobile') && !this.platform.is('tablet');


    this.registerForm = this._formBuilder.group({
      categoryname: ['', Validators.required],
      servicename: ['', Validators.required],
    });

    this.serviceService.getCategory().subscribe((data: any) => {
      this.categoryData = data;
      this.categoryData.sort();
    });
    this.mainServiceService.getAllUniqueCities().subscribe((data: any) => {
      this.uniqueCities = data;
      console.log(data);
      this.uniqueCities.sort();
    });
  }

  search() {
    this.searchObj.category = this.selectedCategory;
    this.searchObj.city = this.selectedLocation;
    console.log(this.selectedCategory);
    console.log(this.selectedCategoryName);
    if (
      !this.categoryData.some((category) => category.name === this.selectedName)
    ) {
      return;
    }
    if (!this.uniqueCities.includes(this.selectedLocation)) {
      return;
    }
    this.mainServiceService.sendingSearchingData(this.searchObj);
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.router.navigate(['acejobber/jobber/category-page']);
    console.log(sessionStorage['loginId']);
    if (
      sessionStorage['loginId'] === 'null' ||
      sessionStorage['loginId'] === undefined
    ) {
      this.router.navigate(['acejobber/jobber/category-page']);
    } else if (
      sessionStorage['loginId'] != 'null' ||
      sessionStorage['loginId'] != undefined
    ) {
      this.router.navigate(['acejobber/jobber/categorypage']);
    }
  }

  searchs(event) {
    let query = event.query;
    if (query.length > 0) {
      this.suggestions = this.uniqueCities.filter((item) =>
        item ? item.toLowerCase().startsWith(query.toLowerCase()) : false
      );
    }
  }

  searching(event) {
    let query = event.query;
    if (query.length > 0) {
      this.suggestion = this.categoryData
        .filter((item) =>
          item ? item.name.toLowerCase().startsWith(query.toLowerCase()) : false
        )
        .map((item) => ({ name: item.name, id: item.id }));
    }
  }

  onSelect(event) {
    this.selectedCategory = event.value.id;
    this.selectedName = event.value.name;
    setTimeout(() => {
      this.search();
    }, 0);
  }
}
