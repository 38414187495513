import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  lastValueFrom,
  map,
  of,
} from 'rxjs';
import { Injectable } from '@angular/core';
import { AppConstants } from './../appConstants';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ResponseServiceService } from '../jobposter-module/response-service.service';
import { ServiceService } from '../jobposter-module/service.service';

@Injectable({
  providedIn: 'root',
})
export class MainServiceService {
  // Declare castReceivedMessagesList as a private property
  private _castReceivedMessagesList!: Observable<any[]>;

  constructor(
    private http: HttpClient,
    private responseService: ResponseServiceService,
    private service: ServiceService
  ) {}
  wSServiceObj: any;
  wSClient: any;
  chatMessage: any;
  websocketStomp: any;
  //=================================
  private hireRequest = new Subject<any>();
  castHireRequest = this.hireRequest.asObservable();
  updateHireRequest(hireRequest) {
    this.hireRequest.next(hireRequest);
  }
  //=================================
  private PushNotification = new Subject<any>();
  castPushNotification = this.PushNotification.asObservable();
  updatePushNotification(PushNotification) {
    this.PushNotification.next(PushNotification);
  }
  //=================================
  private halfReg = new Subject<any>();
  castHalfReg = this.halfReg.asObservable();
  updateHalfReg(halfReg) {
    this.halfReg.next(halfReg);
  }
  private subscribedOnce: boolean = false;
  hasSubscribedOnce(): boolean {
    return this.subscribedOnce;
  }
  setSubscribedOnce(value: boolean) {
    this.subscribedOnce = value;
  }
 
  //=================================
  private spList = new Subject<any>();
  castSPList = this.spList.asObservable();
  updateSPList(spList) {
    this.spList.next(spList);
  }
  //==================================================
  private receivedMessage = new Subject<any>();
  castReceivedMessage = this.receivedMessage.asObservable();
  updateReceivedMessage(newReceivedMessage) {
    this.receivedMessage.next(newReceivedMessage);
  }
  //=================================================
  private selectChatter= new Subject<any>();
   selectedChatter=this.selectChatter.asObservable();

   updateSelectedChatter(value:any){
    this.selectChatter.next(value);
  }
  getCustomerChat(){
    if(this.responseService.isExistCustomerChat){
        return of(this.responseService.getCustomerChat())
    }
  return null;
 }
 getSPChat(){
  if(this.responseService.isExistSPChat){
    return of (this.responseService.getSPChat());
  }
  return null;
}
  //  private msgsList = new BehaviorSubject<any[]>([]);
  //  castReceivedMessagesList= this.msgsList.asObservable();

  //  updateMsgsList(newMsg){
  //   // this.msgsList.next([...this.msgsList.getValue(), newMsg]);
  //   const currentMsgs=this.msgsList.getValue();
  //    if(currentMsgs  == null){
  //        this.msgsList.next([newMsg])
  //    }
  //    else{
  //     this.msgsList.next([...currentMsgs, newMsg])
  //    }

  //  }
  private msgsList = new Subject<any>();
  castReceivedMessagesList = this.msgsList.asObservable();
  updateMsgsList(newMsg) {
    let data = JSON.parse(newMsg.body);
    //this.msgsList.next(data.messages); // this is cause by gawthami
    this.msgsList.next(data)
  }
  //   private msgsList: Map<string, BehaviorSubject<any[]>> = new Map();
  //   castReceivedMessagesList=combineLatest(Array.from(this.msgsList.values()).map(bs => bs.asObservable())).pipe(map(arrays => arrays.flat()));
  //   getMsgsListByUser(key:any): BehaviorSubject<any[]> {
  //     if (!this.msgsList.has(key)) {
  //       this.msgsList.set(key, new BehaviorSubject<any[]>([]));
  //     }
  //     return this.msgsList.get(key)!;
  //   }
  //   updateMsgsList(newMsg: any) {
  //     const obj = JSON.parse(newMsg.body);
  //     const { senderId, receiverId } = obj.combUsers;
  //     const key = `${senderId}_${receiverId}`;
  // if(this.responseService.isExistMessagedDataList){
  //    this.responseService.getMessageDataList(key)
  // }
  // else{
  //   this.responseService.setMessageDataList(key , newMsg)
  //   // return newMsg ;
  //   this.castReceivedMessagesList = newMsg
  // }
  //     // Get or initialize the BehaviorSubject for this user pair
  //     const msgList = this.getMsgsListByUser(key);

  //     // Add the new message to the list
  //     msgList.next([...(msgList.getValue() || []),...obj.messages]);
  //   }
  //==================================================
  private updateNHistory = new Subject<any>();
  castUpdateNHistory = this.updateNHistory.asObservable();
  updateUpdateNHistory(PushNotification) {
    this.updateNHistory.next(PushNotification);
  }
  //==================================================
  private updateNHistoryy = new Subject<any>();
  castUpdateNHistoryy = this.updateNHistoryy.asObservable();
  updateUpdateNHistoryy(PushNotification) {
    this.updateNHistoryy.next(PushNotification);
  }
  //=================================
   //==================================================
   private updateNCount = new Subject<any>();
   castUpdateNCount = this.updateNCount.asObservable();
   updateUpdateNCount(updateNCount) {
     this.updateNCount.next(updateNCount);
   }
   //=================================
  private updateJobStatus = new Subject<any>();
  castUpdateJobStatus = this.updateJobStatus.asObservable();
  updateJobberJobStatus(PushNotification) {
    this.updateJobStatus.next(PushNotification);
  }
  // ================================================
  private reCallService = new Subject<any>();
  castReCallService = this.reCallService.asObservable();
  updateReCallService(reCallService) {
    this.reCallService.next(reCallService);
  }
  //=================================
  private user = new Subject<any>();
  castUser = this.user.asObservable();
  //  mohan (25/04/24) ==========
  private messageReceived = new Subject<any>();
  castReceivedMsgAcknowledge = this.messageReceived.asObservable();
  // ====================
  editUser(newUser) {
    this.user.next(newUser);
  }
  //=============================

  getWebsocketStomp() {
    return this.websocketStomp;
  }

  setWebsocketStomp(websocketStomp) {
    this.websocketStomp = websocketStomp;
  }
  //============================================
  getWSServiceObj() {
    return this.wSServiceObj;
  }

  setWSServiceObj(wSServiceObj) {
    this.wSServiceObj = wSServiceObj;
  }

  getWSClientObj() {
    return this.wSClient;
  }

  setWSClientObj(wSClient) {
    this.wSClient = wSClient;
  }

  setChatMessage(chatMessage) {
    this.chatMessage = chatMessage;
  }

  getChatMessage() {
    return this.chatMessage;
  }

  sendDataToKafka(obj): Observable<any> {
    return this.http
      .post<any>(AppConstants.websocketHost + '/temp/user/session/save/ui', obj)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  activateTFAuth(id): Observable<any> {
    return this.http
      .get<any>(
        AppConstants.api_based_url +
          '/jobPosterRegistration/activateTFAuth?loginId=' +
          id
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getUserChatsList(id): Observable<any> {
    return this.http
      .get<any>(
        AppConstants.api_based_url + '/chatCls/getUserChatsList?id=' + id
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getInboxByUserId(inbox) {
    return this.http
      .put<any>(AppConstants.api_based_url + '/inbox/getByUserId', inbox)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  sendJobRequest(jobId, jobberId) {
    return this.http
      .put(
        AppConstants.api_based_url + `/postJob/jobRequest/${jobId}/${jobberId}`,
        jobId
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  verifyAccount(user): Observable<any> {
    return this.http
      .post<any>(
        AppConstants.api_based_url + '/userAccount/updateIsActivate',
        user
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  postJobWholeSave(job): Observable<any> {
    return this.http
      .post<any>(AppConstants.api_based_url + '/postJob/wholeSave', job)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getUserByLoginId(userId): Observable<any> {
    return this.http
      .get<any>(
        AppConstants.api_based_url +
          '/userAccount/getDetailsById?loginId=' +
          userId
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  chatSave(chat): Observable<any> {
    return this.http
      .post<any>(AppConstants.api_based_url + '/chatCls/save', chat)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  chatUpdateReceived(chat): Observable<any> {
    return this.http
      .put<any>(AppConstants.api_based_url + '/chatCls/updateReceived', chat)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  inboxUpdateSeen(inbox): Observable<any> {
    return this.http
      .put<any>(AppConstants.api_based_url + '/inbox/updateSeen', inbox)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getChat(chat: any): Observable<any> {
    return this.http
      .put<any>(AppConstants.api_based_url + '/chatCls/getChat', chat)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  // login apis

  generateToken(login): Observable<any> {
    return this.http
      .post<any>(
        AppConstants.api_based_url + '/userAccount/generateToken',
        login
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getAllSp(): Observable<any> {
    return this.http
      .get<any>(
        AppConstants.api_based_url + '/serviceProviderRegistration/getAll'
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getAllJp(): Observable<any> {
    return this.http
      .get<any>(AppConstants.api_based_url + '/jobPosterRegistration/getAll')
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  // Written by Gowthami

  public getAllPostJob() {
    return this.http.get(AppConstants.api_based_url + '/postJob/getAll');
  }
  public getAllProfile() {
    return this.http.get(AppConstants.api_based_url + '/user/getAll');
  }
  public getAllLocations() {
    return this.http.get(AppConstants.api_based_url + '/location/getAll');
  }
  public getAllCustomers() {
    if (this.responseService.isExistGetAllCustomers()) {
      return of(this.responseService.getGetAllCustomers());
    } else {
      return this.http
        .get(AppConstants.api_based_url + '/jobPosterRegistration/getAll')
        .pipe(
          map((data) => {
            this.responseService.setGetAllCustomers(data);
            return data;
          })
        );
    }
  }
  public getAllUniqueCities() {
    if (this.responseService.isExistingUniqueCities()) {
      return of(this.responseService.getUniquecities());
    } else {
      return this.http
        .get(AppConstants.api_based_url + '/location/cities')
        .pipe(
          map((data) => {
            this.responseService.setUniquecities(data);
            return data;
          })
        );
    }
  }

  public getAllServices() {
    return this.http.get(
      AppConstants.api_based_url + '/service/getAllServices'
    );
  }

  sendingSearchingData(data) {
    localStorage.setItem('categoryandCity', JSON.stringify(data));
  }

  receivingSearchingData() {
    const data = localStorage.getItem('categoryandCity');
    return data ? JSON.parse(data) : null;
  }

  setIndexData(data) {
    localStorage.setItem('jobIndex', JSON.stringify(data));
  }
  getIndexData() {
    const data = localStorage.getItem('jobIndex');
    return data ? JSON.parse(data) : null;
  }

  serviceName: any;
  setServiceName(data) {
    this.serviceName = data;
  }
  getServiceName() {
    return this.serviceName;
  }
  segregateWebsocketData(value) {
    console.log(" main service")
    if(value.isSeenCount || value.readMarkerCountToday || value.readMarkerCountPrevious) {
      sessionStorage["isSeenCount"]=value.isSeenCount??value.isSeenCount;
      sessionStorage["readMarkerCountToday"]=value.readMarkerCountToday??value.readMarkerCountToday;
      sessionStorage["readMarkerCountPrevious"]=value.readMarkerCountPrevious??value.readMarkerCountPrevious;
    return;
    }
    const newNotification = value;
     this.updateUpdateNHistory(newNotification);
    if (value.messageType != 'Chat' && value.messageType != 'MatchedWorker') {
     const notification=value
     this.responseService.segregateData(notification)
    }
    // console.log(value)
    // const matchingSPListService = new MatchingSPListService()
    // var iuClass : UIClass
    // iuClass =  DecideClassService.decideUI(MatchingSPListService)
    // iuClass.updateMessage(value)
    // this.updateSPList(value)
    if (value.messageType == 'Chat') {
      this.updateReceivedMessage(value);
    } else if (value.messageType == 'PushNotification') {
      this.updatePushNotification(value.value);
    } else if (value.messageType == 'HireRequest') {
      if (
        this.responseService.ifExistHiredSPDetails(sessionStorage['loginId'])
      ) {
        // let obj = {
        //   serviceproviderId: sessionStorage['loginId'],
        // };
        // this.http
        //   .put<any>(AppConstants.api_based_url + '/hired/getAllBySP', obj)
        //   .pipe(
        //     map((data) => {
        //       this.responseService.setHiredSPDetails(
        //         obj.serviceproviderId,
        //         data
        //       );
        //       return;
        //     })
        //   );
      }
      this.updatePushNotification(value);
    } else if (value.messageType == 'MatchedCustomer') {
      this.updatePushNotification(value);
    }
    // else if (value.messageType == 'AcceptHire') {
    //   this.updatePushNotification(value);
    //   // var ary = this.responseService.getJobsPostedDataList()
    //   // if(ary != null && ary != undefined){
    //   //       var jobId = value.value.jobId
    //   //       let index = ary.findIndex(obj => obj.jobId == jobId);
    //   //       ary[index].status  = 'Accepted'
    //   // }
    //   // if (this.responseService.isExistJobPostedDataList) {
    //   //   this.responseService.updateJobsPostedData(value.value.jobId,'Accepted','Accepted');
    //   // }
    //   if (this.responseService.isExistJobsPostedData()) {
    //     this.responseService.updateJobsPostedDataStatus( value.value.jobId,'Accepted', 'Hired');
    //   }
    //   if (this.responseService.isExistMatchingJobberList(value.value.jobId)) {
    //     this.responseService.updateHiredMJList(
    //       value.value.jobId,
    //       value.value.serviceproviderId,
    //       'Hired',
    //       'Accepted',
    //       2
    //     );
    //   }
    //   if (this.responseService.isExistJobHireDataList(value.value.jobId)) {
    //     var array = this.responseService.updateJobHireDataList(
    //       value.value.jobId,
    //       value.value.serviceproviderId,
    //       'Accepted',
    //       2
    //     );
    //     this.service.updateHireUpdatedData(array);
    //   }
    // }

    // else if (value.messageType == 'RejectHire') {
    //   this.updatePushNotification(value);
    //   if (this.responseService.isExistJobPostedDataList) {
    //     this.responseService.updateJobsPostedData(
    //       value.value.jobId,
    //       'Rejected',
    //       'Open'
    //     );
    //   }
    // }
    else if (value.messageType == 'NewServiceProviderRegistration') {
      this.updatePushNotification(value);
    } else if (value.messageType == 'PaymentRequest') {
      this.updatePushNotification(value);
    }
    // need to think on this part how to identify the flow for each message type
    //else if(messageType != null){
    //       this.updatePushNotification(value);
    //}
    else if (value.messageType == 'MatchedWorker') {-
      this.updateSPList(value);
    } else if (value.messageType == 'Chat') {
      this.updateReceivedMessage(value);
    }
  }
  //Bhabani 25/01/2024
  generateOtp(phoneNumber): Observable<any> {
    return this.http
      .post<any>(
        AppConstants.api_based_url +
          '/userAccount/generate?phoneNumber=' +
          phoneNumber,
        phoneNumber
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  verifyOtp(
    phoneNumber,
    otpCode,
    sid,
    userTypeId,
    friendReferralCode
  ): Observable<any> {
    return this.http
      .post<any>(
        AppConstants.api_based_url +
          '/userAccount/verify?phoneNumber=' +
          phoneNumber +
          '&otpCode=' +
          otpCode +
          '&serviceSid=' +
          sid +
          '&userTypeId=' +
          userTypeId +
          '&friendReferralCode=' +
          friendReferralCode,
        { phoneNumber, otpCode, sid, userTypeId, friendReferralCode }
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  //Bhabani 09/03/2024
  async getNotifications(
    userId,
    pageNumber,
    pageSize,
    messageType,
    isRead,
    requestNotification
  ): Promise<any> {
    let checkCondition = false
    let dataLength = this.responseService.getNotifications(pageNumber, pageSize, messageType, isRead, requestNotification).length
    if ( dataLength == pageSize) {
      let data = this.responseService.getNotifications(pageNumber, pageSize, messageType, isRead, requestNotification);
      checkCondition = data.some(item => item.isRead !== isRead);
      if(!checkCondition) {
        return data
      }
    }
    if(checkCondition || dataLength != pageSize ) {
      checkCondition = false
      let deleteDate = this.responseService.getKeys(pageNumber, pageSize, messageType, isRead, requestNotification)
      deleteDate.forEach(item => {
        this.responseService.deleteNotification(item)
      });
      try {
        const response = await lastValueFrom(
          this.http.get<any>(
            AppConstants.api_based_url +
              `/notification/list/${userId}?pageNumber=${pageNumber}&pageSize=${pageSize}&uiMessageType=${messageType}&isRead=${isRead}&requestNotification=${requestNotification}`
          )
        );
        response.data.forEach(item => {
          this.responseService.segregateData(item)
        });
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    }
  }

  //Bhabani 22/03/2024
  updateIsSent(uniqueIdentifier) {
    return this.http
      .put<any>(
        AppConstants.api_based_url +
          `/ui-response-data/update/delivery-marker/${uniqueIdentifier}`,
        uniqueIdentifier
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  updateReadMarker(uniqueIdentifier) {
    return this.http
      .put<any>(
        AppConstants.api_based_url +
          `/ui-response-data/update/read-marker/${uniqueIdentifier}`,
        uniqueIdentifier
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  // mohan (25/04/24)
  segrigateMsgAck(message: any) {
    this.messageReceived.next(message);
  }

  private jobberExistProfile = new Subject<any>();
  saveJobberExistProfile = this.jobberExistProfile.asObservable();

  updateJobberExistProfile(jobberData) {
    this.jobberExistProfile.next(jobberData);
  }
  private jobberExistProfileOne = new Subject<any>();
  saveJobberExistProfileOne = this.jobberExistProfileOne.asObservable();

  updateJobberExistProfileOne(jobberData) {
    this.jobberExistProfileOne.next(jobberData);
  }
  private jobberExistProfileTwo = new Subject<any>();
  saveJobberExistProfileTwo = this.jobberExistProfileTwo.asObservable();

  updateJobberExistProfileTwo(jobberData) {
    this.jobberExistProfileTwo.next(jobberData);
  }

  private updateSaveImageStatic = new Subject<any>();
  castUpdateSaveImageStatic = this.updateSaveImageStatic.asObservable();

  updateSaveImageStaticFunc(imageData) {
    this.updateSaveImageStatic.next(imageData);
  }
}
