<div class="dialog-container">
  <h2 class="dialog-title" mat-dialog-title >{{ message.title }}</h2>
  <div class="dialog-content" mat-dialog-content>
    <p>{{message.content}}</p>
  </div>
  
  <div class="flex flex-column gap-7" *ngIf="message.content == 'Do you want to Cancel the Job ?'">
    <div *ngFor="let reasons of cancelReasons" class="field-checkbox" id="reasons">
        <p-radioButton 
            [inputId]="reasons.key"
            name="reasons" 
            [value]="reasons" 
            [(ngModel)]="selectedReason" />
        <label [for]="reasons.key" class="ml-2">
            {{ reasons.value }}
        </label>
    </div>
</div>
<div *ngIf="message.content == 'Do you want to Cancel the Job ?'">
<textarea  row="3" cols="40" pInputTextArea></textarea>>
</div>
  <div class="dialog-actions" mat-dialog-actions >
    <button class="dialog-button dialog-button-secondary" (click)="onNo()">No</button>
    <button class="dialog-button dialog-button-primary" (click)="onYes()">Yes</button>
  </div>
</div>