import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MainServiceService } from 'src/app/Services/main-service.service';
import { ServiceService } from 'src/app/jobposter-module/service.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent {
  constructor(
    private serviceService: ServiceService,
    private mainServiceService: MainServiceService,
    private router: Router
  ) {}
  categoryData: any;
  serviceData: any;
  selectedCategory: any;
  categoryName: any;

  ngOnInit() {
    this.getCatogory();
  }
  getCatogory() {
    this.serviceService.getCategory().subscribe((data) => {
      this.categoryData = data;
      if (this.categoryData && this.categoryData.length > 0) {
        this.selectedCategory = this.categoryData[0];
        this.categoryName = this.selectedCategory;
        this.getServiceByCatId();
      }
    });
  }

  setSelectedCategoryAndGetServices(category: any) {
    this.selectedCategory = category;
    this.categoryName = category;
    this.getServiceByCatId();
  }

  getServiceByCatId() {
    if (this.selectedCategory && this.selectedCategory.id) {
      this.serviceData = this.categoryData.find(
        (obj) => obj.id === this.selectedCategory.id
      ).services;
      console.log(this.serviceData);
    } else {
    }
  }

  sendToPostJob(service) {
    console.log(service);
    this.mainServiceService.sendingSearchingData(this.categoryName);
    this.mainServiceService.setServiceName(service);
    this.router.navigate(['acejobber/post-job']);
  }
}
