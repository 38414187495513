import { Component } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-landing-page-two',
  templateUrl: './landing-page-two.component.html',
  styleUrls: ['./landing-page-two.component.scss'],
  // standalone:true,
  // imports:[]
})
export class LandingPageTwoComponent {

  constructor(private router:Router){}
  jobberLogin(){
    this.router.navigate(['acejobber/login/login-page'])
  }
  warning() {
    alert("its not implemented.....") 
  }
}
