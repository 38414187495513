export class RCHire {

    id : Number;
	 hirestatusId : Number;
   reasonId : Number;
 hireId : Number;
   customerId : Number;
    serviceproviderId : Number;
   
}
