import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { AppConstants } from '../appConstants';
import { EMPTY, map, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ServiceFileService } from '../jobber-module/services/service-file.service';

@Injectable({
  providedIn: 'root',
})
export class ResponseServiceService {
  pendingMsgsRequest: any;
  constructor(private http: HttpClient) {}

  private jobsPostedDataList;
  private matchingJobberList: Map<number, any[]> = new Map<number, any[]>();
  private categoryList;
  private locationsList;
  private userDetails;
  private userSPDetails;
  private userHSPDetails;
  private jobHireDataList: Map<number, any[]> = new Map<number, any[]>();
  private servicesRates: Map<number, any[]> = new Map<number, any[]>();
  private paymentsData: Map<number, any[]> = new Map<number, any[]>();
  private invoicePayables;
  private paymentHistory;
  private rewards;
  private useableAdvanceAmount;
  private isUserExist: boolean = false;
  private userAccountDetails;
  private dashboardData;
  private openJobs;
  private activeJobs;
  private rejectedJobs;
  private upcomingJobs;
  private completedJobs;
  private myJobsDataList: Map<number, any[]> = new Map<number, any[]>();
  private serviceProviderHireList;
  private serviceProviderAwaitingDataList;
  private serviceProviderUpcomingDataList;
  private uniqueCities;
  private customerData;
  private customersList;
  private customerChat;
  private reasonsForRejectOrcancel;
  private reviewCount;
  private serviceProviderPassedDataList;
  private spProfile;
  private SPChat;
  private documentDetails;
  private getAllDocuments;
  private image: Map<String, any[]> = new Map<string, any[]>();
  private images: Map<String, any[]> = new Map<string, any[]>();
  private jobsPostedData: Map<number, any[]> = new Map<number, any[]>();
  private jobberExistingProfile;
  private jobberPayments: Map<number, any[]> = new Map<number, any[]>();
  private jobberPaymentHistory: Map<number, any[]> = new Map<number, any[]>();
  private JobberOpenJobs: Map<number, any[]> = new Map<number, any[]>();
  private JobberActiveJobs: Map<number, any[]> = new Map<number, any[]>();
  private JobberUpcomingJobs: Map<number, any[]> = new Map<number, any[]>();
  private JobberCompletedJobs: Map<number, any[]> = new Map<number, any[]>();
  private JobberCancelledJobs: Map<number, any[]> = new Map<number, any[]>();
  private cache: { [key: string]: any } = {};
  openJobsKey = '1_10';
  dashboardMapKey = '4-1';
  postedJobMapKey = '10-1';
  jobberPaymentHistoryKey = '1_5';
  paymentHistoryMapKey = '1_3_Fully Paid';
  customerOpenJobsData = '10-1_1';
  customerUpcomingJobsMapKey = '10-1_0';
  customerActiveJobsMapKey = '10-1_2';
  customerCompletedJobsData = '10-1_5';
  customerCancelledJobsData = '10-1_9';

  //================================================
  //Bhabani 17-jul-2024
  // Method to check if data exists in cache
  hasData(key: string): boolean {
    return !!this.cache[key];
  }

  // Method to get data from cache
  getData(key: string): any {
    return this.cache[key];
  }

// Method to set data in cache
setData(key: string, data: any): void {
  if (data.data && data.data.length > 0) {
    this.cache[key] = data;
  } else {
    // Remove the key if there's no data
    delete this.cache[key];
  }
}

notificationSetData(key: string, data: any): void {
  if (key!=null && data!=null) {
    this.cache[key] = data;
  } else {
    delete this.cache[key];
  }
}

// Method to insert data while ensuring uniqueness
insertUniqueNotification(key: string, notification: any): void {
  if (!notification) return; // Handle null notification
  let cachedData = this.getData(key);
  // Check if notification already exists
  if (notification.callBackId && !cachedData.data.some(n => n.callBackId === notification.callBackId)) {
    cachedData.data.push(notification);
    this.setData(key, cachedData);
  }
}




  private notificationCounts: { [key: string]: number } = {
    'notifications_todayUnRead': 1,
    'notifications_todayRead': 1,
    'notifications_previousUnRead': 1,
    'notifications_previousRead': 1
  };

  segregateData(response: any): void {
    if (!response) return;

    const notification = response;
    const today = new Date();
    const notificationDate = new Date(notification.createdAt);
    const isToday = (
        notificationDate.getDate() === today.getDate() &&
        notificationDate.getMonth() === today.getMonth() &&
        notificationDate.getFullYear() === today.getFullYear()
    );

    const requestNotification = isToday ? 'Today' : 'Previous';
    
    let keyBase = `notifications_${requestNotification}_${notification.isRead ? 'true' : 'false'}`;
    
    if (this.notificationCounts[keyBase] === undefined) {
      this.notificationCounts[keyBase] = 1;
    } else {
      this.notificationCounts[keyBase]++;
    }
    
    let key = `${keyBase}_${this.notificationCounts[keyBase]}`;
    this.notificationSetData(key, notification);
  }

  getNotifications(pageNumber: number, pageSize: number, uiMessageType: string | null, isRead: boolean, requestNotification: string): any[] {
    let returnData: any[] = [];
    const pageStart = pageNumber * pageSize + 1;
    const pageEnd = (pageNumber + 1) * pageSize;
    if(uiMessageType != null ) {
      returnData = this.getDataBasedOnUiMessageType(pageNumber, pageSize, uiMessageType, isRead, requestNotification);
      return returnData;
    }
    for (let i = pageStart; i <= pageEnd; i++) {
      let keyBase = `notifications_${requestNotification}_${isRead}`;
      let key = `${keyBase}_${i}`;
      if (this.hasData(key)) {
        returnData = returnData.concat(this.getData(key));
      }
    }
    return returnData;
  }


  getDataBasedOnUiMessageType(pageNumber: number, pageSize: number, uiMessageType: string | null, isRead: boolean, requestNotification: string): any[] {
    let returnData: any[] = [];
    const pageStart = pageNumber * pageSize;
    const pageEnd = (pageNumber + 1) * pageSize;
    let num = 1;
    let collectedData: any[] = [];
    
    while (collectedData.length < pageEnd && num <= 10000) { 
        let keyBase = `notifications_${requestNotification}_${isRead}`;
        let key = `${keyBase}_${num}`;
        let data = this.getData(key);
        if (data) {
            if (data.messageType === uiMessageType) {
                collectedData.push(data);
            }
        }
        
        num++;
    }
    returnData = collectedData.slice(pageStart, pageEnd);
    return returnData;
}


  getKeys(pageNumber: number, pageSize: number, uiMessageType: string | null, isRead: boolean, requestNotification: string): any[] {
    let keyData: any[] = [];
    const pageStart = pageNumber * pageSize + 1;
    const pageEnd = (pageNumber + 1) * pageSize;
    
    for (let i = pageStart; i <= pageEnd; i++) {
      let keyBase = `notifications_${requestNotification}_${isRead}`;
      let key = `${keyBase}_${i}`;
      keyData = keyData.concat(key);
    }
    return keyData;
  }



  deleteNotification(key) {
    if(this.hasData(key)) {
      delete this.cache[key];
      this.updateDataKey(key);
    }
  }

  updateData(obj: any): void {
    const { pageNumber, pageSize, isRead, requestNotification, callbackId } = obj;
    const pageStart = pageNumber * pageSize + 1;
    const pageEnd = (pageNumber + 1) * pageSize;
    
    for (let i = pageStart; i <= pageEnd; i++) {
      const key = `notifications_${requestNotification}_${isRead}_${i}`;
      
      if (this.hasData(key)) {
        const data = this.getData(key);
        if (data.callBackId === callbackId && !data.isRead) {
          data.isRead = true
          this.updateDataKey(key);
          this.MoveDataKey(data)
        }
      }
    }
  }
 
  updateDataKey(key: string) {
    if(key != null) {
      const parts = key.split('_');
      const keyBase = parts.slice(0, -1).join('_'); 
      let number = parseFloat(parts[parts.length - 1]);
      while(this.hasData(`${keyBase}_${number}`)) {
        try {
          let checkKey = `${keyBase}_${number+1}`
          let movekey = `${keyBase}_${number}`
          let data = this.getData(checkKey)
          this.notificationSetData(movekey, data)
          number++;
        } catch (error) {
          break;
        }
      }
    }
  }

  MoveDataKey(data: any) {
    if(data != null) {
      const today = new Date();
      const notificationDate = new Date(data.createdAt);
      const isToday = (
          notificationDate.getDate() === today.getDate() &&
          notificationDate.getMonth() === today.getMonth() &&
          notificationDate.getFullYear() === today.getFullYear()
      );
      const requestNotification = isToday ? 'Today' : 'Previous';

      let keyBase = `notifications_${requestNotification}_${data.isRead ? 'true' : 'false'}`;
      let number = 1;
      let saveData = data
      let moveData = data

      while(this.hasData(`${keyBase}_${number}`)) {
        try {
          let checkKey = `${keyBase}_${number}`
          moveData = this.getData(checkKey)
          this.notificationSetData(checkKey, saveData)
          saveData = moveData
          number++;
        } catch (error) {
          break;
        }
      }
    }
  }



  //========================================
  setJobsPostedDataList(value) {
    this.jobsPostedDataList = value;
  }
  getJobsPostedDataList() {
    return this.jobsPostedDataList;
  }
  updateJobsPostedDataStatus(jobId, status, jobStatus) {
    let index = this.jobsPostedDataList?.findIndex((obj) => obj.jobId == jobId);
    if (index != -1) {
      this.jobsPostedDataList[index].status = status;
      this.jobsPostedDataList[index].jobStatus = jobStatus;
    }
  }
  setPendingMsgsRequest(data){
    this.pendingMsgsRequest =data ;
  }
  
  isRequestSent(){
    return this.pendingMsgsRequest != undefined && this.pendingMsgsRequest != null;
  }
  setCustomerChat(data){
    this.customerChat=data;
  }
  getCustomerChat(){
    return this.customerChat 
  }
  isExistCustomerChat() {
    return this.customerChat != undefined && this.customerChat != null;
  }
  setSPChat(data:any){
    this.SPChat=data;
  }
  getSPChat(){
    return this.SPChat 
  }
  isExistSPChat() {
    return this.SPChat != undefined && this.SPChat != null;
  }

  groupedMessages={} // for jobber
   setGroupedMessagesUsers(combinationIds:any,messages:any){
    this.groupedMessages[combinationIds]=messages;
   }

   getGroupedMessagesUsers(combinationIds:any):any{
      if(this.groupedMessages.hasOwnProperty(combinationIds)){
         return of(this.groupedMessages[combinationIds]);
      }
     else{
       return EMPTY;
      }
    }
   updateGroupedMessages(combinationIds:any,messages:any) {
    this.groupedMessages[combinationIds]=messages;
   }

   groupedMessagess={} // for customer
   setGroupedMessagesUserss(combinationIds:any,messages:any){
    this.groupedMessagess[combinationIds]=messages;
   }

   getGroupedMessagesUserss(combinationIds:any):any{
      if(this.groupedMessagess.hasOwnProperty(combinationIds)){
         return of(this.groupedMessagess[combinationIds]);
      }
     else{
       return EMPTY;
      }
    }
   updateGroupedMessagess(combinationIds:any,messages:any) {
    this.groupedMessagess[combinationIds]=messages;
   }
   
   checkedUserIdJP={}
   setCheckedForUsersChatHistoryJP(selectedId:any){
   this.checkedUserIdJP[selectedId]=true
   }
   isCheckedForUsersChatHistoryJP(selectedId:any):boolean{
     return this.checkedUserIdJP.hasOwnProperty(selectedId) && this.checkedUserIdJP[selectedId] === true;
   }
   
   checkedUserIdSP={}
   setCheckedForUsersChatHistorySP(selectedId:any){
   this.checkedUserIdSP[selectedId]=true
   }
   isCheckedForUsersChatHistorySP(selectedId:any):boolean{
     return this.checkedUserIdSP.hasOwnProperty(selectedId) && this.checkedUserIdSP[selectedId] === true;
   }
// =====================================================================

  // addRowJobsPostedData(obj) {
  //   if (this.jobsPostedDataList != null && this.jobsPostedDataList != undefined) {
  //     this.jobsPostedDataList.unshift(obj);
  //   } else {
  //     this.jobsPostedDataList = [];
  //     this.jobsPostedDataList.push(obj);
  //   }
  // }
  addRowJobsPostedData(obj) {
    this.jobsPostedDataList = this.jobsPostedDataList || [];
    this.jobsPostedDataList?.unshift(obj);
  }

  isExistJobsPostedData() {
    return (
      this.jobsPostedDataList != undefined && this.jobsPostedDataList != null
    );
  }
  //==========================================
  setMatchingJobberList(jobId, list) {
    this.matchingJobberList.set(jobId, list);
  }
  getMatchingJobberList(jobId) {
    return this.matchingJobberList.get(jobId);
  }
  isExistMatchingJobberList(jobId) {
    const value = this.matchingJobberList?.get(jobId);
    if (value?.length <= 0) {
      return false;
    } else {
      return this.matchingJobberList.has(jobId);
    }
  }
  updateHiredMJList(
    jobId,
    serviceproviderId,
    jobcase,
    hireStatus,
    hireStatusId
  ) {
    var aray = this.matchingJobberList.get(jobId);
    var spObj = aray?.find((x) => x.spUserAccountId == serviceproviderId);
    if (spObj) {
      spObj.case = jobcase;
      spObj.hireStatus = hireStatus;
      spObj.hireStatusId = hireStatusId;
    }
    if (jobcase == 'Hired') {
      var index = aray?.findIndex((task) => task.hireStatus == 'Awaiting');
      if (index != -1) {
        aray[index].hireStatus = 'Closed';
      }
    } else if (jobcase == 'Accepted') {
      var index = aray?.findIndex((task) => task.hireStatus == 'Closed');
      if (index != -1) {
        aray[index].hireStatus = 'Awaiting';
      }
    }
  }
  //==================================================
  setCategoryList(value) {
    this.categoryList = value;
  }
  getCategoryList() {
    return this.categoryList;
  }
  isExistCatgeoryList() {
    return this.categoryList != undefined && this.categoryList != null;
  }
  //======================================
  setLocationsList(value) {
    this.locationsList = value;
  }
  getLocationList() {
    return this.locationsList;
  }
  isExistLocationsList() {
    return this.locationsList != undefined && this.locationsList != null;
  }
  addRowLocationList(obj) {
    var newObject = JSON.parse(JSON.stringify(obj));
    if (this.locationsList != null && this.locationsList != undefined) {
      this.locationsList?.unshift(newObject);
    } else {
      this.locationsList = [];
      this.locationsList?.push(newObject);
    }
  }
  deleteRowLocation(value) {
    var index = this.locationsList?.findIndex((obj) => obj.id == value.id);
    this.locationsList?.splice(index, 1);
  }
  editRowLocation(value) {
    var index = this.locationsList?.findIndex((obj) => obj.id == value.id);
    this.locationsList[index] = value;
  }
  //===========================================
  setUserDetails(value) {
    this.userDetails = value;
  }
  getUserDetails() {
    return this.userDetails;
  }
  ifExistUserDetails() {
    return this.userDetails != undefined && this.userDetails != null;
  }
  //==============================================
  // Bhabani 17-04-2024
  setUserSPDetails(value) {
    this.userSPDetails = value;
  }
  getUserSPDetails() {
    return this.userSPDetails;
  }
  ifExistUserSPDetails() {
    return this.userSPDetails != undefined && this.userSPDetails != null;
  }
  //==============================================
  hiredDates: Map<number, Object> = new Map<number, Object>();
  setHiredSPDetails(id, value) {
    this.hiredDates.set(id, value);
  }
  getHiredSPDetails(id) {
    return this.hiredDates.get(id);
  }
  ifExistHiredSPDetails(id) {
    return this.hiredDates.has(id);
  }
  updateHiredSPDetails(jobId, value, status) {
    let index = this.userHSPDetails?.findIndex((obj) => obj.hire.jobId == jobId);
    const startDate = new Date(this.userHSPDetails[index].hire.startDate);
    const currentDate = new Date();
    if (value == 'StartedFromLocation') {
      this.userHSPDetails[index].hire.isStartFromLocation = status;
    } else if (value == 'StartWork') {
      this.userHSPDetails[index].hire.isWorkStarted = status;
    } else if (value == 'Accepted') {
      this.userHSPDetails[index].hire.hireStatus = value;
      this.userHSPDetails[index].hire.hirestatusId = 2;
      this.userHSPDetails[index].hire.isAccepted = true;
      if (startDate == currentDate) {
        this.userHSPDetails[index].hire.caseJobStatus = 2;
      } else {
        this.userHSPDetails[index].hire.caseJobStatus = 3;
      }
    } else if (value == 'Rejected') {
      this.userHSPDetails[index].hire.hireStatus = value;
      this.userHSPDetails[index].hire.hirestatusId = 3;
      this.userHSPDetails[index].hire.caseJobStatus = 5;
    } else if (value == 'Rescheduled') {
      this.userHSPDetails[index].hire.hireStatus = value;
      this.userHSPDetails[index].hire.hirestatusId = 7;
      if (startDate == currentDate) {
        this.userHSPDetails[index].hire.caseJobStatus = 2;
      } else {
        this.userHSPDetails[index].hire.caseJobStatus = 3;
      }
    }
    return this.userHSPDetails;
  }
  //==============================================   hireStatus
  setJobHireDataList(jobId, hireData) {
    this.jobHireDataList.set(jobId, hireData);
  }
  getJobHireDataList(jobId) {
    return this.jobHireDataList.get(jobId);
  }
  isExistJobHireDataList(jobId) {
    let value = this.jobHireDataList?.get(jobId);
    if (value?.length <= 0) {
      return false;
    } else {
      return this.jobHireDataList.has(jobId);
    }
  }

  // addNewHireData(jobId ,data) {
  //   let existHireData = this.getJobHireDataList(jobId);
  //   if (existHireData != undefined || existHireData != null) {
  //    existHireData.push(data);
  //    this.jobHireDataList.set(jobId , existHireData);
  //   } else {
  //     this.setJobHireDataList(jobId , data);
  //   }
  // }
  addNewHireData(jobId, data) {
    let existHireData = this.getJobHireDataList(jobId);
    existHireData = existHireData || [];
    existHireData?.push(data);
    this.jobHireDataList?.set(jobId, existHireData);
  }
  updateJobHireDataList(jobId, serviceproviderId, hireStatus, hirestatusId) {
    var arry = this.jobHireDataList?.get(jobId);
    var index = arry?.findIndex(
      (x) => x.hire.serviceproviderId == serviceproviderId
    );
    if (index != -1) {
      arry[index].hire.status = hireStatus;
      arry[index].hire.hirestatusId = hirestatusId;
    }
    if (hireStatus == 'Accepted') {
      var index = arry?.findIndex((x) => x.hire.status == 'Awaiting');
      if (index != -1) {
        arry[index].hire.status = 'Closed';
      }
    }
    if (hireStatus == 'Cancelled') {
      var ind = arry?.findIndex((task) => task.hire.status == 'Closed');
      if (ind != -1) {
        arry[ind].hire.status = 'Awaiting';
      }
    }
    return arry;
  }
  //=======================================
  setServicesRates(spId, value) {
    this.servicesRates.set(spId, value);
  }
  getServicesRates(spId) {
    return this.servicesRates.get(spId);
  }
  isExistServicesRates(spId) {
    const value = this.servicesRates?.get(spId);
    if (value?.length <= 0) {
      return false;
    } else {
      return this.servicesRates.has(spId);
    }
  }
  //======================================
  getInvoicePayables() {
    return this.invoicePayables;
  }
  setInvoicePayables(pageNumber, value) {
    this.invoicePayables.set(pageNumber, value);
  }
  isInvoicePayables() {
    return this.invoicePayables != undefined && this.invoicePayables != null;
  }
  updateTaskAndInvoiceStatus(invoiceObj, data) {
    let index = this.invoicePayables?.findIndex(
      (obj) => obj.invoiceInf.invoiceId == invoiceObj?.invoice?.id
    );
    if (index != -1) {
      this.invoicePayables[index].invoiceInf.status = data?.body;
      for (let i = 0; i < this.invoicePayables[index].taskInfList.length; i++) {
        for (let selectTask of invoiceObj.taskList) {
          if (this.invoicePayables[index].taskInfList[i].id == selectTask.id) {
            this.invoicePayables[index].taskInfList[i].status =
              'Finished and Received Payment';
          }
        }
      }
      if (data.body == 'Fully Paid') {
        this.deleteRowInvoicePayables(index);
      }
    }
  }
  deleteRowInvoicePayables(index) {
    var invoice = this.invoicePayables[index];
    this.invoicePayables?.splice(index, 1);
    this.addRowPaymentHistory(invoice);
  }
  //========================================
  isExistPaymentHistory() {
    return this.paymentHistory != undefined && this.paymentHistory != null;
  }
  setPaymentHistory(pageNumber, value) {
    this.paymentHistory.set(pageNumber, value);
  }
  getPaymentHistory() {
    return this.paymentHistory;
  }
  addRowPaymentHistory(obj) {
    this.paymentHistory.unshift(obj);
  }
  //==============================================
  isExistJobberPayments(key) {
    const value = this.jobberPayments?.get(key);
    if (value?.length <= 0) {
      return false;
    } else {
      return this.jobberPayments.has(key);
    }
  }
  setjobbersPayments(key, invoices) {
    this.jobberPayments.set(key, invoices);
  }
  getJobbersPayments(key) {
    return this.jobberPayments.get(key);
  }
  isExistJobberPaymentHistory(key) {
    const value = this.jobberPaymentHistory?.get(key);
    if (value?.length <= 0) {
      return false;
    } else {
      return this.jobberPaymentHistory.has(key);
    }
  }
  setJobberPaymentHistory(key, invoices) {
    this.jobberPaymentHistory.set(key, invoices);
  }
  getJobberPaymentHistory(key) {
    return this.jobberPaymentHistory.get(key);
  }
  updatejobbersPaymentHistory(jobId, status) {
    if (status == 'Fully Paid') {
      for (const [key, invoices] of this.jobberPayments.entries()) {
        const index = invoices?.findIndex(
          (invoice) => invoice.invoiceInf.jobId == jobId
        );
        if (index != -1) {
          if (this.jobberPaymentHistory != (null || undefined)) {
            if (invoices[index] != null) {
              invoices[index]?.taskInfList?.forEach((task) => {
                task.status = 'Finished and Received Payment';
              });
            }
            let jobberPaymentHistoryData = this.getJobberPaymentHistory(
              this.jobberPaymentHistoryKey
            );
            if (jobberPaymentHistoryData != undefined) {
              jobberPaymentHistoryData?.unshift(invoices[index]);
              this.jobberPaymentHistory?.set(
                parseInt(this.jobberPaymentHistoryKey),
                jobberPaymentHistoryData
              );
              invoices?.splice(index, 1);
            } else {
              this.jobberPaymentHistory?.set(
                parseInt(this.jobberPaymentHistoryKey),
                invoices[index]
              );
              invoices.splice(index, 1);
            }
          } else {
            invoices.splice(index, 1);
          }
        }
      }
    } else if (status == 'Partially Paid') {
      if (this.jobberPayments != null || this.jobberPayments != undefined) {
        for (const [key, invoices] of this.jobberPayments.entries()) {
          const invoice = invoices.find(
            (invoice) => invoice.invoiceInf.jobId == jobId
          );
          if (invoice != null) {
            invoice.taskInfList.forEach((task) => {
              if (task.status == 'Finished and Awating for Payment') {
                task.status = 'Finished and Received Payment';
              }
            });
          }
        }
      }
    }
  }
  updateJobberPertialPayment(invoiceData, taskData) {
    if (this.jobberPayments != null || this.jobberPayments != undefined) {
      for (const [key, invoices] of this.jobberPayments.entries()) {
        const index = invoices?.findIndex(
          (invoice) => invoice.invoiceInf.jobId == invoiceData.jobId
        );
        if (index != -1) {
          invoices[index]?.taskInfList?.forEach((task) => {
            const taskIdMatches = taskData?.some(
              (taskItem) => taskItem.id === task.id
            );
            if (taskIdMatches) {
              task.status = 'Finished and Awating for Payment';
            }
          });
        }
      }
    }
  }
  isExistPaymentsData(pageNumber) {
    const value = this.paymentsData?.get(pageNumber);
    if (value?.length <= 0) {
      return false;
    } else {
      return this.paymentsData.has(pageNumber);
    }
  }
  setPaymentsData(pageNumber, value) {
    this.paymentsData.set(pageNumber, value);
  }
  partialPaymentUpdate(invoiceId, taskList, invoiceStatus) {
    for (const [key, invoices] of this.paymentsData.entries()) {
      const index = invoices?.findIndex(
        (invoice) => invoice.invoiceInf.invoiceId == invoiceId
      );
      if (index !== -1) {
        invoices[index]?.taskInfList?.forEach((task) => {
          const taskIdMatches = taskList?.some(
            (taskItem) => taskItem.id === task.id
          );
          if (taskIdMatches) {
            task.status = 'Finished and Received Payment';
          }
        });
        if (invoiceStatus == 'Fully Paid') {
          invoices[index].invoiceInf.status = invoiceStatus;
          const paymentHistory = this.getPaymentsData(
            this.paymentHistoryMapKey
          );
          if (paymentHistory != (null || undefined)) {
            paymentHistory?.unshift(invoices[index]);
            this.paymentsData?.set(
              parseInt(this.paymentHistoryMapKey),
              paymentHistory
            );
            invoices.splice(index, 1);
          } else {
            invoices.splice(index, 1);
          }
          break;
        }
      }
    }
  }
  getPaymentsData(pageNumber) {
    return this.paymentsData.get(pageNumber);
  }
  //=======================
  isExistRewards() {
    return this.rewards != undefined && this.rewards != null;
  }
  setRewards(value) {
    this.rewards = value;
  }
  getRewards() {
    return this.rewards;
  }
  //=======================================

  isExistUseableAdvanceAmount() {
    return (
      this.useableAdvanceAmount != undefined &&
      this.useableAdvanceAmount != null
    );
  }
  setUseableAdvanceAmount(value) {
    this.useableAdvanceAmount = value;
  }
  getUseableAdvanceAmount() {
    return this.useableAdvanceAmount;
  }
  //======================================
  getIsUserExist() {
    return this.isUserExist;
  }
  setIsUserExist(value) {
    this.isUserExist = value;
  }
  //=====================================
  isExistUserAccountDetails() {
    return (
      this.userAccountDetails != undefined && this.userAccountDetails != null
    );
  }
  getUserAccountDetails() {
    return this.userAccountDetails;
  }
  setUserAccountDetails(value) {
    this.userAccountDetails = value;
  }
  //=======================================
  isExistDashboardData() {
    return this.dashboardData != undefined && this.dashboardData != null;
  }
  getDashboardData() {
    return this.dashboardData;
  }
  setDashboardData(value) {
    this.dashboardData = value;
  }
  //========================================
  isExistOpenJobs() {
    return this.openJobs != undefined && this.openJobs != null;
  }
  setOpenJobs(value) {
    this.openJobs = value;
  }
  getOpenJobs() {
    return this.openJobs;
  }
  //============================
  isExistActiveJobs() {
    return this.activeJobs != undefined && this.activeJobs != null;
  }
  setActiveJobs(value) {
    this.activeJobs = value;
  }
  getActiveJobs() {
    return this.activeJobs;
  }
  //============================
  isExistRejectedJobs() {
    return this.rejectedJobs != undefined && this.rejectedJobs != null;
  }
  setRejectedJobs(value) {
    this.rejectedJobs = value;
  }
  getRejectedJobs() {
    return this.rejectedJobs;
  }
  //============================
  isExistUpcomingJobs() {
    return this.upcomingJobs != undefined && this.upcomingJobs != null;
  }
  setUpcomingJobs(value) {
    this.upcomingJobs = value;
  }
  getUpcomingJobs() {
    return this.upcomingJobs;
  }
  //============================
  isExistCompletedJobs() {
    return this.completedJobs != undefined && this.completedJobs != null;
  }
  setCompletedJobs(value) {
    this.completedJobs = value;
  }
  getCompletedJobs() {
    return this.completedJobs;
  }
  //============================
  isExistMyJobsDataList(pageNumber) {
    const value = this.myJobsDataList?.get(pageNumber);
    if (value?.length <= 0) {
      return false;
    } else {
      return this.myJobsDataList.has(pageNumber);
    }
  }
  setMyJobsDataList(pageNumber, value) {
    this.myJobsDataList.set(pageNumber, value);
  }
  getMyJobsDataList(pageNumber) {
    return this.myJobsDataList.get(pageNumber);
  }
  // updateCustomerAwaitingJobs(data) {
  //   let jobDataArray = this.getMyJobsDataList('10-1_1');
  //   if (jobDataArray != null || jobDataArray != undefined) {
  //   const hasJobId = jobDataArray.some((item) => item.jobId == data[0]?.jobId);
  //   if (!hasJobId) {
  //     const newObj = this.setDataStructure(data);
  //     jobDataArray.unshift(newObj);
  //     this.myJobsDataList.set(parseInt(this.customerOpenJobsData), jobDataArray);
  //   }
  //  }
  // }
  updateCustomerAwaitingJobs(data) {
    let jobDataArray = this.getMyJobsDataList('10-1_1');
    if (jobDataArray != undefined) {
      const hasJobId = jobDataArray?.some(
        (item) => item.jobId === data[0]?.jobId
      );
      if (!hasJobId) {
        const newObj = this.setDataStructure(data);
        jobDataArray.unshift(newObj);
        this.myJobsDataList?.set(
          parseInt(this.customerOpenJobsData),
          jobDataArray
        );
      }
    }
  }
  setDataStructure(data) {
    const newObj = {
      categoryName: data[0]?.categoryName,
      imageId: null,
      isAccepted: null,
      isReviewed: null,
      isStartFromLocation: null,
      jobId: data[0]?.jobId,
      postedDate: data[0]?.postedDate,
      serviceIds: data[0]?.jobServiceIds,
      serviceName: data[0]?.servicesName,
      spFirstName: null,
      spLastName: null,
      startDate: null,
      startTime: null,
      status: data[0]?.jobStatus,
      totalPages: 0,
    };
    return newObj;
  }
  //===========================================
  //---------------------------------------------//
  //Gowthami 21-01-2024
  isExistServiceProviderHireData() {
    return (
      this.serviceProviderHireList != undefined &&
      this.serviceProviderHireList != null
    );
  }
  setServiceProviderHireData(data) {
    this.serviceProviderHireList = data;
  }
  getServiceProviderHireData() {
    return this.serviceProviderHireList;
  }
  isExistServiceProviderAwaitingData() {
    return (
      this.serviceProviderAwaitingDataList != undefined &&
      this.serviceProviderAwaitingDataList != null
    );
  }
  setServiceProviderAwaitingData(data) {
    this.serviceProviderAwaitingDataList = data;
  }
  getServiceProviderAwaitingData() {
    return this.serviceProviderAwaitingDataList;
  }
  isExistServiceProviderUpComingData() {
    return (
      this.serviceProviderUpcomingDataList != undefined &&
      this.serviceProviderUpcomingDataList != null
    );
  }
  setServiceProviderUpComingData(data) {
    this.serviceProviderUpcomingDataList = data;
  }
  getServiceProviderUpComingData() {
    return this.serviceProviderUpcomingDataList;
  }
  //24-02-2024
  isExistingUniqueCities() {
    return this.uniqueCities != undefined && this.uniqueCities != null;
  }
  setUniquecities(data) {
    this.uniqueCities = data;
    console.log(data);
  }
  getUniquecities() {
    return this.uniqueCities;
  }
  isExistCustomerData() {
    return this.customerData != undefined && this.customerData != null;
  }
  setCustomerData(data) {
    this.customerData = data;
  }
  getCustomerData() {
    return this.customerData;
  }
  isExistGetAllCustomers() {
    return this.customersList != undefined && this.customersList != null;
  }
  setGetAllCustomers(data) {
    this.customersList = data;
  }
  getGetAllCustomers() {
    return this.customersList;
  }
  isExistGetAllReasons() {
    return (
      this.reasonsForRejectOrcancel != undefined &&
      this.reasonsForRejectOrcancel != null
    );
  }
  setGetAllReasons(data) {
    this.reasonsForRejectOrcancel = data;
  }
  getGetAllReasons() {
    return this.reasonsForRejectOrcancel;
  }
  isExistgetReviewAverage() {
    return this.reviewCount != undefined && this.reviewCount != null;
  }
  setgetReviewAverage(data) {
    this.reviewCount = data;
  }
  getgetReviewAverage() {
    return this.reviewCount;
  }
  isExistServiceProviderPassedData() {
    return (
      this.serviceProviderPassedDataList != undefined &&
      this.serviceProviderPassedDataList != null
    );
  }
  setServiceProviderPassedData(data) {
    this.serviceProviderPassedDataList = data;
  }
  getServiceProviderPassedData() {
    return this.serviceProviderPassedDataList;
  }
  isExistGetProfileImage() {
    return this.spProfile != undefined && this.spProfile != null;
  }
  setGetProfileImage(data) {
    this.spProfile = data;
  }
  getGetProfileImage() {
    return this.spProfile;
  }
  isExistGetDocument() {
    return this.documentDetails != undefined && this.documentDetails != null;
  }
  setGetDocument(data) {
    this.documentDetails = data;
  }
  updateDocumentProfile(data) {
    this.documentDetails = data;
  }
  getGetDocument() {
    return this.documentDetails;
  }
  setJobPostedDataList(pageNumber, jobsData) {
    this.jobsPostedData.set(pageNumber, jobsData);
  }

  updateJobsPostedData(jobId, status, jobStatus, startDate, jobberStatus) {
    var filteredData = [];
    var newObj;
    for (const [key, jobs] of this.jobsPostedData.entries()) {
      const index = jobs?.findIndex((job) => job.jobId === jobId);
      if (index !== -1) {
        if (jobs[index] != (null || undefined)) {
          if (status === 'Rejected' || status === 'Cancelled') {
            jobs[index].status = 'Open';
          } else {
            jobs[index].status = status;
          }
          jobs[index].jobStatus = jobStatus;
          if (jobStatus == 'Accepted') {
            jobs[index].isAccepted = true;
          }
        }
        this.jobsPostedData?.set(key, jobs);
        filteredData = jobs?.filter((obj) => obj.jobId === jobId);
        newObj = this.setDataStructure(filteredData);
      }
    }
    if (jobStatus == 'Awaiting') {
      this.updateCustomerAwaitingJobs(filteredData);
    } else {
      for (const [key, jobs] of this.myJobsDataList.entries()) {
        const index = jobs?.findIndex((job) => job?.jobId === jobId);
        const formattedCurrentDate = `${new Date().toISOString().split('T')[0]}`;
        if (index !== -1) {
          if (status === 'Rejected' || status === 'Cancelled') {
            jobs[index].status = 'Open';
          } else {
            if (jobberStatus == 'jobberAccepted') jobs.splice(index, 1);
          }
          if (jobStatus == 'Accepted') {
            if (startDate > formattedCurrentDate) {
              const activeData = this.getMyJobsDataList(
                this.customerUpcomingJobsMapKey
              );
              if (activeData == undefined) {
                this.myJobsDataList?.set(
                  parseInt(this.customerUpcomingJobsMapKey),
                  newObj
                );
                break;
              } else {
                activeData.unshift(newObj);
                this.myJobsDataList?.set(
                  parseInt(this.customerUpcomingJobsMapKey),
                  activeData
                );
                break;
              }
            } else {
              const activeData = this.getMyJobsDataList(
                this.customerActiveJobsMapKey
              );
              if (activeData == undefined) {
                this.myJobsDataList?.set(
                  parseInt(this.customerActiveJobsMapKey),
                  newObj
                );
              } else {
                activeData.unshift(newObj);
                this.myJobsDataList?.set(
                  parseInt(this.customerActiveJobsMapKey),
                  activeData
                );
              }
            }
          } else if (jobStatus == 'Started From Location') {
            jobs[index].status = jobStatus;
          } else if (jobStatus == 'Work Started') {
            jobs[index].status = jobStatus;
          } else if (jobStatus == 'Awaiting For Payment') {
            jobs[index].status = jobStatus;
            break;
          } else if (jobStatus == 'Finished') {
            jobs.splice(index, 1);
            const finishedData = this.getMyJobsDataList(
              this.customerCompletedJobsData
            );
            if (finishedData != (undefined || null)) {
              finishedData.unshift(newObj);
              this.myJobsDataList?.set(
                parseInt(this.customerCompletedJobsData),
                finishedData
              );
            } else {
              this.myJobsDataList?.set(
                parseInt(this.customerCompletedJobsData),
                finishedData
              );
            }
          } else if (status == 'Cancelled') {
            jobs[index].status = 'Open';
            jobs[index].isAccepted = false;
            const cancelledData = this.getMyJobsDataList(
              this.customerOpenJobsData
            );
            cancelledData.unshift(newObj);
            this.myJobsDataList?.set(
              parseInt(this.customerOpenJobsData),
              cancelledData
            );
            jobs.splice(index, 1);
          }
        }
      }
      if (jobStatus == 'Awaiting For Payment') {
        if (this.paymentsData != (null || undefined)) {
          for (let [key, invoices] of this.paymentsData.entries()) {
            const hasJobId = invoices?.some(
              (invoice) => invoice.invoiceInf.jobId == jobId
            );
            if (!hasJobId) {
              const index = invoices?.findIndex(
                (invoice) => invoice.invoiceInf.jobId === jobId
              );
              invoices?.splice(index, 1);
              this.addNewInvoice(jobId);
              break;
            } else {
              const index = invoices?.findIndex(
                (invoice) => invoice.invoiceInf.jobId === jobId
              );
              invoices?.splice(index, 1);
              this.addNewInvoice(jobId);
              break;
            }
          }
        }
      }
    }
  }

  // updateJobsPostedData(jobId, status, jobStatus, startDate ,jobberStatus): void {
  //   const updateJob = (job: any, newStatus: string, newJobStatus: string, isNewJob: boolean = false): any => {
  //     job.status = newStatus;
  //     job.jobStatus = newJobStatus;
  //     if (newJobStatus === 'Accepted') {
  //       job.isAccepted = true;
  //     }
  //     return isNewJob ? this.setDataStructure([job]) : job;
  //   };
  //   for (const [key, jobs] of this.jobsPostedData.entries()) {
  //     const index = jobs.findIndex(job => job.jobId === jobId);
  //     if (index !== -1 && jobs[index]) {
  //       let newStatus = status;
  //       ['Rejected', 'Cancelled'].includes(status) && (newStatus = 'Open');
  //       this.jobsPostedData.set(key, updateJob(jobs[index], newStatus, jobStatus));
  //     }
  //   }
  //   for (const [key, jobs] of this.myJobsDataList.entries()) {
  //     const index = jobs.findIndex(job => job.jobId === jobId);
  //     if (index !== -1 && jobs[index]) {
  //       let newStatus = status;
  //       ['Rejected', 'Cancelled'].includes(status) && (newStatus = 'Open');
  //       const updatedJob = updateJob(jobs[index], newStatus, jobStatus);
  //       if (jobStatus === 'Accepted') {
  //         const activeData = this.getMyJobsDataList(this.customerUpcomingJobsMapKey);
  //         this.myJobsDataList.set(parseInt(this.customerUpcomingJobsMapKey), activeData ? [...activeData, updatedJob] : [updatedJob]);
  //       } else if (jobStatus === 'Finished') {
  //         jobs.splice(index, 1);
  //         const finishedData = this.getMyJobsDataList(this.customerCompletedJobsData);
  //         this.myJobsDataList.set(parseInt(this.customerCompletedJobsData), finishedData ? [...finishedData, updatedJob] : [updatedJob]);
  //       } else if (status === 'Cancelled') {
  //         jobs[index].isAccepted = false;
  //         const openJobsData = this.getMyJobsDataList(this.customerOpenJobsData);
  //         this.myJobsDataList.set(parseInt(this.customerOpenJobsData), openJobsData ? [...openJobsData, updatedJob] : [updatedJob]);
  //         jobs.splice(index, 1);
  //       }
  //     }
  //   }
  //   if (jobStatus === 'Awaiting For Payment' && this.paymentsData) {
  //     for (let [key, invoices] of this.paymentsData.entries()) {
  //       const hasJobId = invoices.some(invoice => invoice.invoiceInf.jobId === jobId);
  //       if (!hasJobId) {
  //         const index = invoices.findIndex(invoice => invoice.invoiceInf.jobId === jobId);
  //         invoices.splice(index, 1);
  //         this.addNewInvoice(jobId);
  //         break;
  //       }
  //     }
  //   }
  // }

  addNewInvoice(jobId) {
    var obj = {
      customerId: sessionStorage['loginId'],
      jobId: jobId,
      status: 'Partially Paid',
    };
    const url = `${AppConstants.api_based_url}/invoice/getByCustomerIdPayments`;
    const authToken = sessionStorage['token'];
    fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(obj),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.length == 1) {
          let newInvoice = this.getPaymentsData('1_3_Partially Paid');
          newInvoice.unshift(data[0]);
          this.setPaymentsData('1_3_Partially Paid', newInvoice);
        }
      });
  }
  updatePostedDataList(newData) {
    for (let [key, value] of this.jobsPostedData.entries()) {
      let jobDataArray = Array.isArray(value) ? value : [];
      if (key + '' == this.dashboardMapKey) {
        jobDataArray.unshift(newData);
        if (jobDataArray.length > 4) {
          jobDataArray.pop();
        }
      } else if (key + '' == this.postedJobMapKey) {
        jobDataArray.unshift(newData);
      } else {
        jobDataArray.push(newData);
      }
      this.jobsPostedData.set(key, jobDataArray);
    }
  }
  getJobPostedDataList(pageNumber) {
    return this.jobsPostedData.get(pageNumber);
  }

  isExistJobPostedDataList(pageNumber, jobId) {
    const value = this.jobsPostedData?.get(pageNumber);
    if (value?.length <= 0) {
      return false;
    } else {
      if (jobId.path) {
        let isExist;
        for (const [key, data] of this.jobsPostedData.entries()) {
          const index = data?.indexOf((task) => task.jobId == jobId.path);
          if (index != -1) {
            isExist = key;
            break;
          }
        }
        if (isExist) {
          pageNumber = isExist;
        } else {
          return false;
        }
      }
      return this.jobsPostedData.has(pageNumber);
    }
  }

  isExistImageByImageId(imageId) {
    const value = this.image?.get(imageId);
    if (value?.length <= 0) {
      return false;
    } else {
      return this.image.has(imageId);
    }
  }
  setImageByImageId(imageId, data) {
    this.image.set(imageId, data);
  }
  getImageByImageId(imageId) {
    return this.image.get(imageId);
  }
  isExistMultipleImagesByImageIds(imageId) {
    const value = this.images.get(imageId);
    if (value?.length <= 0) {
      return false;
    } else {
      return this.images.has(imageId);
    }
  }
  setMultipleImagesByImageId(imageId, data) {
    this.images.set(imageId, data);
  }
  getMultipleImagesByImageId(imageId) {
    return this.images.get(imageId);
  }
  updateImage(oldKey , newImageId , data) {
    const mergedData = { ...this.images?.get(oldKey) || {}, ...data };
    this.images?.set(newImageId, mergedData);
    this.images.delete(oldKey);
  }
  //-------------------------------------------//
  //--------------------------07-05-07--------------------------//
  setJobberOpenJobs(pageNumber, jobsData) {
    this.JobberOpenJobs.set(pageNumber, jobsData);
    this.userHSPDetails = jobsData;
  }
  getJobberOpenJobs(pageNumber) {
    return this.JobberOpenJobs.get(pageNumber);
  }
  deleteJobberOpenJob(hireId, startDate, status): void {
    for (const [key, openJobsData] of this.JobberOpenJobs.entries()) {
      const index = openJobsData?.findIndex(
        (job) => job.hire && job.hire.hireId === hireId
      );
      if (index != -1) {
        if (status == 'Accepted') {
          const formattedCurrentDate = `${
            new Date().toISOString().split('T')[0]
          }`;
          if (startDate > formattedCurrentDate) {
            let existUpComingJobs = this.getJobberUpcomingJobs(
              this.openJobsKey
            );
            if (existUpComingJobs != undefined || existUpComingJobs != null) {
              existUpComingJobs.unshift(openJobsData[index]);
              this.JobberUpcomingJobs?.set(
                parseInt(this.openJobsKey),
                existUpComingJobs
              );
              openJobsData.splice(index, 1);
            } else {
              openJobsData.splice(index, 1);
            }
          } else {
            let existActiveJobs = this.getJobberActiveJobs(this.openJobsKey);
            if (existActiveJobs != undefined || existActiveJobs != null) {
              existActiveJobs.unshift(openJobsData[index]);
              this.JobberActiveJobs.set(
                parseInt(this.openJobsKey),
                existActiveJobs
              );
              openJobsData.splice(index, 1);
            } else {
              openJobsData.splice(index, 1);
            }
          }
          break;
        } else if (status == 'Rejected') {
          let jobberRejectedJobs = this.getJobberCancellledJobs(
            this.openJobsKey
          );
          if (jobberRejectedJobs != undefined || jobberRejectedJobs != null) {
            jobberRejectedJobs.unshift(openJobsData[index]);
            this.JobberCancelledJobs.set(
              parseInt(this.openJobsKey),
              jobberRejectedJobs
            );
            openJobsData.splice(index, 1);
          } else {
            openJobsData.splice(index, 1);
          }
          break;
        }
      }
    }
  }
  updateSpCancelledData(hireId: string, startDate: string, status: string) {
    const formattedCurrentDate = new Date(startDate)
      .toISOString()
      .split('T')[0];
    const processJobs = (jobsMap: Map<number, any[]>) => {
      jobsMap.forEach((jobs, key) => {
        const index = jobs?.findIndex(
          (job) => job.hire && job.hire.hireId === hireId
        );
        if (index !== -1) {
          let jobberRejectedJobs = this.getJobberCancellledJobs(key);
          if (jobberRejectedJobs) {
            jobs[index].status = 'Cancled';
            jobberRejectedJobs.push(jobs[index]);
            this.JobberCancelledJobs.set(key, jobberRejectedJobs);
          }
          jobs.splice(index, 1);
        }
      });
    };
    if (new Date(startDate) <= new Date(formattedCurrentDate)) {
      processJobs(this.JobberActiveJobs);
    } else {
      processJobs(this.JobberUpcomingJobs);
    }
  }
  jobCancelledByCustomer(jobId, startDate, status) {
    for (const [key, openJobs] of this.JobberOpenJobs.entries()) {
      let index = openJobs?.findIndex(
        (job) => job.hire && job.hire.jobId == jobId
      );
      if (index != -1) {
        openJobs.splice(index, 1);
        break;
      } else {
        for (const [key, activeJobs] of this.JobberActiveJobs.entries()) {
          let index = activeJobs?.findIndex(
            (job) => job.hire && job.hire.jobId == jobId
          );
          if (index != -1) {
            activeJobs.splice(index, 1);
            break;
          } else {
            for (const [
              key,
              upcomingJobs,
            ] of this.JobberUpcomingJobs.entries()) {
              let index = upcomingJobs.findIndex(
                (job) => job.hire && job.hire.jobId == jobId
              );
              if (index != -1) {
                upcomingJobs.splice(index, 1);
                break;
              }
            }
          }
        }
      }
    }
  }
  customerCancelledJobs(jobId, type) {
    for (const [key, customerMyJobs] of this.myJobsDataList.entries()) {
      const index = customerMyJobs?.findIndex((job) => job.jobId === jobId);
      if (index != -1) {
        let cancelledData = this.getMyJobsDataList(
          this.customerCancelledJobsData
        );
        if (cancelledData != undefined || cancelledData != null) {
          customerMyJobs[index].status = 'JobCancled';
          cancelledData.unshift(customerMyJobs[index]);
          this.myJobsDataList.set(
            parseInt(this.customerCancelledJobsData),
            cancelledData
          );
          customerMyJobs.splice(index, 1);
          break;
        } else {
          customerMyJobs.splice(index, 1);
          break;
        }
      }
    }
  }
  isExistJobberOpenJobs(pageNumber) {
    const value = this.JobberOpenJobs?.get(pageNumber);
    if (value?.length <= 0) {
      return false;
    } else {
      return this.JobberOpenJobs.has(pageNumber);
    }
  }
  setJobberActiveJobs(pageNumber, jobsData) {
    this.JobberActiveJobs.set(pageNumber, jobsData);
    this.userHSPDetails = jobsData;
  }
  getJobberActiveJobs(pageNumber) {
    return this.JobberActiveJobs.get(pageNumber);
  }
  isExistJobberActiveJobs(pageNumber) {
    const value = this.JobberActiveJobs?.get(pageNumber);
    if (value?.length <= 0) {
      return false;
    } else {
      return this.JobberActiveJobs.has(pageNumber);
    }
  }
  setJobberUpcomingJobs(pageNumber, jobsData) {
    this.JobberUpcomingJobs.set(pageNumber, jobsData);
    this.userHSPDetails = jobsData;
  }
  getJobberUpcomingJobs(pageNumber) {
    return this.JobberUpcomingJobs.get(pageNumber);
  }
  isExistJobberUpcomingJobs(pageNumber) {
    const value = this.JobberUpcomingJobs?.get(pageNumber);
    if (value?.length <= 0) {
      return false;
    } else {
      return this.JobberUpcomingJobs.has(pageNumber);
    }
  }
  setJobberCompletedJobs(pageNumber, jobsData) {
    this.JobberCompletedJobs.set(pageNumber, jobsData);
  }
  getJobberCompletedJobs(pageNumber) {
    return this.JobberCompletedJobs.get(pageNumber);
  }
  isExistJobberCompletedJobs(pageNumber) {
    const value = this.JobberCompletedJobs?.get(pageNumber);
    if (value?.length <= 0) {
      return false;
    } else {
      return this.JobberCompletedJobs.has(pageNumber);
    }
  }
  setJobberCancelledJobs(pageNumber, jobsData) {
    this.JobberCancelledJobs.set(pageNumber, jobsData);
  }
  getJobberCancellledJobs(pageNumber) {
    return this.JobberCancelledJobs.get(pageNumber);
  }
  isExistJobberCancelledJobs(pageNumber) {
    const value = this.JobberCancelledJobs?.get(pageNumber);
    if (value?.length <= 0) {
      return false;
    } else {
      return this.JobberCancelledJobs.has(pageNumber);
    }
  }
  setJobberExistingProfile(data) {
    this.jobberExistingProfile = data;
  }
  getJobberExistingProfile() {
    return this.jobberExistingProfile;
  }
  updateProfilePic(data) {
    this.jobberExistingProfile.body.serviceProviderProfileCls.imageUUID =
      data.body.id;
  }
  isExistJobberProfile() {
    return (
      this.jobberExistingProfile != undefined &&
      this.jobberExistingProfile != null
    );
  }

  isExistAllDocumentsByJobberId() {
    return this.getAllDocuments ?? false;
  }
  setAllDocumentsByJobberId (data) {
    this.getAllDocuments = data;
  }
  getAllDocumentsByJobberId () {
    return this.getAllDocuments;
  }
}
