
<section class="hero">
  <div class="container">
    <div class="content">
      <div class="text-content">
        <div class="headline-wrapper">
          <h1 class="headline">Post a job. Negotiate. <br /> Get Your job done.</h1>
          <button class="cta-button" (click)="navigate('postJob')">Post a Job</button>
        </div>
      </div>
      <div class="image-content">
        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/a1ff2053249c6f35586a721aab0bba983c6d27b6e458a745836556ecf73e9a2e?apiKey=e7fddb4d47f644fa8636247c213e6c85&&" class="side-image" alt="Job completion" />

        <div class="image-wrapper">
          <div class="image-stack">
            <!-- <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/3825b7f483413270eaf70d1a79118f6d5fe17a4162a532a3e0b03968ace2079a?apiKey=e7fddb4d47f644fa8636247c213e6c85&" class="image" alt="Job negotiation" /> -->
            <div class="image-inner-stack">
              <!-- <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/a1ff2053249c6f35586a721aab0bba983c6d27b6e458a745836556ecf73e9a2e?apiKey=e7fddb4d47f644fa8636247c213e6c85&" class="image" alt="Job discussion" /> -->
              <div class="image-inner-stack">
                <!-- <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/ba9feefdb3bf637d1c7464b15917232be59a1cde4e2a9b239341a7697e6121e7?apiKey=e7fddb4d47f644fa8636247c213e6c85&" class="image" alt="Job listing" /> -->
              </div>
            </div>
          </div>
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/b0badb1ebb27f286a7bd4528ed39e2a14793a928d0f5526a00279f9ee2f07f29?apiKey=e7fddb4d47f644fa8636247c213e6c85&" class="main-image" alt="Main job platform" />
        </div>
      </div>
    </div>
  </div>
</section>
            <div class="container-fluid common-3">
                <div class="row rows">
               <div class=" col-12 col-md-6 col-lg-4 col-xl-4 main-body common-2">

               </div>
               <div class="col-12 col-md-6 col-lg-8 col-xl-8">
                <p class="left-matter">Select Category and Services.</p>
               </div>
                </div>
              </div> 
            <div class="container-fluid common-3">
              <div class="row rows">
             <div class=" col-12 col-md-6 col-lg-6  col-lg-8 order-2 order-md-2 order-lg-1"  id="main-12">
              <p class="description">Enter Job description.</p>
              <p  class="p2" style="padding-top: 20px;"><span class="material-symbols-outlined">
                done
                </span>Create your profile</p>  
            <p  class="p2"><span class="material-symbols-outlined">
                done
                </span>Get notifications for the open jobs </p>  
            <p  class="p2"><span class="material-symbols-outlined">
                done
                </span>Accepts the job offer</p>  
            <p class="p2"><span class="material-symbols-outlined">
                done
                </span>Complete the job </p>  
            <p  class="p2"><span class="material-symbols-outlined">
                done
                </span>Receive the payment.</p> 
             </div>
             <div class="col-12  col-md-4 main- enter-budget order-1 order-md-1 order-lg-2">
             </div>
              </div>
            </div> 
            <div class="container-fluid common-1">
              <div class="row rows">
                <div class=" col-12 col-md-4 main-4 common-2">

                </div>
             <div class=" col-12 col-md-8">
              <p class="left-matter  left-mat">Enter your Budget.</p>
             </div>
            
              </div>
            </div> 

            <div class="container-fluid common-1">
              <div class="row rows">
                <div class="col-12 col-md-8 order-2 order-md-2 order-lg-1">
                  <p class="post" style="float: left;">Post your Job requirement.</p>
                 </div>
             <div class=" col-12 col-md-4 main-3 common-2 order-1 order-md-1 order-lg-2" >

             </div>
            
              </div>
            </div> 
            <div class="container-fluid common-1" >
              <div class="row rows">
             <div class=" col-12 col-md-4 main-6 common-2">

           </div>
           <div class="col-12 col-md-8">
            <p class="left-matter">Choose the jobber</p>
           </div>
            </div>
          </div> 

            <div class="container-fluid common-1">
              <div class="row rows">
                <div class="col-12 col-md-8 order-2 order-md-2 order-lg-1">
                  <p class="post" style="float: left;">Get your job</p>
                 </div>
             <div class=" col-12 col-md-4 main-3 common-2 order-1 order-md-1 order-lg-2" >

             </div>
            
              </div>
            </div> 
            <div class="container-fluid common-1">
              <div class="row rows">
                <div class=" col-12 col-md-4 main-5 common-2" >
                </div>
             <div class="col-8 col-md-8">
              <p class="left-matter" >Make the payment securely.</p>
             </div>
              </div>
            </div> 
