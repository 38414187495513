import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable()
export class DeviceService {

private platformReady: Promise<any>;

constructor(private platform: Platform) {
this.platformReady = this.platform.ready();
}

  private _isMobile: boolean = false;
  private _isAndroid: boolean = false;
  private _isIOS: boolean = false;
  private _isMobileWeb: boolean = false;
  private _isDesktop: boolean = false;
  private _isTablet: boolean = false;
  private _isWeb: boolean = false;

  waitForPlatformReady(): Promise<any> {
    return this.platformReady.then(() => {
      this._isMobile = this.platform.is('mobile');
      this._isAndroid = this.platform.is('android');
      this._isIOS = this.platform.is('ios');
      this._isMobileWeb = this.platform.is('mobileweb');
      this._isDesktop = this.platform.is('desktop');
      this._isTablet = this.platform.is('tablet');
      this._isWeb = !this._isMobile && !this._isMobileWeb;
      if (this._isMobileWeb && /Chrome/.test(navigator.userAgent)) {
        this._isMobile = false; // Set _isMobile to false
        this._isWeb = true;
      }
    });
  }
  // Whether the device is a mobile device.
  get isMobile(): boolean {
    return this._isMobile;
  }
  // Whether the device is an Android device
  get isAndroid(): boolean {
    return this._isAndroid;
  }
  // Whether the device is an iOS device
  get isIOS(): boolean {
    return this._isIOS;
  }
  // Whether the device is a mobile web device (i.e., a mobile device running a web browser)
  get isMobileWeb(): boolean {
    return this._isMobileWeb;
  }
  // Whether the device is a web device (i.e., not a mobile device)
  get isWeb(): boolean {
    return this._isWeb;
  }

// Whether the device is a tablet device.
  get isTablet(): boolean {
    return this._isTablet;
  }

getPlatformCode(): string {
// If the device is a mobile web device (i.e., a mobile device running a web browser)
if (this.isMobileWeb) {
// Force desktop screen on mobile browser
return 'mobile-web-desktop';
}
// If the device is a mobile device running Android or iOS
if (this.isMobile && (this.isAndroid || this.isIOS)) {
return 'mobile-android-ios';
}
// If the device is a desktop device
if (this.isWeb) {
return 'mobile-web-desktop';
}
// If the device is a tablet device
else if (this.isTablet) {
return 'tablet';
}
// If the device platform cannot be determined
else {
return 'unknown';
}
}
}

