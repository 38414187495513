import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, catchError, from, of, switchMap, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { WebsocketStompService } from '../Services/websocket-stomp.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private router:Router,private websocketStomp : WebsocketStompService) { }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token = sessionStorage.getItem('token');
    if (!token) {
      return next.handle(request);
    }

    const clonedRequest = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });

    return next.handle(clonedRequest).pipe(
      catchError(error => {
        if (error.status === 401) {
          this.router.navigate(['/']);
        }
        return throwError(()=>error);
      })
    );;
  }


}
