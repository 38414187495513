<div class="container-fluid">
  <div class="row">
    <div class="col-11 m-auto">
      <h3 style="color: var(--first-color); padding-bottom: 10px">Categories</h3>
      <mat-tab-group mat-align-tabs="start" style="overflow: hidden">
        <mat-tab>
          <ng-template mat-tab-label>
            <li *ngFor="let item of categoryData">
              <a
                (mouseenter)="setSelectedCategoryAndGetServices(item)"
                class="services-types"
              >
                <img
                  [src]="
                    'assets/category/' + item.name + '.webp'
                   
                  "
                  style="width: 40px; margin-bottom: 5px"
                  class="img-category"
                />
                {{ item.name }}
              </a>
            </li>
          </ng-template>
          <div class="service-list">
            <li *ngFor="let item of serviceData">
              <h6 (click)="sendToPostJob(item)" class="services-type">
                {{ item.serviceName }}
              </h6>
            </li>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
