import { Component } from '@angular/core';

@Component({
  selector: 'app-company-works',
  templateUrl: './company-works.component.html',
  styleUrls: ['./company-works.component.scss']
})
export class CompanyWorksComponent {

}
