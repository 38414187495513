import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { WebsocketStompService } from './websocket-stomp.service';
import { PopupComponent } from '../popup/popup.component';
import { MatDialog } from '@angular/material/dialog';
// import * as path from 'path';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isPrivate: boolean;
  constructor(
    private dialog: MatDialog,
    private websocketStomp: WebsocketStompService,
    private router: Router
  ) {}
  isRouteAuthenticated(): boolean {
    const loginId = sessionStorage['loginId'];
    const token = sessionStorage['token'];
    const userTypeId = sessionStorage['userTypeId'];
    const pathName = window.location.pathname;
    const isAllowedToNavigate = this.checkPrivateRoute(pathName);

    if (
      pathName == '/' &&
      !isAllowedToNavigate &&
      sessionStorage['isLoggedIn'] == 'true'
    ) {
      sessionStorage['loginId'] = null;
      sessionStorage['token'] = null;
      sessionStorage['postJob'] = null;
      sessionStorage['recentCallbackId'] = null;
      sessionStorage['nCallbackIdSet'] = null;
      sessionStorage['userTypeId'] = null;
      sessionStorage['email'] = null;
      sessionStorage['isLoggedIn'] = null;
      this.router.navigate(['/']);
      this.router.navigate(['/']);
      return false;
    }

    if (
      pathName == '/' &&
      !isAllowedToNavigate &&
      sessionStorage['isLoggedIn'] === null
    ) {
      sessionStorage['loginId'] = null;
      sessionStorage['token'] = null;
      sessionStorage['postJob'] = null;
      sessionStorage['userTypeId'] = null;
      sessionStorage['recentCallbackId'] = null;
      sessionStorage['nCallbackIdSet'] = null;
      sessionStorage['email'] = null;
      sessionStorage['isLoggedIn'] = null;
      this.router.navigate(['/']);
      this.router.navigate(['/']);
      return true;
    }
    if (!isAllowedToNavigate && sessionStorage['isLoggedIn'] == 'true') {
      const titleData = 'Warning!';
      const contentData = 'you want to leave the page?';
      const dialogRef = this.dialog.open(PopupComponent, {
        width: '500px',
        data: {
          title: titleData,
          content: contentData,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          console.log('Popup confirmed!');
          this.logout();
          sessionStorage['isLoggedIn'] = 'false';
          this.router.navigate(['/']);
          if (!isAllowedToNavigate && sessionStorage['isLoggedIn'] == 'false') {
            return true;
          }
          return false;
        } else {
          if (!isAllowedToNavigate && sessionStorage['isLoggedIn'] == 'true') {
            location.reload();
          }
          console.log('Popup cancelled!');
          return false;
        }
      });
      return false;
    }

    // Add your authentication logic here
    // For example, check if the user is logged in
    if (
      loginId != undefined &&
      token != undefined &&
      userTypeId != undefined &&
      loginId != 'null' &&
      token != 'null' &&
      userTypeId != 'null'
    ) {
      if (sessionStorage['isLoggedIn'] == 'false') {
        sessionStorage['loginId'] = null;
        sessionStorage['token'] = null;
        sessionStorage['postJob'] = null;
        sessionStorage['userTypeId'] = null;
        sessionStorage['recentCallbackId'] = null;
        sessionStorage['nCallbackIdSet'] = null;
        sessionStorage['email'] = null;
        sessionStorage['isLoggedIn'] = null;
        return true;
      }
      sessionStorage['isLoggedIn'] = 'true';
      return true;
    }
    if (
      !(
        loginId != undefined &&
        token != undefined &&
        userTypeId != undefined &&
        loginId != 'null' &&
        token != 'null' &&
        userTypeId != 'null'
      ) &&
      !isAllowedToNavigate
    ) {
      return true;
    }
    if (sessionStorage['isLoggedIn'] == 'false') {
      sessionStorage['loginId'] = null;
      sessionStorage['token'] = null;
      sessionStorage['postJob'] = null;
      sessionStorage['userTypeId'] = null;
      sessionStorage['email'] = null;
      sessionStorage['recentCallbackId'] = null;
      sessionStorage['nCallbackIdSet'] = null;
      sessionStorage['isLoggedIn'] = null;
      return true;
    }
    if (
      loginId == undefined &&
      token == undefined &&
      userTypeId == undefined &&
      pathName == '/' &&
      isAllowedToNavigate == true
    ) {
      return true;
    }
    this.logout();
    this.router.navigate(['/']);
    return false;
  }

  logout() {
    sessionStorage['loginId'] = null;
    sessionStorage['token'] = null;
    sessionStorage['postJob'] = null;
    sessionStorage['userTypeId'] = null;
    sessionStorage['email'] = null;
    sessionStorage['isLoggedIn'] = null;
    sessionStorage['recentCallbackId'] = null;
    sessionStorage['nCallbackIdSet'] = null;
    this.router.navigate(['/']);
  }

  //Bhabani 19-03-2024
  checkPrivateRoute(currentLocation: any) {
    const pathToMap = new Map();
    // Add all parent routes to the map
    this.router.config.forEach((route) => {
      if (!pathToMap.has(route.path)) {
        pathToMap.set(route.path, []);
      }

      pathToMap.get(route.path).push(route);
    });
    // Add all child routes to the map with their parent path
    this.router.config.forEach((route) => {
      if (route.children) {
        route.children.forEach((childRoute) => {
          const parentPath = route.path;
          const childPath = parentPath + '/' + childRoute.path;
          if (!pathToMap.has(childPath)) {
            pathToMap.set(childPath, []);
          }
          pathToMap.get(childPath).push(childRoute);
        });
      }
    });
    // Print all the paths
    //   pathToMap.forEach((value, key) => {
    //     const matchedRoute = Array.from(pathToMap.keys()).find(path => '/' + path === currentLocation);
    // console.log(matchedRoute)
    //     if (matchedRoute == '') {
    //       this.isPrivate = false;
    //     } else {
    //       this.isPrivate = pathToMap.get(matchedRoute)[0].data.isPrivate;
    //     }
    //   });
    const matchedRoute = Array.from(pathToMap.keys()).find((path) => {
      if (path[0] !== '/') {
        path = '/' + path;
      }
      const pathParts = path.split('/');
      const currentLocationParts = currentLocation.split('/');
      if (pathParts.length !== currentLocationParts.length) {
        return false;
      }
      for (let i = 0; i < pathParts.length; i++) {
        const pathPart = pathParts[i];
        const currentLocationPart = currentLocationParts[i];
        const colonIndex = pathPart.indexOf(':');
        if (colonIndex !== -1) {
          if (currentLocationPart[0] !== ':' && isNaN(currentLocationPart[0])) {
            return false;
          }
          const paramData = pathPart.slice(colonIndex + 1);
          if (
            paramData.length > 0 &&
            !currentLocationPart.includes(paramData) &&
            isNaN(currentLocationPart[0])
          ) {
            return false;
          }
        } else if (
          pathPart !== currentLocationPart &&
          isNaN(currentLocationPart[0])
        ) {
          return false;
        }
      }
      return true;
    });
    if (matchedRoute || matchedRoute == '') {
      const pathParts = matchedRoute.split('/');
      const routeParams = {};
      for (let i = 0; i < pathParts.length; i++) {
        const pathPart = pathParts[i];
        const colonIndex = pathPart.indexOf(':');
        if (colonIndex !== -1) {
          const paramData = currentLocation.split('/')[i].slice(colonIndex + 1);
          if (paramData.length > 0) {
            routeParams[pathPart.slice(colonIndex + 1)] = paramData;
            this.isPrivate = true;
          } else {
            this.isPrivate = false;
          }
        } else {
          this.isPrivate = pathToMap.get(matchedRoute)[0].data.isPrivate;
        }
      }
    }
    return this.isPrivate;
  }
}
