import { environment } from "src/environments/environment";

export class AppConstants {
    public static api_based_url = environment.apiUrl
    public static reward_url = environment.rewardUrl
    public static review_url = environment.reviewUrl
    public static accessToken = ''
    public static userId = null
    public static websocketHost = environment.websocketUrl

    public static googleClientId = "766904086137-ureb7cglco6rauskbcfufeiolqkvg1vo.apps.googleusercontent.com";
    //Bhabani 25/01/2024
    public static accountSid = "AC610c39c858413a9df5909472909fbc3b"
    public static authToken = "639f62fbfb3147acd171073336fb4662"
    public static messageJsonUrl=`https://api.twilio.com/2010-04-01/Accounts/${this.accountSid}/Messages.json`
    public static ipapiUrl='https://ipapi.co/json/';

    //Bhabani 27-04-2024
    public static razorpayKeyId ='rzp_test_1uxBmnNW5vY1De'
    public static acejobberImageUrl='https://lawfeat.com/assets/figma/newlogo.webp';
    public static aName='AceJobber';
    public static currency='INR';
    public static address='Plot no 185, Road Number 76 filmnagar, Jubilee Hills, Hyderabad, Telangana 500033, IN';

} 