import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { DeviceService } from './Services/device.service';import { ServiceFileService } from './jobber-module/services/service-file.service';
import { MainServiceService } from './Services/main-service.service';
import { firstValueFrom } from 'rxjs';


 

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],


})
export class AppComponent {

  title = 'ace-jobber';
  isMobile: boolean;
  isTablet: boolean;
  isWeb: boolean;

  envName = environment.name;

  pageLoaded = false;
  typeOfButton: any;
  typeOfLogin: string;
  type: string;
  userr: any;
  isTrue: boolean;
  signUpType: string;
  isSignup: boolean;
  searchObj = {
    category: '',
    city: '',
  };
  twoFactorAuth = false;
  navigateToTabs: boolean = false;
  enableHrefInvalidL: boolean = false;
  enableHrefInvalidS: boolean = false;

  ngOnInit(): void {
    this.deviceService.waitForPlatformReady().then(() => {
      console.log('Device type:', this.deviceService.getPlatformCode());
    });
 this.PreLoginCheck();
  }


  // constructor(private ahWebsocketService: WebsocketService, private mainServiceService: MainServiceService) {
  //         this.mainServiceService.setWSServiceObj(ahWebsocketService)
  // }
  hideNavbar: any
  constructor(public router: Router,private platform: Platform,public deviceService: DeviceService, private route: ActivatedRoute, private service: ServiceFileService, private mainService: MainServiceService) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.pageLoaded = true;
      }
    });
    
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.hideNavbar = event.url.startsWith('/acejobber/jobPoster/jobs-posted') || event.url.startsWith('/acejobber/jobPoster/payments')|| event.url.startsWith('/acejobber/jobPoster/my-jobs')
        || event.url.startsWith('/acejobber/jobber/customerList') || event.url.startsWith('/acejobber/jobber/payments')|| event.url.startsWith('/acejobber/jobber/my-jobs') || event.url.startsWith('/acejobber/jobPoster/tabs-page') 
        || event.url.startsWith('/acejobber/jobber/notifications-history') ||  event.url.startsWith('/acejobber/jobPoster/notifications-history');
       
      }
    });
  }


  // Bhabani 09 july 2024
  PreLoginCheck() {
    let loginData = null;
    const getLoginData = () => {
      if (loginData === null) {
        loginData = localStorage.getItem('loginSession') ? JSON.parse(localStorage.getItem('loginSession')) : null;
      }
      return loginData;
    };
    const hasExecuted = sessionStorage.getItem('hasExecuted');
    var navigateToTabs = false;
   
    if (getLoginData() && !hasExecuted) {
      sessionStorage['token'] = loginData.token;
      sessionStorage['loginId'] = loginData.loginId;
      sessionStorage['email'] = loginData.email;
      sessionStorage['userTypeId'] = loginData.userTypeId;
      this.typeOfButton = loginData.isProfileExist;
      if (this.typeOfButton == '3' || this.typeOfButton == 0) {
        this.typeOfLogin = 'SignUp';
      } else if (
        this.typeOfButton == 'customer4' ||
        this.typeOfButton == 'service4'
      ) {
        this.typeOfLogin = 'LogIn';
      }
      if (this.typeOfButton == 'customer4') {
        this.type = 'jobPoster';
      } else if (this.typeOfButton == 'service4') {
        this.type = 'jobber';
      }

      this.isTrue =
        this.typeOfLogin === 'LogIn'
          ? this.typeOfButton === 'customer4'
            ? sessionStorage['userTypeId'] == 1
              ? false
              : true
            : this.typeOfButton === 'service4'
              ? sessionStorage['userTypeId'] == 2
                ? false
                : true
              : false
          : (sessionStorage['userTypeId'] == 0 ? false : true)
            ? this.userr == sessionStorage['userTypeId']
              ? false
              : true
            : false;


      if (sessionStorage['userTypeId'] != 0 && this.typeOfButton == '3') {
        this.typeOfLogin = 'SignUp';
        this.isTrue = false;
        if (sessionStorage['userTypeId'] == 1) {
          this.signUpType = 'jobPoster';
        } else if (sessionStorage['userTypeId'] == 2) {
          this.signUpType = 'jobber';
        }
      }
      if (this.typeOfLogin == 'LogIn' && this.isSignup) {
        this.hrefInvalid(this.typeOfLogin);
      } else if (this.isTrue) {
        if (sessionStorage['userTypeId'] == 1) {
          const keysToRemove = [
            'email',
            'loginId',
            'token',
            'postJob',
            'userTypeId',
            'isLoggedIn',
          ];
          for (const key of keysToRemove) {
            sessionStorage.removeItem(key);
          }
        } else if (sessionStorage['userTypeId'] == 2) {
          const keysToRemove = [
            'email',
            'loginId',
            'token',
            'postJob',
            'userTypeId',
            'isLoggedIn',
          ];
          for (const key of keysToRemove) {
            sessionStorage.removeItem(key);
          }
        }
      } else if (this.typeOfLogin == 'SignUp') {
        if (this.signUpType == 'jobber') {
          this.router.navigate(['/acejobber/jobber/jobber-registration']);
        } else {
          this.router.navigate([
            '/acejobber/jobPoster/jobposter-registration',
          ]);
        }
      } else if (this.typeOfLogin == 'LogIn') {
        if (this.type == 'jobber') {
          this.router.navigate(['acejobber/' + this.type + '/customerList']);
        } else {
          if (this.twoFactorAuth) {
          } else {
            if (navigateToTabs) {
              this.router.navigate([
                'acejobber/' + this.type + '/tabs-page',
              ]);
            } else {
              this.router.navigate([
                'acejobber/' + this.type + '/dashboardHire',
              ]);
            }
          }
        }
      }else{
        this.router.navigate['/']
      }
      sessionStorage.setItem('hasExecuted', 'true');
    }

  }
  hrefInvalid(typeOfLogin: any) {
    const keysToRemove = [
      'email',
      'loginId',
      'token',
      'postJob',
      'userTypeId',
      'isLoggedIn',
    ];
    for (const key of keysToRemove) {
      sessionStorage.removeItem(key);
    }
    if (typeOfLogin == 'LogIn' && this.isSignup) {
      this.enableHrefInvalidL = true;
    } else if (typeOfLogin == 'SignUp' && !this.isSignup) {
      this.enableHrefInvalidS = true;
    }
  }

}
