import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent {

  goBack() {
    this.router.navigate(['/acejobber/jobber/customerList']);
  }

  constructor(private router :Router,){

  }
}
