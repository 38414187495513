import { Component, ElementRef, ViewChild, HostListener } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',

  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent {
  @ViewChild('dialog') dialogElement: ElementRef<HTMLDialogElement>;
  @ViewChild('openButton') openButtonElement: ElementRef<HTMLButtonElement>;
  @ViewChild('closeButton') closeButtonElement: ElementRef<HTMLButtonElement>;

  constructor() {}

  ngAfterViewInit() {
    this.openButtonElement.nativeElement.addEventListener('click', () => {
      this.dialogElement.nativeElement.showModal();
    });

    this.closeButtonElement.nativeElement.addEventListener('click', () => {
      this.dialogElement.nativeElement.close();
    });

    this.dialogElement.nativeElement.addEventListener('click', (event) => {
      const dialogElement = event.target as HTMLDialogElement;
      if (dialogElement.nodeName === 'DIALOG') {
        dialogElement.close('dismiss');
      }
    });
  }

  // form
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape' && this.dialogElement.nativeElement.open) {
      this.dialogElement.nativeElement.close();
    }
  }

  onlyNumbers(event: any) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    }
  }

  onSubmit() {
    // Form submission logic here
  }
  myForm = new FormGroup({
    myControl: new FormControl('', Validators.required),
  });
}
