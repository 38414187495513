
<div class="container">
    <div class="row">
        <div class="col-12">
            <h2 style="text-align: center;font-weight:bold;font-size: 35px;padding-top: 15px;">How It Works</h2>
  <h3 style="font-size:30px;font-weight: 600;color: black;padding-top: 35px;">For Customers</h3>
  <ol>
     <li>
        <p><strong class="bold" >Tell Us What You Need</strong>: <br> "Start by telling us exactly what service you need and where you need it. It's quick and easy."</p>
     </li>
     <li>
        <p><strong class="bold" >Set Your Budget</strong>: <br> "You're in control. Set your budget and watch the offers come in."</p>
     </li>
     <li>
        <p><strong class="bold">Choose Your Jobber</strong>: <br> "Browse through our list of top-rated Jobbers. Check out their profiles, ratings, and availability."</p>
     </li>
     <li>
        <p><strong class="bold">Send Your Request</strong>: <br> "Select your preferred Jobber and send them your request directly. If they’re available, they’ll accept your job. Just like that."</p>
     </li>
     <li>
        <p><strong class="bold"> Chat and Negotiate</strong>: <br> "Found your match? Chat in-app and negotiate to ensure you get the best deal."</p>
     </li>
     <li>
        <p><strong class="bold">Payment, Smooth and Secure</strong>: <br> "After your Jobber accepts, your payment is processed securely through our platform. No fuss, just trust."</p>
     </li>
     <li>
        <p><strong class="bold">Leave a Review</strong>:  <br> "Once the job is done, don't forget to leave a review. Share your experience and help the community find the best professionals."</p>
     </li>
  </ol>
  <p><strong style="font-size: 27px;">Plus, Enjoy These Exclusive Features:</strong></p>
  <ul>
     <li><strong class="bold">Insurance Peace of Mind</strong>: <br> "Every job is insured. Sit back and relax, knowing you’re fully covered."</li>
     <li><strong class="bold">Direct Messaging</strong>: <br> "Got questions? Get answers. Chat directly with your pro anytime through our in-app messaging."</li>
     <li><strong class="bold">Real-Time SMS Updates</strong>:  <br> "Never miss a beat with SMS updates. From job acceptance to completion, you’re informed."</li>
     <li><strong class="bold">Rewards for Every Booking</strong>: <br> "Earn rewards with every booking. Save on future services and enjoy exclusive benefits."</li>
  </ul>
  <p><strong style="font-size: 27px;">Ready to Get Started?</strong></p>
  <p>"Your Jobber is just a selection away. Set your need, pick your Jobber, and get it done."</p>
  <h3 style="font-size: 27px;color: black;font-weight: 600;">For Jobber</h3>
  <ol>
     <li>
        <p><strong class="bold">Sign Up and Get Verified</strong>: <br> "Create your account and upload your ID for verification. It's a quick step to ensure trust and safety within our community."</p>
     </li>
     <li>
        <p><strong class="bold">Create Your Jobber Profile</strong>: <br> "Highlight your skills, experience, and past work. A strong profile attracts more customers and showcases your expertise."</p>
     </li>
     <li>
        <p><strong class="bold">Set Your Availability</strong>: <br> "Control your schedule. Update your availability to let customers know when you’re open for work."</p>
     </li>
     <li>
        <p><strong class="bold">Receive Direct Requests</strong>: <br> "Customers choose you based on your profile and send requests directly. Review and accept jobs that fit your skills and calendar."</p>
     </li>
     <li>
        <p><strong class="bold">Use the Dashboard</strong>: <br> "Access your dashboard to track job metrics and financial trends. Stay informed about your performance and earnings."</p>
     </li>
     <li>
        <p><strong class="bold">Chat and Confirm Jobs</strong>: <br> "Discuss job details and finalize agreements through in-app messaging. It’s direct communication for clear expectations."</p>
     </li>
     <li>
        <p><strong class="bold">Secure and Timely Payments</strong>: <br> "After job completion, payments are processed securely through our platform. Enjoy hassle-free transactions."</p>
     </li>
     <li>
        <p><strong class="bold">Build Your Reputation</strong>: <br> "Get reviews from customers after each job. Positive feedback boosts your profile and helps grow your business."</p>
     </li>
  </ol>
  <p><strong style="font-size: 27px;">Exclusive Features for Jobbers:</strong></p>
  <ul>
     <li style=" color: black;
     font-size: 20px;"><strong class="bold">Insurance Coverage</strong>: <br> "Work with confidence knowing each job is insured. Focus on what you do best, covered by our insurance policy."</li>
     <li style=" color: black;
     font-size: 20px;"><strong class="bold">Real-Time SMS and App Updates</strong>: <br> "Never miss out. Receive job alerts and updates directly via SMS and our app, keeping you in the loop wherever you are."</li>
     <li style=" color: black;
     font-size: 20px;"><strong class="bold">Rewards Program</strong>: <br> "Your hard work deserves extra rewards. Benefit from bonuses, enhanced visibility on our platform, and more."</li>
  </ul>
  <p><strong style="font-size: 27px;">Ready to Elevate Ace Skill?</strong></p>
  <p>"Join us today. Complete your profile, get verified, and connect with customers looking for your expertise. Let’s achieve great work together."</p>
        </div>
    </div>
</div>
