<footer class="text-center text-lg-start  text-muted footer-main">
  <div class="col-12 pt-5 pt-md-0">
    <div class="d-flex align-content-center  justify-content-center justify-content-lg-start">
     <img src="assets/figma/newlogo.webp" alt="" width="40px" height="40px">
       <span style="
      font-family: 'Poppins';
      font-weight: 700;
      font-size: 20px;
      padding-top: 5px;
      letter-spacing: 3.6px;
      padding-left: 10px;
      color: #FFFFFF;">AceJobber</span>
    </div>
  </div>
  <section>
    <div class="container m-auto mt-5">
      
      <div class="row mt-3">
      
          <div class=" col-6 col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 class="text-uppercase fw-bold mb-4">
                FOR JOB POSTER
              </h6>
              <p>
                <a href="/acejobber/post-job">Post a Job</a>
              </p>
              <p>
                <a href="/working">How it Works</a>
              </p>
              <p>
                <a href="/acejobber/login/login-page">Login</a>
              </p>
            </div>
        <div class=" col-6 col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
          <h6 class="text-uppercase fw-bold mb-4">
           FOR JOBBER
          </h6>
          <p>
            <a href="/">Find a Job</a>
          </p>
          <p>
            <a href="/working">How it Works</a>
          </p>
          <p>
            <a  href="/landing-2">Become a Jobber</a>
          </p> 
        </div>

        <div class=" col-6 col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
          <h6 class="text-uppercase fw-bold mb-4">
           Company
          </h6>
           <p>
            <a  >About Us</a>
          </p>
          <p>
            <a>Careers</a>
          </p>
          <p>
            <a href="/contact-us">contact Us</a>
          </p>
            <p>
              <a >Customer Support</a>
            </p>
        </div>
        <div class=" col-6 col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
          <h6 class="text-uppercase fw-bold mb-4">
          Terms
          </h6>
         
          <p>
            <a href="/privacy">Privacy Policy</a>
          </p>
          <p>
              <a href="/terms">Terms and Conditions</a>
            </p>
            <p>
              <a href="/charges">Fees and Charges</a>
            </p>
            <p>
              <a href="/refund">Refund Policy</a>
            </p>

        </div>
        <div class=" col-6 col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
          <h6 class="text-uppercase fw-bold mb-4 ">Our Locations</h6>
          <p>
              <a >India</a>
            </p>
        </div>
      </div>
    </div>
  </section>
</footer>






