import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
// import { NgxPaginationModule } from 'ngx-pagination'; // commented while merging
import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';

import { FormsModule } from '@angular/forms'; 
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import {RouterModule} from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';  
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ButtonModule } from 'primeng/button';
import { MultiSelectModule } from 'primeng/multiselect';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { CardModule } from 'primeng/card';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MessagesModule } from 'primeng/messages';
import { TabViewModule } from 'primeng/tabview';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { BadgeModule } from 'primeng/badge';
import { ToastModule } from 'primeng/toast';
import {MatStepperModule} from '@angular/material/stepper';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatTabsModule} from '@angular/material/tabs';
import { MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter'
import { AutoCompleteModule } from 'primeng/autocomplete';
import { NO_ERRORS_SCHEMA } from '@angular/core';
import {WebcamModule} from 'ngx-webcam';
import {MatCardModule} from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';

import { LazyLoadImageModule } from 'ng-lazyload-image';
import { TokenInterceptor } from './interceptors/token.interceptor';


import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { IonicModule } from '@ionic/angular';
import { ExampleComponent } from './example/example.component';

import { NavbarComponent } from './navbar/navbar.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { CompanyWorksComponent } from './company-works/company-works.component';
import { RefundPolicyComponent } from './refund-policy/refund-policy.component';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { routes } from './app-routing.module';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ChargesComponent } from './charges/charges.component';
import { DeviceService } from './Services/device.service';

@NgModule({   
                                              
  declarations: [
    AppComponent,
    routingComponents,
    NavbarComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent,
    CompanyWorksComponent,
    RefundPolicyComponent,
    ContactUsComponent,
    ChargesComponent
  ],
  exports: [NavbarComponent],
  
  imports: [
    RouterModule.forRoot(routes,{scrollPositionRestoration:'enabled'}),
    BrowserModule,

    CommonModule,
    LazyLoadImageModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    BrowserAnimationsModule,
    ButtonModule,
    MultiSelectModule,
    CheckboxModule,
    PasswordModule,
    InputTextModule,
    CardModule,
    DropdownModule,
    ProgressSpinnerModule,
    TableModule,
    DialogModule,
    PdfViewerModule,
    MessagesModule,
    TabViewModule,
    RadioButtonModule,
    CalendarModule,
    ConfirmDialogModule,
    ConfirmPopupModule,
    BadgeModule,
    ToastModule,
    MatStepperModule,
    MatButtonModule,
    MatDialogModule,
    MatInputModule,
    MatTabsModule,
    MatFormFieldModule,
    MatIconModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    AutoCompleteModule,
    WebcamModule,
    MatCardModule,
    MatToolbarModule,  // changed the spelling,
    MatTooltipModule,     
    LazyLoadImageModule,
    NgxIntlTelInputModule,
    BrowserAnimationsModule,
    NgxScrollTopModule ,
    
    IonicModule.forRoot(),
    
    

    
    BrowserAnimationsModule
 //   NgxPaginationModule  //ccommented while merging


  ],
  schemas: [
    NO_ERRORS_SCHEMA
],

  providers: [TokenInterceptor,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }, /* optional */
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },DeviceService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
