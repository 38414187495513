
<div class="container">
    <div class="row">
        <div class="col-12">
            <h3 style="text-align: center; padding-top: 18px;font-weight: 500;font-weight: bold;">PRIVACY POLICY</h3>

<h5 style="text-align: center; padding-top: 10px;font-weight: 500;">Last updated: 31st January, 2022</h5><div style="height: 100vh;width: 100%; padding: 30px ;">
    <div class="col-12">
    <h6 style="font-size: 25px;">Welcome to AceJobber's Privacy Policy.</h6>
    </div>
    <br>
    <div class="col-12">
    <section style=" font-weight: 500; font-size: 20px; text-align: justify;">AceJobber India Private Limited and its affiliates (collectively "AceJobber," "we," or "us")
        specialize in offering web-based solutions that create connections between customers
        seeking specific services and service professionals providing those services. This Privacy
        Policy details our practices regarding the collection, storage, usage, processing, and
        disclosure of personal data you consent to share with us when you access, use, or
        interact with our website at <a href="https://www.acejobber.com">https://www.acejobber.com</a> or our mobile application
        'AceJobber' (together referred to as the "Platform"), or when you use products or
        services AceJobber provides through the Platform (referred to collectively as the
        "Services"). Within this Policy, the services provided to you by service professionals via
        the Platform are called "Professional Services."</section></div>
        <br>
        <section style="font-weight: 500; text-align: justify; font-size: 20px;">At AceJobber, safeguarding your personal data and respecting your privacy are priorities
            for us. To offer you access to the Services or Professional Services, it is necessary for us
            to collect and process certain data about you. This Policy outlines how your personal
            data is processed and utilized by us.</section>
            <br>
            <section style="font-weight: 500; text-align: justify; font-size: 20px;">It's important to note that, unless explicitly defined in this Policy, capitalized terms carry
                the same meanings ascribed to them in our Terms and Conditions, which are available
                at <a href="https://www.AceJobber.com/terms">https://www.AceJobber.com/terms</a>("Terms"). We encourage you to read this Policy in
                conjunction with the Terms.</section>
                <br>
            <section style="font-weight: 500; text-align: justify; font-size: 20px;">By utilizing the Services, you acknowledge that you have read, understood, and agreed
                to this Policy and consent to the processing described herein.</section>
                <br>
                <section style=" font-weight: 500; font-size: 20px;">For details on the application of this Policy's terms to you, please see Section 1.</section>
             <br>
             <br>
             <h1 style="font-weight: bold;font-size: 25px;">1. Background and Essential Information</h1>
             <h2 style="padding-top: 15px; font-weight: 600; font-size: 21px;">(a) Application of this Policy:</h2>

             <section style="font-weight: 500;font-size: 20px;padding-top: 10px;">This Policy governs the relationship between AceJobber and individuals who access, use,
                or engage with the Services, including the Professional Services provided through our
                Platform. Throughout this document, the term "you" refers to any end user interacting
                with our Platform.</section><br>
                <section style="font-weight: 500; font-size: 20px; ">By engaging with the Platform, you expressly consent to our practices regarding the
                    collection, storage, use, and disclosure of your personal data, all conducted in strict
                    adherence to the terms set forth in this Policy.</section>
                <br>
                <h2 style="font-weight: 600; padding-top: 15px;font-size: 21px;">(b) Policy Review and Updates:</h2>
                <section style="font-weight: 500; padding-top: 15px; font-size: 20px;text-align: justify;">We are committed to maintaining the relevance and accuracy of our Privacy Policy by
                    conducting regular reviews and updates. To ensure the personal data we maintain is
                    accurate and current, we encourage you to review this Policy periodically and inform us
                    of any changes to your personal data during your engagement with us.</section><br>

                    <h2 style="font-weight: 600; padding-top: 15px;font-size: 21px;">(c) Interactions with Third-Party Services:</h2>
                    <section style="font-weight: 500; padding-top: 15px; font-size: 20px; text-align: justify;">Our Platform may provide links to external websites, plug-ins, services, and applications
                        (collectively, "Third-Party Services") for your convenience and information. Engaging
                        with these Third-Party Services may result in data about you being collected or shared
                        by third parties. Please be aware that we do not exercise control over these Third-Party
                        Services and do not endorse them, nor are we responsible for their privacy practices. We
                        advise exercising caution and recommend that you review the privacy policies of these
                        third-party entities before sharing your personal information</section>
                        <br>
               <h1 style="font-weight: bold;font-size: 25px;">2. Categories of Personal Data Collected</h1>
              <h2 style="font-weight: 600; padding-top: 15px;font-size: 21px;">(a) Types of Personal Data:</h2>

              <section style="font-weight: 500;padding-top: 15px; font-size: 20px; text-align: justify;">At AceJobber, we collect various categories of personal data to enhance your experience
                and provide tailored services. These categories include:</section>

                 <ul style="padding-left: 45px;">
                    <li style="padding-top: 15px;"><b  style="font-size: 20px;">Contact Information: </b><span style="font-size: 18px;font-weight: 500;">This encompasses your mailing address, geographic
                        location, email addresses, and telephone numbers.</span></li>
                    <li><b  style="font-size: 20px;">Identity and Profile Information: </b><span style="font-size: 18px;font-weight: 500;">This includes details such as your name,
                        usernames or other identifiers, photographs, and gender.</span></li>
                        <li><b  style="font-size: 20px;">Marketing and Communications Information: </b><span  style="font-size: 18px;font-weight: 500; text-align: justify;">We gather data related to your
                            interactions with marketing materials, including your postal and email addresses,
                            content you've engaged with (e.g., service requests, feedback, blog comments),
                            your preferences in receiving marketing communications from us and our
                            partners, as well as your communication preferences. Additionally, we record your
                            communications with service professionals through our Platform.</span></li>
                    <li><b style="font-size: 20px;">Technical Information: </b><span style="font-size: 18px; font-weight: 500;">This category covers data such as your IP address,
                        browser type, internet service provider, operating system details, website and application usage metrics (including page views, access times, clicks), and device
                        information used to access our Platform</span></li>
                    <li><b style="font-size: 20px;">Transaction Information: </b><span style="font-size: 18px; font-weight: 500;">Details of the services you've engaged with on our
                        Platform, including a portion of your payment card details and UPI IDs for
                        payment processing, as provided by payment processors, are collected for
                        transaction tracking.
                       </span></li>
                    <li><b style="font-size: 20px;">Usage Information: </b><span style="font-size: 18px; font-weight: 500;">We track how you use our Services and Platform, including
                        your navigation patterns, booking history, and interactions within the application
                        or website.</span></li>
                 </ul>
                
                 <h2 style="font-weight: 600; padding-top: 15px;font-size: 21px;">(b) Aggregated Data:</h2>

                 <section style="padding-top: 10px; font-weight: 500; text-align: justify; font-size: 20px;">We also generate and utilize aggregated data for analytical purposes. This data, derived
                    from your personal information, does not identify you directly or indirectly. Should
                    aggregated data be combined with your personal data in a way that could identify you,
                    it will be treated as personal data and handled in accordance with this Policy.</section>

                    <h2 style="font-weight: 600; padding-top: 15px;font-size: 21px;">(c) Implications of Withholding Personal Data:</h2>
                    <section style="font-weight: 500; padding-top: 10px; text-align: justify; font-size: 20px;">Providing your personal data is a requirement for using our Services, either due to legal
                        obligations or as part of our contractual relationship. Failure to provide necessary data
                        upon request may hinder our ability to deliver the Services, potentially resulting in
                        cancellation or restricted access to our offerings.</section>
                    <br>
                        <h1 style="font-weight: bold;font-size: 25px;">3. Methods of Personal Data Collection</h1>
                        <section style="font-size: 20px; font-weight: 500; padding-top: 10px; ">At AceJobber, we employ several methods to collect personal data to provide and
                            enhance our Services. The avenues through which we gather this information include:</section>
                       <section><b style="font-size: 20px; font-weight: 600;">(a) Direct Interactions: </b><span style="font-size: 20px; font-weight: 500;padding-top: 10px;">You directly supply us with your personal data through various
                        interactions, such as when you:</span></section>

                           <ul style="margin-left:35px ;">
                            <li style="font-size: 20px; font-weight: 500;">Register for an account or create a profile on our Platform.</li>
                            <li style="font-size: 20px; font-weight: 500;">Engage with our Services or participate in activities related to the Services.</li>
                            <li style="font-size: 20px; font-weight: 500;">Participate in promotions, user polls, or complete online surveys.</li>
                            <li style="font-size: 20px; font-weight: 500;">Opt-in to receive marketing communications from us.</li>
                            <li style="font-size: 20px; font-weight: 500;">Contact us with queries, report issues with the Platform or our Services, or
                                provide feedback.</li>
                           </ul>

                  <section> <b style="font-size: 20px; font-weight: 600;">(b) Automated Technologies: </b><span style="font-size: 20px; font-weight: 500;">Our Platform automatically collects certain types of
                        Technical Data during your visits and interactions with our Services. This collection is
                        facilitated through the use of technologies such as cookies, web beacons, pixel tags, and
                        server logs. Such Technical Data may include information about your device, browsing
                        actions, and patterns. Additionally, we may gather Technical Data from your interactions
                        with websites or applications that use our cookies.</span></section> 

                        <section> <b style="font-size: 20px; font-weight: 600;">(c) Third-Party Sources: </b><span style="font-size: 20px; font-weight: 500;">Our Platform automatically collects certain types of
                            We also receive personal data about you from various third-party sources, which include:</span></section> 
                                         
                            <ul style="margin-left:35px ;">
                                <li style="font-size: 20px; font-weight: 500;">Technical data provided by analytics services (e.g., Google Analytics) and
                                    advertising networks.</li>
                                <li style="font-size: 20px; font-weight: 500;">Identity, profile, and contact information sourced from service professionals,
                                    public databases, and other publicly accessible sources.</li>
                                <li style="font-size: 20px; font-weight: 500;">Personal data shared with us by our affiliates and partner entities.</li>
                            </ul>
                        <section style="font-size: 20px; font-weight: 500;">This comprehensive approach allows us to tailor our Services to meet your needs better
                            and enhance your overall experience on the Platform.</section>
                        <br>
                 <h1 style="font-weight: bold;padding-top: 15px;font-size: 25px;">4. Utilization of Your Personal Data: </h1>
                     <h2 class="heading" style="font-size: 21px;">(a) Legal Basis for Processing: </h2>
                     <section style="font-size: 20px; font-weight: 500;">AceJobber commits to processing your personal data strictly in compliance with
                        applicable laws. Predominantly, we process your personal data to:</section>
                        <ol>
                            <li style="font-size: 20px; font-weight: 500; padding-top: 10px;">Provide you with our Services and enable your access to Professional Services.</li>
                            <li style="font-size: 20px; font-weight: 500;">Fulfill our legal obligations and contractual commitments to you.</li>
                        </ol>
                    <h2 style="font-weight: 600; padding-top: 10px; font-size: 28px;font-size: 21px;">Specific Purposes for Using Your Data:</h2>


                    <ul style="margin-left:35px ;">
                        <li style="padding-top: 15px;"><b class="bold" >Identity Verification: </b><span style="font-size: 18px;font-weight: 500;">TTo confirm your identity, register you as a user, and
                            establish your account on our Platform.</span></li>
                        <li><b class="bold" >Service Delivery: </b><span style="font-size: 18px;font-weight: 500;">To furnish you with the Services and Professional Services you seak</span></li>
                            <li><b class="bold" >Marketing and Communications Information: </b><span  style="font-size: 18px;font-weight: 500; text-align: justify;">
                                To tailor and enhance your experience, as well as the functionality and customer service quality of our Services, based on

                                 your feedback and usage patterns.</span></li>
                        <li><b class="bold">Transactions and Communications: </b><span style="font-size: 18px; font-weight: 500;">To process payments, track transactions,
                            and manage our ongoing relationship through notifications, updates, and
                            essential information about our Services.</span></li>
                        <li><b  class="bold" >Marketing: </b><span style="font-size: 18px; font-weight: 500;">To inform you about our services, special offers, and relevant
                            updates. This includes personalized advertising based on your interests and
                            interactions with our Services.
                           </span></li>
                        <li><b class="bold" >Legal Compliance and Protection: </b><span style="font-size: 18px; font-weight: 500;">To comply with legal requirements, protect
                            our business and Services, troubleshoot issues, conduct data analysis, and
                            safeguard our legal rights.</span></li>
                     </ul>
                     <h2 class="heading" style=" font-size: 21px;">(b) Consent and Communication: </h2>
                     <section class="small-headings">By engaging with our Services and creating an account, you permit AceJobber, along
                        with our service professionals, partners, and affiliates, to communicate with you through
                        various means for service delivery, promotional purposes, and to inform you about new
                        features or Services.</section>

                        <h2 class="heading" style=" font-size: 21px;">(c) Information Sharing and Collaboration: </h2>
                        <section class="small-headings">We may collect, compile, and share your information as necessary to provide the
                            Services. This includes interactions with service professionals, vendors, social media
                            platforms, third-party service providers, and within the AceJobber group for operational
                            purposes.</section>
                            <h2 class="heading" style=" font-size: 21px;">(d) Disclosure for Legal Reasons: </h2>
                           <section class="small-headings">Your data may be shared without your prior consent if required by law, court orders, or
                            government directives, under the premise of fulfilling legal obligations, enforcing our
                            policies, or protecting our rights and interests.</section> 
                      <br>
                      <h1 style="font-weight: bold;font-size: 25px;">5. Use of Cookies</h1>
                      <h2 class="heading" style=" font-size: 21px;">(a) Definition and Functionality: </h2>
                      <section class="small-headings">Cookies are diminutive data files transferred to your device's hard drive via your web
                        browser (with your consent) by a website or its service provider. These files are
                        instrumental in enabling the website or service provider's systems to recognize your
                        browser and capture as well as remember specific information about your interactions.</section>

                        <h2 class="heading" style=" font-size: 21px;">(b) Purpose and Utilization: </h2>
                        <section class="small-headings">Our use of cookies is aimed at enhancing user distinction, understanding your
                            preferences for subsequent visits, monitoring advertisement effectiveness, and
                            gathering comprehensive data on site traffic and interactions. This approach enables us
                            to provide a more streamlined and personalized user experience. To deepen our
                            understanding of visitor behavior, we may engage third-party services, which are
                            restricted from using the collected data for any purposes other than aiding in our
                            business enhancement efforts.</section>

                       <h2 class="heading" style=" font-size: 21px;">(c) Third-Party Cookies: </h2>
                       <section class="small-headings">During your interaction with our Platform, you might encounter cookies or similar
                        technologies deployed by third-party entities on certain pages. It's important to note
                        that AceJobber does not govern the use of such third-party cookies. Furthermore,
                        should you engage in personal correspondence with us or if such interactions are
                        relayed to us by other users or third parties, we may store this information in a user-
                        specific file, contributing to our comprehensive user engagement records.</section>

                    <h1 style="font-weight: bold;padding-top: 10px;font-size: 25px;">6. Disclosure of Your Personal Data</h1>
                    <h2 class="heading" style=" font-size: 21px;">(a) Sharing Your Data: </h2>
                    <section class="small-headings">In line with the purposes outlined in Section 4, AceJobber may disclose your personal
                        data to the following categories of third parties:</section>
                <ol style="font-weight: 800; margin-left: 30px;">
                    <li><span style="font-size: 20px;">Service Professionals: </span><span style="font-size: 18px;font-weight: 500;">To facilitate the provision of Professional Services you request.</span></li>      
                            
                    <li><span style="font-size: 20px; " >Internal Groups: </span><span style="font-size: 18px; font-weight: 500;">Including companies within the AceJobber family, for
                        operational and service enhancement purposes.</span></li>
                    <li><span style="font-size: 20px;">External Third Parties: </span><span style="font-size: 18px; font-weight: 500;">This includes: </span></li>
                </ol>

                 <ul style="margin-left: 50px; font-weight: 800;">
                    <li><span style="font-size: 20px;">Associate Partners and Service Providers: </span><span style="font-size: 18px; font-weight: 500;">Trusted entities that assist in
                        operating our Platform, marketing, business operations, payment
                        processing, content transmission, and service delivery on our behalf. </span></li>
                        <li><span style="font-size: 20px;">Analytics and Advertising Networks: </span><span style="font-size: 18px; font-weight: 500;">Providers conducting web analytics
                            to refine our Platform, employing cookies and similar technologies for service optimization.</span></li>
                    <li><span style="font-size: 20px;">Registered Users: </span><span style="font-size: 18px; font-weight: 500;">Based on your consent or at your direction, sharing
                        with other users of our Platform.</span></li>
                        <li><span style="font-size: 20px;">Legal and Regulatory Authorities: </span><span style="font-size: 18px; font-weight: 500;">As necessitated by legal or regulatory
                            obligations.</span></li>
                 </ul>
                       
                 <h2 class="heading" style=" font-size: 21px;">(b) Third-Party Compliance and Data Security: </h2>
                 <section class="small-headings">We enforce strict data protection standards for any third-party service provider
                    accessing your personal data, prohibiting them from using your information for their
                    own purposes and ensuring processing is only for specified, instructed tasks. Our
                    commitment extends to requiring these parties to adhere to legal standards and treat
                    your personal data with the utmost security and confidentiality.</section>
                

                    <h1 style="font-weight: bold;padding-top: 10px;font-size: 25px;">7. Your Rights Concerning Your Personal Data</h1>
                    <h2 class="heading" style=" font-size: 21px;">(a) Access and Correction of Your Personal Data: </h2>
                    <section class="small-headings">You affirm that the personal data provided to AceJobber is accurate, current, and
                        complete. In our commitment to uphold your data rights, we ensure you have the
                        capability to access and rectify any inaccuracies or deficiencies in your personal data,
                        adhering to applicable legal stipulations. Should you wish to obtain a copy of the
                        personal data we hold about you, please contact us at privacy@AceJobber.com. We aim
                        to respond to such requests within seven (7) working days.</section>

                    <h2 class="heading" style=" font-size: 21px;">(b) Opting Out of Marketing and Promotional Communications: </h2>
                    <section class="small-headings">AceJobber respects your preferences regarding marketing and promotional
                        communications. When you receive such communications from us via email, you will be
                        provided with an option to unsubscribe from future messages through the opt-out
                        instructions included in each email. We acknowledge and inform you that processing
                        your opt-out request may take up to ten (10) business days. It is important to note that,
                        independent of your marketing preferences, we may still communicate with you
                        regarding your user account and any services you have enlisted or received from us for
                        essential purposes.</section>
                    <h1 style="font-weight: bold;padding-top: 10px;font-size: 25px;">8. Account and Personal Data Deletion</h1>
                    <h2 class="heading" style=" font-size: 21px;">(a) Requesting Deletion: </h2>
                    <section class="small-headings">Despite the provisions outlined in our Terms, you retain the right to request the deletion
                        of your account and the personal data associated with it from AceJobber's records. To
                        initiate this process, please forward your request to privacy@AceJobber.com. We
                        endeavor to process such requests within seven (7) working days.</section>
                        <br>
                        <section class="small-headings">Upon the deletion of your account, please be aware that you will forfeit access to all
                            AceJobber Services. For the sake of clarity, we wish to emphasize that any data related
                            to transactions you have conducted on the Platform will be preserved in strict
                            compliance with relevant legal obligations.</section>
                        
                    <h1 style="font-weight: bold;padding-top: 10px;font-size: 25px;">9. International Transfer of Your Personal Data</h1>
                    <h2 class="heading" style=" font-size: 21px;">(a) Compliance with Laws: </h2>
                    <section class="small-headings">AceJobber is committed to adhering to all applicable laws regarding the storage and
                        transfer of personal data. In the course of providing our Services, it may be necessary to
                        transfer your personal data across borders to be stored in countries different from your
                        own. Such transfers may occur due to the geographical locations of our servers, or the
                        locations of our vendors, partners, or service providers.</section>
                    <h2 class="heading" style=" font-size: 21px;">(b) Your Consent to Data Transfer: </h2>
                    <section class="small-headings">By utilizing our Services and submitting your personal data to us, you consent to these
                        international transfers, as well as the storage and processing of your information in
                        countries other than your own, as outlined above. We assure you that these actions are
                        taken with the utmost care for your privacy and in line with the highest standards of
                        data protection.</section>

                        <h1 style="font-weight: bold; padding-top: 10px;font-size: 25px;">10. Data Security Measures</h1>
                        <h2 class="heading" style=" font-size: 21px;">(a) Security Implementations: </h2>
                        <section class="small-headings">AceJobber employs robust security protocols and privacy-enhancing technologies
                            across our Platform, including but not limited to encryption, password protection, call
                            masking, and the adoption of physical security measures. These efforts are designed to safeguard your personal data against unauthorized access and disclosure, in compliance
                            with standards mandated by applicable laws.</section>
                        <h2 class="heading" style=" font-size: 21px;">(b) Password Security and User Responsibility: </h2>
                        <section class="small-headings">As a user, you play a critical role in maintaining the security of your account. The
                            password you create to access specific areas of the Services or Professional Services
                            must be kept confidential and not disclosed to anyone. AceJobber cannot be held liable
                            for unauthorized access to your information stemming from the loss, theft, or
                            compromise of your password, nor for any subsequent activities on your account
                            resulting from such breaches.</section>
                            <section class="small-headings">Should you have reason to believe that your password has been compromised in any
                                way, it is imperative that you contact us immediately. This will allow us to take swift
                                action to secure your account, including initiating a password reset process.</section>
                   <h1 style="font-weight: bold; padding-top: 10px;font-size: 25px;">11. Data Retention</h1>
                   <h2 class="heading" style=" font-size: 21px;">(a) Retention Policy: </h2>
                   <section class="small-headings">You recognize and agree that AceJobber will retain your personal data for the duration
                    necessary to fulfill the purposes for which it was collected, as well as for a reasonable
                    timeframe thereafter to meet our legal obligations and rights post the deactivation of
                    your account or cessation of your use of our Services.</section>
                    <h2 class="heading" style=" font-size: 21px;">(b) Aggregation and Indefinite Use: </h2>
                    <section class="small-headings">Under certain conditions, your personal data may be anonymized and aggregated for
                        research or statistical analysis. In such instances, it ceases to identify you personally,
                        allowing us to utilize this information indefinitely without further obligation to notify
                        you.</section>

                <h1 style="font-weight: bold; padding-top: 10px;font-size: 25px;">12. Business Transitions</h1>

                 <section class="small-headings">Please be informed that in the event of significant business changes such as mergers,
                    acquisitions by another entity, or the sale of all or a portion of our assets, your personal
                    data may be transferred as part of the assets involved in such transitions.</section>

                    <h1 style="font-weight: bold; padding-top: 10px;font-size: 25px;">13. User-Generated Content</h1>
                    <section class="small-headings">We encourage you to share your content on our Platform, including comments,
                        feedback, images, and any other information you wish to make public. Be advised that
                        this content becomes accessible to all Platform visitors and may enter the public
                        domain. We cannot guarantee that this information will not be used in ways that may
                        not align with this Policy, the law, or your privacy expectations. We expressly disclaim all
                        liability for the misuse of such content. You agree to abide by all relevant laws when
                        posting or sharing content and acknowledge your sole responsibility for ensuring your
                        contributions do not contravene these laws.</section>

                        <h1 style="font-weight: bold; padding-top: 10px;font-size: 25px;">14. Updates to This Policy</h1>
                    <b style="font-size: 20px;">(a) </b><span style="font-size: 20px; font-weight: 500;padding-top: 10px; text-align: justify;">AceJobber reserves the right to amend this Privacy Policy periodically. Changes will
                        be communicated by updating the policy on the Platform or via email, as appropriate.
                        Your continued use of the Platform following such notifications constitutes your
                        acceptance of the revised policy terms, as permitted by law.</span><br>

                        <b style="font-size: 20px;">(b) </b><span style="font-size: 20px; font-weight: 500;padding-top: 10px;">We encourage regular review of this Policy to stay informed of how we are
                            protecting your personal data.</span>

                    <h1 style="font-weight: bold; padding-top: 10px;font-size: 25px;">15. Grievance Officer</h1>
                    <section class="small-headings">Should you have any inquiries or concerns regarding this Privacy Policy, our data
                        handling practices, or any other related matters, please do not hesitate to contact us at
                        privacy@AceJobber.com. For direct queries, you may also reach out to our designated
                        Grievance Officer:</section>
                        <br>
                    <ul style="margin-left: 35px;">
                        <li><b style="font-size: 20px;">Name: </b><span style="font-size: 20px;">[Full Name]</span></li>
                        <li><b style="font-size: 20px;">Designation: </b><span style="font-size: 20px;">General Counsel</span></li>
                        <li><b style="font-size: 20px;">Email: </b><span style="font-size: 20px;">privacy@AceJobber.com</span></li>
                    </ul>

                    <section style="padding-bottom: 30px; font-size: 20px; font-weight: 500; text-align: justify; padding-top: 10px;">Your feedback, questions, and grievances are invaluable to us, and we are committed to
                        addressing them promptly.</section>
    

            </div>

        </div>
    </div>
</div>  
