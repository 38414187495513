import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.scss'],

})
export class HowItWorksComponent {
  constructor(public router:Router) {}
  navigate(value){
    sessionStorage.clear()
    if(value == 'postJob'){
      sessionStorage['loginId'] = null
      sessionStorage['token'] = null
      this.router.navigate(['acejobber/post-job',]);
    }
  
    }

    }
   
