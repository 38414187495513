import { Inject, NgModule, inject } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { FirstPageComponent } from './first-page/first-page.component';
import { CategoriesComponent } from './landing-page/categories/categories.component';
import { PopularCategoriesComponent } from './landing-page/popular-categories/popular-categories.component';
import { CategoryservicefilterComponent } from './categoryservicefilter/categoryservicefilter.component';
import { FindajobJobberComponent } from './findajob-jobber/findajob-jobber.component';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { OtpAuthComponent } from './otp-auth/otp-auth.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { LandingPageTwoComponent } from './landing-page-two/landing-page-two.component';
import { NotificationsHistoryComponent } from './notifications-history/notifications-history.component';
import { AuthService } from './Services/auth.service';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { CompanyWorksComponent } from './company-works/company-works.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ChargesComponent } from './charges/charges.component';
import { ExampleComponent } from './example/example.component';
export const routes: Routes = [

{
  path:'example',
  loadComponent:()=>import('./example/example.component').then(u=>u.ExampleComponent)
  , canActivate: [()=> inject(AuthService).isRouteAuthenticated()]
  , data: { isPrivate:false }


},

  {
    path : 'navbar',
    loadComponent:()=>import('./navbar/navbar.component').then(u=>u.NavbarComponent)
     , canActivate: [()=> inject(AuthService).isRouteAuthenticated()]
     , data: { isPrivate:false }

  },
  {
    path : 'example',
    loadComponent:()=>import('./example/example.component').then(u=>u.ExampleComponent)
     , canActivate: [()=> inject(AuthService).isRouteAuthenticated()]
     , data: { isPrivate:false }

  },
  {
    path : 'charges',
    loadComponent:()=>import('./charges/charges.component').then(u=>u.ChargesComponent)
     , canActivate: [()=> inject(AuthService).isRouteAuthenticated()]
     , data: { isPrivate:false }

  },
  {
    path : 'contact-us',
    loadComponent:()=>import('./contact-us/contact-us.component').then(u=>u.ContactUsComponent)
     , canActivate: [()=> inject(AuthService).isRouteAuthenticated()]
     , data: { isPrivate:false }

  },
  {
    path : 'refund',
    loadComponent:()=>import('./refund-policy/refund-policy.component').then(u=>u.RefundPolicyComponent)
     , canActivate: [()=> inject(AuthService).isRouteAuthenticated()]
     , data: { isPrivate:false }

  },
  {
    
    path : 'works',
    loadComponent:()=>import('./company-works/company-works.component').then(u=>u.CompanyWorksComponent)
     , canActivate: [()=> inject(AuthService).isRouteAuthenticated()]
     , data: { isPrivate:false }

  },
  {
    
    path : 'terms',
    loadComponent:()=>import('./terms-conditions/terms-conditions.component').then(u=>u.TermsConditionsComponent)
     , canActivate: [()=> inject(AuthService).isRouteAuthenticated()]
     , data: { isPrivate:false }

  },
  {
    path: 'privacy',
    loadComponent:()=>import('./privacy-policy/privacy-policy.component').then(u=>u.PrivacyPolicyComponent)
    ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
    , data: { isPrivate:false }
  },
  {
    path: 'jobPoster/chat',
    loadComponent:()=>import('./chat-page2/chat-page2.component').then(u=>u.ChatPage2Component)
    ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
    , data: { isPrivate:false }
  },
  {
    path: 'landing-2',// component: LandingPageTwoComponent
    loadComponent:()=>import('./landing-page-two/landing-page-two.component').then(land=>land.LandingPageTwoComponent)
    ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
    , data: { isPrivate:false }
  },
  {
    path: 'working',// component: HowItWorksComponent
    loadComponent:()=>import('./how-it-works/how-it-works.component').then(works=>works.HowItWorksComponent)
    ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
    , data: { isPrivate:false }
  },
  {
    path: 'find',
    loadComponent:()=>import('./findjobcustomer/findjobcustomer.component').then(find=>find.FindjobcustomerComponent)
    ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
    , data: { isPrivate:false }
  },

  {
    path: '',component:FirstPageComponent
    ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
    , data: { isPrivate:false }
  },
  
// {// Bhabani 24/01/2024
//   path:'otp-auth',//component:OtpAuthComponent
//   loadComponent:()=>import('./otp-auth/otp-auth.component').then(otp=>otp.OtpAuthComponent)
// },

  {
    path: 'acejobber/jobber',
    loadComponent:()=>import('./jobber-module/main-page/main-page.component').then(main=>main.MainPageComponent),
    children: [

      {
        path: 'dasboard',
        loadComponent:()=>import('./jobber-module/dashboard/dashboard.component').then(dash=>dash.DashboardComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
        path: 'jobber-registration',
        loadComponent:()=>import('./jobber-module/jobber-registration/jobber-registration.component').then(reg=>reg.JobberRegistrationComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
        path: 'jprofile',
        loadComponent:()=>import('./jobber-module/jobber-registration/jprofile/jprofile.component').then(jp=>jp.JprofileComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
        path: 'jdoc',
        loadComponent:()=>import('./jobber-module/jobber-registration/jdoc/jdoc.component').then(jdoc=>jdoc.JdocComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
        path: 'documents',
        loadComponent:()=>import('./jobber-module/documents/documents.component').then(doc=>doc.DocumentsComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
        path: 'chat-page',
        loadComponent:()=>import('./chat-page/chat-page.component').then(ch=>ch.ChatPageComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
        path: 'chat-ws',
        loadComponent:()=>import('./chat-page-ws-stomp/chat-page-ws-stomp.component').then(c=>c.ChatPageWsStompComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
        path: 'chat-list',
        loadComponent:()=> import('./chat-list/chat-list.component').then(x=>x.ChatListComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
        path: 'my-jobs',
        loadComponent:()=>import('./jobber-module/my-jobs/my-jobs.component').then(my=>my.MyJobsComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
        path: 'my-jobs/:id',
        loadComponent:()=>import('./jobber-module/my-jobs/my-jobs.component').then(my=>my.MyJobsComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
        path: 'my-notifications',
        loadComponent:()=>import('./jobber-module/my-notifications/my-notifications.component').then(not=>not.MyNotificationsComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
        path: 'payments',
        loadComponent:()=>import('./jobber-module/jobber-payments/jobber-payments.component').then(pay=>pay.JobberPaymentsComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
        path: 'payments/:id/:index',
        loadComponent:()=>import('./jobber-module/jobber-payments/jobber-payments.component').then(pay=>pay.JobberPaymentsComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
        path: 'payments/:id/:index/:userId',
        loadComponent:()=>import('./jobber-module/jobber-payments/jobber-payments.component').then(pay=>pay.JobberPaymentsComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
        path: 'reviews',
        loadComponent:()=>import('./jobber-module/reviews/reviews.component').then(rev=>rev.ReviewsComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
        path: 'settings',
        loadComponent:()=>import('./settings/settings.component').then(set=>set.SettingsComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
        path: 'wallet',
        loadComponent:()=>import('./jobber-module/wallet/wallet.component').then(set=>set.WalletComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
        path: 'jobber-terms',
        loadComponent:()=>import('./jobber-module/jobber-terms/jobber-terms.component').then(set=>set.JobberTermsComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
        path: 'jobber-help',
        loadComponent:()=>import('./jobber-module/jobber-help/jobber-help.component').then(set=>set.JobberHelpComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      
      {
        path: 'find',
        loadComponent:()=>import('./findjobcustomer/findjobcustomer.component').then(find=>find.FindjobcustomerComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
        path: 'chat',
        loadComponent:()=>import('./chat-page2/chat-page2.component').then(u=>u.ChatPage2Component)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
        path: 'categorypage',
        loadComponent:()=>import('./category-page/category-page.component').then(cat=>cat.CategoryPageComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
        path: 'customerList',
        loadComponent:()=>import('./category-page/category-page.component').then(cat=>cat.CategoryPageComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
        path: 'customerList/:id',
        loadComponent:()=>import('./category-page/category-page.component').then(cat=>cat.CategoryPageComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
        path: 'findajobjobber',component:FindajobJobberComponent
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      // {
      //   path: 'jobDetails',
      //   loadComponent:()=>import('./jobber-module/job-details/job-details.component').then(job=>job.JobDetailsComponent)
      //   ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
      //   , data: { isPrivate:true }
      // },
      {
        path: 'jobDetails/:messageType/:id',
        loadComponent:()=>import('./jobber-module/job-details/job-details.component').then(job=>job.JobDetailsComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
        path: 'referral-rewards',
        loadComponent:()=>import('./referral-rewards/referral-rewards.component').then(ref=>ref.ReferralRewardsComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
        path: 'referral-rewards-explanation', 
        loadComponent:()=>import('./referral-rewards-work-explanation/referral-rewards-work-explanation.component').then(ex=>ex.ReferralRewardsWorkExplanationComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
        path: 'notifications-history', 
        loadComponent:()=>import('./notifications-history/notifications-history.component').then(ex=>ex.NotificationsHistoryComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
      path: 'notifications-history/:count', 
      loadComponent:()=>import('./notifications-history/notifications-history.component').then(ex=>ex.NotificationsHistoryComponent)
      ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
      , data: { isPrivate:true }
    }
    ]
  },
  {
    path: 'acejobber',
    children: [
      {
        path: 'jobber/signup',
        loadComponent:()=>import('./signup-page/signup-page.component').then(sign=>sign.SignupPageComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:false }
      },
      {
        path: 'jobber/signup/:code',
        loadComponent:()=>import('./signup-page/signup-page.component').then(sign=>sign.SignupPageComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:false }
      },
      {
        path: 'jobPoster/signup',
        loadComponent:()=>import('./signup-page/signup-page.component').then(sig=>sig.SignupPageComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:false }
      },
      {
        path: 'jobPoster/signup/:code',
        loadComponent:()=>import('./signup-page/signup-page.component').then(sig=>sig.SignupPageComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:false }
      },

      {
        path: 'notify',
        loadComponent:()=>import('./notify/notify.component').then(not=>not.NotifyComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:false }
      },
      {
        path: 'login/login-page',// component:LoginPageComponent
        loadComponent:()=>import('./login-page/login-page.component').then(l=>l.LoginPageComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:false }
      },
      {
        path: 'jobberlogin/login-page',// component:LoginPageComponent
        loadComponent:()=>import('./login-page/login-page.component').then(l=>l.LoginPageComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:false }
      },
      {
        path: 'signup/login-page',// component:LoginPageComponent
        loadComponent:()=>import('./login-page/login-page.component').then(l=>l.LoginPageComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:false }
      },
      {
        path: 'login/login-page/:code',// component:LoginPageComponent
        loadComponent:()=>import('./login-page/login-page.component').then(l=>l.LoginPageComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:false }
      },
      {
        path: 'signup/login-page/:code',// component:LoginPageComponent
        loadComponent:()=>import('./login-page/login-page.component').then(l=>l.LoginPageComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:false }
      },
      // {
      //   path: 'jobber/login-page', component:LoginPageComponent
      //  // loadComponent:()=>import('./login-page/login-page.component').then(l=>l.LoginPageComponent)
      // },
      // {
      //   path: 'jobPoster/login-page', component:LoginPageComponent
      //   //loadComponent:()=>import('./login-page/login-page.component').then(l=>l.LoginPageComponent)
      // },
      {
        path: 'admin/login/login-page',// component:LoginPageComponent
        loadComponent:()=>import('./login-page/login-page.component').then(l=>l.LoginPageComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:false }
      },
      {
        path: 'success-page',
        loadComponent:()=> import('./success-page/success-page.component').then(s=>s.SuccessPageComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:false }
      },
      {
        path: 'customerdetails/findjob-customer',
        loadComponent:()=>import('./findjobcustomer/findjobcustomer.component').then(find=>find.FindjobcustomerComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:false }
      },
      {
        path: 'findjob',
        loadComponent:()=>import('./findjob/findjob.component').then(f=>f.FindjobComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:false }
      },

      {
        path: 'jobber/category-page',
        loadComponent:()=>import('./category-page/category-page.component').then(cat=>cat.CategoryPageComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:false }
      },
      {
        path: 'confirmedVerification',
        loadComponent:()=>import('./verification/verification.component').then(ver=>ver.VerificationComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:false }
      },
      {
        path: 'reset-password',
        loadComponent:()=>import('./reset-password/reset-password.component').then(reset=>reset.ResetPasswordComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:false }
      },
      {
        path: 'two-way-auth', 
        loadComponent:()=>import('./two-way-auth/two-way-auth.component').then(two=>two.TwoWayAuthComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:false }
      },
      {
        path: 'videoCall', 
        loadComponent:()=>import('./video-call/video-call.component').then(x=>x.VideoCallComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:false }
      },
      {
        path: 'post-job',
        loadComponent:()=>import('./jobposter-module/post-job/post-job.component').then(post=>post.PostJobComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:false }
      },
     

    ]
  },

  {
    path: 'acejobber/admin',
    loadComponent:()=>import('./jobber-module/main-page/main-page.component').then(m=>m.MainPageComponent),

    children: [

      {
        path: 'dashboard',
        loadComponent:()=>import('./jobber-module/dashboard/dashboard.component').then(dash=>dash.DashboardComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      // {
      //   path: 'category', component: CategoryComponent
      // },

    ]
  },

  {
    path: 'acejobber/jobPoster',
    loadComponent:()=>import('./jobposter-module/main-pg/main-pg.component').then(main=>main.MainPgComponent),
    children: [
      {
        path: 'post-job',
        loadComponent:()=>import('./jobposter-module/post-job/post-job.component').then(post=>post.PostJobComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
        path: 'customer-help',
        loadComponent:()=>import('./jobposter-module/customer-help/customer-help.component').then(job=>job.CustomerHelpComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
        path: 'customer-terms',
        loadComponent:()=>import('./jobposter-module/customer-terms/customer-terms.component').then(job=>job.CustomerTermsComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
        path: 'jobbers-list',
        loadComponent:()=>import('./jobposter-module/jobbers-list/jobbers-list.component').then(job=>job.JobbersListComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
        path: 'jobposter-registration',
        loadComponent:()=>import('./jobposter-module/jobposter-registration/jobposter-registration.component').then(poster=>poster.JobposterRegistrationComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
        path: 'jobs-posted',
         loadComponent:()=>import('./jobposter-module/jobs-posted/jobs-posted.component').then(p=>p.JobsPostedComponent)
         ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
         , data: { isPrivate:true }
        },
        {
          path: 'jobs-posted/:id',
           loadComponent:()=>import('./jobposter-module/jobs-posted/jobs-posted.component').then(p=>p.JobsPostedComponent)
           ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
           , data: { isPrivate:true }
          },
      {
        path: 'bell-notify',
        loadComponent:()=>import('./jobposter-module/bell-notify/bell-notify.component').then(bel=>bel.BellNotifyComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
        path: 'notifications',
        loadComponent:()=>import('./jobposter-module/notifications/notifications.component').then(not=>not.NotificationsComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
        path: 'settings',
          loadComponent:()=>import('./settings/settings.component').then(set=>set.SettingsComponent)
          ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
          , data: { isPrivate:true }
        },
      {
        path: 'chat-page',
        loadComponent:()=>import('./chat-page/chat-page.component').then(cha=>cha.ChatPageComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
        path: 'chat-list',
        loadComponent:()=> import('./chat-list/chat-list.component').then(y=>y.ChatListComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
        path: 'tabs-page',
        loadComponent:()=>import('./jobposter-module/tabs-page/tabs-page.component').then(tabs=>tabs.TabsPageComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
        path: 'tabs-page/:index',
        loadComponent:()=>import('./jobposter-module/tabs-page/tabs-page.component').then(tabs=>tabs.TabsPageComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
        path: 'payments',
        loadComponent:()=>import('./jobposter-module/payments/payments.component').then(pay=>pay.PaymentsComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
        path: 'payments/:id/:index',
        loadComponent:()=>import('./jobposter-module/payments/payments.component').then(pay=>pay.PaymentsComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
        path: 'payments/:id/:index/:userID',
        loadComponent:()=>import('./jobposter-module/payments/payments.component').then(pay=>pay.PaymentsComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
        path: 'dashboardHire',
        loadComponent:()=>import('./jobposter-module/hire-dashboard/hire-dashboard.component').then(hir=>hir.HireDashboardComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
        path: 'my-jobs',
        loadComponent:()=>import('./jobposter-module/my-jobs-jobposter/my-jobs-jobposter.component').then(poster=>poster.MyJobsJobposterComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
        path: 'my-jobs/:id/:index',
        loadComponent:()=>import('./jobposter-module/my-jobs-jobposter/my-jobs-jobposter.component').then(poster=>poster.MyJobsJobposterComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
        path: 'referral-rewards',
        loadComponent:()=>import('./referral-rewards/referral-rewards.component').then(ref=>ref.ReferralRewardsComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
        path: 'referral-rewards-explanation', 
        loadComponent:()=>import('./referral-rewards-work-explanation/referral-rewards-work-explanation.component').then(ex=>ex.ReferralRewardsWorkExplanationComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
        path: 'customer-chat',
        loadComponent:()=>import('./customer-chat-page/customer-chat-page.component').then(cus=>cus.CustomerChatPageComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
        path: 'chat-ws',
        loadComponent:()=>import('./chat-page-ws-stomp/chat-page-ws-stomp.component').then(c=>c.ChatPageWsStompComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
        path: 'notifications-history', 
        loadComponent:()=>import('./notifications-history/notifications-history.component').then(ex=>ex.NotificationsHistoryComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
        path: 'notifications-history/:count', 
        loadComponent:()=>import('./notifications-history/notifications-history.component').then(ex=>ex.NotificationsHistoryComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
      {
        path: 'jobDetails/:messageType/:id',
        loadComponent:()=>import('./jobber-module/job-details/job-details.component').then(job=>job.JobDetailsComponent)
        ,  canActivate: [() => inject(AuthService).isRouteAuthenticated()]
        , data: { isPrivate:true }
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
// SignupPageComponent,MainPageComponent,JobposterRegistrationComponent,SettingsComponent
export const routingComponents = [FirstPageComponent,CategoriesComponent,PopularCategoriesComponent,
  CategoryservicefilterComponent,FindajobJobberComponent,FooterComponent,NavbarComponent,HowItWorksComponent,LandingPageTwoComponent ]