<div class="contact-us" style="margin-top: 140px">
  <h2>Contact us</h2>
  <form
    #myForm="ngForm"
    (ngSubmit)="onSubmit()"
    action="#"
    class="needs-validation"
    novalidate
    [class.invalid]="myForm?.controls?.myControl?.invalid"
  >
    <div class="mb-3">
      <label for="full-name" class="form-label">Full Name</label>
      <input
        class="form-control"
        type="text"
        id="full-name"
        required
        placeholder="Enter full name"
        ngModel
        name="fullName"
      />
      <div
        class="invalid-feedback"
        *ngIf="
          myForm.controls.fullName.invalid &&
          (myForm.controls.fullName.dirty || myForm.controls.fullName.touched)
        "
      >
        Please fill name field.
      </div>
    </div>

    <div class="mb-3">
      <label for="email" class="form-label">Email Address</label>
      <input
        class="form-control"
        type="email"
        id="email"
        required
        placeholder="Enter your email address"
        ngModel
        name="email"
        pattern="[a-zA-Z0-9._%+-]+@gmail\.com"
      />
      <div
        class="invalid-feedback"
        *ngIf="
          myForm.controls.email.invalid &&
          (myForm.controls.email.dirty || myForm.controls.email.touched)
        "
      >
        <ng-container *ngIf="myForm.controls.email.errors.required">
          Please enter an email address.
        </ng-container>
        <ng-container *ngIf="myForm.controls.email.errors.pattern">
          Please enter a valid Gmail email address (e.g. ****@gmail.com).
        </ng-container>
        <ng-container *ngIf="myForm.controls.email.errors.email">
          Please enter a valid email address.
        </ng-container>
      </div>
    </div>

    <div class="mb-3">
      <label for="phone" class="form-label">Enter Phone Number</label>
      <input
        class="form-control"
        type="tel"
        id="phone"
        required
        placeholder="+91"
        ngModel
        name="phone"
        pattern="[0-9]{10}"
        (keypress)="onlyNumbers($event)"
      />
      <div
        class="invalid-feedback"
        *ngIf="
          myForm.controls.phone.invalid &&
          (myForm.controls.phone.dirty || myForm.controls.phone.touched)
        "
      >
        Please enter a valid phone number (exactly 10 digits).
      </div>
    </div>
    <div class="mb-3">
      <label for="message" class="form-label">Enter Message</label>
      <textarea
        id="message"
        required
        placeholder="Enter message"
        class="form-control"
        ngModel
        name="message"
        style="height: 100px"
      ></textarea>
      <div
        class="invalid-feedback"
        *ngIf="
          myForm.controls.message.invalid &&
          (myForm.controls.message.dirty || myForm.controls.message.touched)
        "
      >
        Please enter a message.
      </div>
    </div>

    <button
      type="submit"
      class="btn btn-primary"
      [disabled]="!myForm.form.valid"
      #openButton
      id="openButton"
    >
      Submit
    </button>
  </form>
  <dialog
    #dialog
    style="width: 40vw"
    [closeOnEscape]="false"
    [closeOnOutsideClick]="false"
  >
    <form method="dialog">
      <header>
        <h3>Thank you!</h3>
        <div class="dialog-close-button" (click)="dialog.close()">
          <span class="material-symbols-outlined"> close </span>
        </div>
      </header>
      <article>
        <p>Thanks for reaching out!</p>
      </article>
      <footer>
        <!-- <button autofocus #closeButton id="closeButton">Accept</button> -->
      </footer>
    </form>
  </dialog>
</div>
