import { Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ServiceFileService } from '../jobber-module/services/service-file.service';
import { Reason } from '../jobber-module/models/reason.model';
import { RCHire } from '../jobber-module/models/rchire.model';
import { ImageFileServiceService } from '../Services/image-file-service.service';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
  standalone:true,
  imports:[ BrowserModule,BrowserAnimationsModule,MatDialogModule ,RadioButtonModule ,FormsModule]
})
export class PopupComponent {
  message: any;
  selectedReason : any
  cancelReasons: { key: string; value: string }[] = [
    { key: 'reason1', value: 'Not interested' },
    { key: 'reason2', value: 'Long distance' },
    { key: 'reason3', value: 'Busy schedule' },
    { key: 'reason4', value: 'Got a better offer' },
    { key: 'reason5', value: 'Others' },
  ];
  messageContent: string;

  constructor(
    public dialogRef: MatDialogRef<PopupComponent>,
    private imageFile: ImageFileServiceService,
    @Inject(MAT_DIALOG_DATA) public data: any , private service: ServiceFileService,
  ) {
    this.message = data;
  }
  reason = new Reason();
  rCHire = new RCHire();
  onYes(): void {
    if(this.message.content == 'Do you want to Cancel the Job ?'){
      this.reason.description = this.selectedReason.value
      this.service.saveReason(this.reason).subscribe((data) => {
      });
      
   } 
   this.dialogRef.close(true);
      
  }

  onNo(): void {
    this.dialogRef.close(false);
  }

  ngOnInit() {
  }
}
