



<div >
<div *ngIf="deviceService.isMobile" class="mobile">
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button (click)="goBack()">
          <ion-icon src="assets/figma/left.svg"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title>Find a Job</ion-title>
    </ion-toolbar>
  </ion-header>


<div class="bg">
    <div class="container">
        <div class="row">
            <div class=" col-12 col-md-12">
  <button (click)="toggleTag()"  class="text-center yes-Button mt-3">Find a Job</button>
               
            </div>
            <div class="col-12 col-md-12  " style="margin-top: 10px;">
                <div *ngIf="showMe">
                  <app-categoryservicefilter></app-categoryservicefilter>
                  </div>
              </div>
        </div>
    </div>

</div>
</div>

<!-- Tablet template -->
<div *ngIf="deviceService.isTablet" class="tablet">
<p>tablet</p>
</div>

<!-- Web template -->
<div *ngIf="deviceService.isWeb " class="desktop">
  <div>
    <h1 class="dashboard-nam"> <span></span>Find A Job</h1>
  </div>
  <!-- <button  class="btn btn-primary" style="margin-top: 20px;"
(click)="rewardPoints()">Rewards</button> -->



 



<div class="bg">
    <div class="container">
        <div class="row">
            <div class=" col-12 col-md-7">
  <h2 (click)="toggleTag()"  class="btn bckgrd mt-3">Find a Job</h2>
               
            </div>
            <div class="col-9  d-flex align-items-center justify-content-center " style="margin-top: 100px;">
                <div *ngIf="showMe">
                  <app-categoryservicefilter></app-categoryservicefilter>
                  </div>
              </div>
        </div>
    </div>

</div>

</div>
</div>

