



<div [class.mobile]="isMobile"
[class.tablet]="isTablet"
[class.desktop]="isWeb">
<ng-template [ngIf]="isMobile">



  <form #userForm="ngForm" [formGroup]="registerForm" action=" ">

    <div class="container-fluid col-12 text-center mt-5  ">
     
    
      <p-autoComplete
     
      appendTo="body"
      class="autocomplete-dropdown p-inputtext p-component mt-3"
      placeholder="Category,Keywords"
      [(ngModel)]="selectedCategoryName"
      formControlName="categoryname"
      [suggestions]="suggestion"
      field="name"
      (completeMethod)="searching($event)"
      (onSelect)="onSelect($event)"

      [style]="{ padding: '0px' }"
      [inputStyle]="{
      
        padding: '5px',
        height: '42px',
       
        
      
      }"
    >
    </p-autoComplete>
   
    <p-autoComplete
    appendTo="body"
    class="autocomplete-dropdown p-inputtext p-component mt-3"
    placeholder="Enter City"
    [(ngModel)]="selectedLocation"
    formControlName="servicename"
    [suggestions]="suggestions"
    (completeMethod)="searchs($event)"
    [style]="{ padding: '10px' }"
    [inputStyle]="{
    
      padding: '5px',
      height: '42px'
     
    }"
  >
</p-autoComplete>
  
<div class="text-center" >
  <button
    type="submit"
    class="btn search-btn"
    (click)="search()"
    [disabled]="!registerForm.valid"
  >
    Search
  </button>
  
</div>
  </div>
  


 


  
  
    <div
      *ngIf="!registerForm.valid && userForm.submitted"
      class="alert alert-danger"
    >
      Please enter the valid information before searching.
    </div>
  </form>
</ng-template>

<!-- Tablet template -->
<ng-template [ngIf]="isTablet">
<p>tablet</p>
</ng-template>

<!-- Web template -->
<ng-template [ngIf]="isWeb">

  <form #userForm="ngForm" [formGroup]="registerForm" action=" ">
    <div class="col-12 category-div">
      <span
        class="material-symbols-outlined d-none d-md-none d-lg-block"
        style="margin-right: -41px; z-index: 10000"
      >
        search
      </span>
      <span>
        <p-autoComplete
          appendTo="body"
          class="autocomplete-dropdown"
          placeholder="Category,Keywords"
          [(ngModel)]="selectedCategoryName"
          formControlName="categoryname"
          [suggestions]="suggestion"
          field="name"
          (completeMethod)="searching($event)"
          (onSelect)="onSelect($event)"
          [style]="{ width: '300px', padding: '10px' }"
          [inputStyle]="{
            width: '300px',
            padding: '5px',
            height: '47px',
            'padding-left': '45px'
          }"
        >
        </p-autoComplete>
      </span>
      <div class="line"></div>
      <span
        class="material-symbols-outlined d-none d-md-none d-lg-block"
        style="margin-right: -41px; z-index: 10000"
      >
        location_on
      </span>
      <span>
        <p-autoComplete
          appendTo="body"
          class="autocomplete-dropdown"
          placeholder="Enter City"
          [(ngModel)]="selectedLocation"
          formControlName="servicename"
          [suggestions]="suggestions"
          (completeMethod)="searchs($event)"
          [style]="{ width: '300px', padding: '10px' }"
          [inputStyle]="{
            width: '350px',
            padding: '5px',
            height: '47px',
            'padding-left': '45px'
          }"
        ></p-autoComplete>
      </span>
      <button
        type="submit"
        class="btn search-btn"
        (click)="search()"
        [disabled]="!registerForm.valid"
      >
        Search
      </button>
    </div>
  
    <div
      *ngIf="!registerForm.valid && userForm.submitted"
      class="alert alert-danger"
    >
      Please enter the valid information before searching.
    </div>
  </form>
  
</ng-template>
</div>